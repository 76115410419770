export class PreferredFund {
  Id: string;
  QmOfferSchemeFundId: number;
  SchemeName: string;
  SchemeCode: string;
  FundName: string;
  FundCode: string;
  SelectOrder: number; // Fund order: first, second, third.
  FundBaseTypeCode: string; // Fund category.
  ModifyDateUTC: string;
  
  constructor (fundBaseTypeCode:string,selectOrder:number) {
    this.FundBaseTypeCode = fundBaseTypeCode;
    this.SelectOrder = selectOrder;
    this.QmOfferSchemeFundId = -1;
  }
  
  static getDisplayName(fund: PreferredFund): string {
    return `${fund.SchemeName} - ${fund.FundName}`;
  }
}

