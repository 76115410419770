import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { LoginService } from '../service/login.service';

@Injectable({
  providedIn: 'root'
})
export class AccessDeniedGuard implements CanActivate {

  constructor (
    private router: Router,
    public loginService: LoginService,
  ) { }

  canActivate(): boolean {
    if (!this.loginService.hasAccessToKiwimonster()) {
      if (this.loginService.getPlatformSetting() === 3) {
        this.router.navigate(['/dashboard']);
      } else {
        let dashboardUrl = this.loginService.platformDashboard(this.loginService.getPlatformSetting());
        this.router.navigate([`/${ dashboardUrl }`]);
      }
      // this.router.navigate(['/home']);
      return false;
    }
    return true;
  }

}
