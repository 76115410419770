<div class="qm-checkmonster">
  <ul class="list-group list-group-flush">
    <li class="list-group-item active" *ngIf="!hideHeader">
        <h5 class="mb-0">Checkmonster</h5>
    </li>
    <!-- household people and household income start -->
    <li class='list-group-item px-0'>
      <div class='row align-items-end'>
        <!-- household income -->
        <div class='col-lg-4'>
          <div class='row'>
            <div class='col-sm-11'>
              <mat-form-field appearance="fill" [title]='sharedFunction.getUiMessageByCode("QuoteWizardClientOverview-INFO-HouseholdIncomeInfo")'>
                <mat-label>Household Income Types</mat-label>
                <mat-select name='householdIncomes' [(ngModel)]="quoteStepCompareCheckmonster.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome"
                  [compareWith]='setDefaultValue' required>
                  <mat-option *ngFor="let income of quoteStepCompareCheckmonster.householdIncomes" [value]="income">
                    {{ income.Name }}
                  </mat-option>
                </mat-select>
                <mat-error *ngIf="errorMessage">Select household income</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <!-- get recommendation button -->
        <div class='col-lg-2'>
          <button class="mb-4 px-4" type="button" mat-flat-button color="qm-pumpkin"  [disabled]="!quoteStepCompareCheckmonster.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome"
            (click)="getCheckmonsterQuote();this.quoteStepCompareCheckmonster.loginService.doGoogleTracking('quote-checkmonster', 'get checkmonster', '');">
            Check cover level
          </button>
        </div>
      </div>
    </li>
    <!-- household people and household income end -->
    <ng-container *ngIf="quoteStepCompareCheckmonster.currentQuote.QuoteEntity.QuoteWizardHouseholdIncome && quoteStepCompareCheckmonster.showCheckmonsterRecommendation">
      <ng-container *ngFor="let client of quoteStepCompareCheckmonster.currentQuote.QuoteEntity.PeopleEntity">
        <li class="list-group-item border-top-0 qpr-item-holder pb-0 px-0"
          *ngIf="!client.IsChild && quoteStepCompareCheckmonster.checkmonsterRecommendation">
          <h4 class='breadcrumb'>{{client.FirstName}} {{client.LastName}}</h4>
          <!-- small screen -->
          <div class="smallscreen">
            <ng-container *ngFor="let benefit of client.BenefitList">
              <ng-container *ngIf="benefit.UIBenefitId !== 9 && benefit.UIBenefitId !== 8 && (benefit.UIBenefitId !== 4 || benefit.UIBenefitId !== 14)">
                <div class="row">
                  <div class="container">
                    <div class="d-flex">
                      <div class="font-weight-bold w-40">{{ client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.BenefitName || benefit.BenefitName }}</div>
                      <ng-container *ngIf="benefit.BenefitId !== 1">
                        <div class="w-60 pl-3">
                          <div>Chances of this before age 65</div>
                          <div>{{(client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.RiskItemValue || 0) | percent:'1.0-0'}}</div>
                        </div>
                      </ng-container>
                    </div>
                    <div class="d-flex mt-3">
                      <ng-container *ngIf="benefit.BenefitId !== 1">
                        <div class="w-40">
                          <mat-checkbox [(ngModel)]="benefit.IsCheckmonsterRecommendSelected"
                            [disabled]="!client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount || (benefit.QuoteOption.CoverAmount === client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount)
                            || (benefit.QuoteOption.CoverAmount === client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount)">
                            Requote using typical?
                          </mat-checkbox>
                        </div>
                        <div class="w-60 pl-3">
                          Included by
                          <span [ngClass]="{'font-weight-bold': client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.PercentageOfClientSeeking > 30}">
                            {{ client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.PercentageOfClientSeeking | number:'1.0-0'}}% 
                          </span>
                           of people
                        </div>
                      </ng-container>
                    </div>
                    <ng-container *ngIf="benefit.BenefitId !== 1">
                      <div class="mt-3">
                        <div class="text-nowrap"
                          *ngIf="benefit.BenefitId === 6 || benefit.BenefitId === 7">
                          <div>
                            <div class="progress qm-bg-none h-auto">
                              <div class="w-60">
                                <div class="progress-bar qm-bg-blue w-60 text-black" role="progressbar"
                                  [ngStyle]="{'width': (
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount > benefit.QuoteOption?.IpMonthlyCoverAmount) || (client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount > benefit.QuoteOption.CoverAmount)) ?
                                    (((benefit.QuoteOption?.IpMonthlyCoverAmount || benefit.QuoteOption.CoverAmount) / client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount) * 100)
                                    : 100) + '%'}"
                                  attr.aria-valuenow="{{(
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount > benefit.QuoteOption?.IpMonthlyCoverAmount) || (client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount > benefit.QuoteOption.CoverAmount)) ?
                                    (((benefit.QuoteOption?.IpMonthlyCoverAmount || benefit.QuoteOption.CoverAmount) / client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount) * 100)
                                    : 100)}}"
                                  aria-valuemin="0" aria-valuemax="100">
                                  {{ benefit.QuoteOption?.IpMonthlyCoverAmount || benefit.QuoteOption.CoverAmount | currency : '$ ' : 'symbol': '1.0-0' }}
                                </div>
                              </div>
                              <div class="pl-1 w-40">Your Cover Amount</div>
                            </div>
                          </div>
                          <div *ngIf="client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount">
                            <div class="progress qm-bg-none h-auto">
                              <div class="w-60">
                                <div class="progress-bar qm-bg-pumpkin text-black w-60" role="progressbar"
                                  [ngStyle]="{'width': ((
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount < benefit.QuoteOption?.IpMonthlyCoverAmount) || (client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount < benefit.QuoteOption.CoverAmount)) ?
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount / (benefit.QuoteOption?.IpMonthlyCoverAmount || benefit.QuoteOption.CoverAmount)) * 100)
                                    : 100)) + '%'}" attr.aria-valuenow="{{((
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount < benefit.QuoteOption?.IpMonthlyCoverAmount) || (client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount < benefit.QuoteOption.CoverAmount)) ?
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount / (benefit.QuoteOption?.IpMonthlyCoverAmount || benefit.QuoteOption.CoverAmount)) * 100)
                                    : 100))}}" aria-valuemin="0" aria-valuemax="100">
                                  {{ client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount | currency : '$ ' : 'symbol': '1.0-0'}}
                                </div>
                              </div>
                              <div class="pl-1 w-40">Typical for your profile</div>
                            </div>
                          </div>
                        </div>
                        <div class="text-nowrap"
                          *ngIf="benefit.BenefitId !== 6 && benefit.BenefitId !== 7">
                          <div>
                            <div class="progress qm-bg-none h-auto">
                              <div class="w-60">
                                <div class="progress-bar qm-bg-blue w-60 text-black" role="progressbar"
                                  [ngStyle]="{'width': (benefit.BenefitId === 1 ? '0' : 
                                    (client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount > benefit.QuoteOption.CoverAmount ?
                                    ((benefit.QuoteOption.CoverAmount / client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount) * 100)
                                    : 100)) + '%'}"
                                  attr.aria-valuenow="{{((benefit.BenefitId === 1) ? '0' : 
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount > benefit.QuoteOption.CoverAmount) ?
                                    ((benefit.QuoteOption.CoverAmount / client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount) * 100)
                                    : 100))}}"
                                  aria-valuemin="0" aria-valuemax="100">
                                  {{ benefit.BenefitId === 1 ? "" : (benefit.QuoteOption.CoverAmount | currency : '$ ' : 'symbol': '1.0-0') }}
                                </div>
                              </div>
                              <div class="pl-1 w-40">Your Cover Amount</div>
                            </div>
                          </div>
                          <div *ngIf="client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount">
                            <div class="progress qm-bg-none h-auto">
                              <div class="w-60">
                                <div class="progress-bar qm-bg-pumpkin w-60 text-black" role="progressbar"
                                  attr.aria-valuenow="{{((benefit.BenefitId === 1) ? 0 : 
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount < benefit.QuoteOption.CoverAmount) ?
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount / benefit.QuoteOption.CoverAmount) * 100)
                                    : 100))}}" aria-valuemin="0" aria-valuemax="100"
                                  [ngStyle]="{'width': ((benefit.BenefitId === 1) ? 0 : 
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount < benefit.QuoteOption.CoverAmount) ?
                                    ((client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount / benefit.QuoteOption.CoverAmount) * 100)
                                    : 100)) + '%'}">
                                    {{ client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount | currency : '$ ' : 'symbol': '1.0-0'}}
                                </div>
                              </div>
                              <div class="pl-1 w-40">Typical for your profile</div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div class="d-flex mt-3">
                        <div class="mb-0 w-40">Things to think about</div>
                        <div class="mb-0 w-60 pl-3">
                          <div *ngIf="client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.BenefitId === 2">Replace lost income for your family?</div>
                          <div *ngIf="client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.BenefitId === 4">Debt? Lost income? <br />Treatment costs?</div>
                          <div *ngIf="client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.BenefitId === 5">Debt? Lost income? <br />Home help/rehab?</div>
                          <div *ngIf="client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.BenefitId === 6">Will this cover your expenses?</div>
                          <div *ngIf="client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.BenefitId === 7">Will this cover your expenses?</div>
                        </div>
                      </div>
                      <!-- Is there a reason to accept the gap? -->
                      <ng-container *ngIf="benefit.BenefitId === 6">
                        <div class="d-flex mt-3"
                            *ngIf="client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]].BenefitCode && quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount,(benefit.QuoteOption?.IpMonthlyCoverAmount || 0))">
                          <div class="mb-0 w-40">Is there a reason to accept the gap?</div>
                          <div class="mb-0 w-60 pl-3">
                            <button mat-flat-button class="d-flex w-100 px-0 text-left"
                                    (click)="advisorCommentChangeHandler(client, client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]].BenefitCode)">
                              <p class="pt-0 reason text-wrap">{{client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]].Comment.Message}}
                                <span class="material-icons material-symbols-outlined align-text-top"
                                      style="font-size: 18px;">keyboard_arrow_down</span>
                              </p>
                            </button>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="benefit.BenefitId === 7">
                        <div class="d-flex mt-3"
                            *ngIf="client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]].BenefitCode && quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MonthlyCoverAmount,(benefit.QuoteOption?.CoverAmount || 0))">
                          <div class="mb-0 w-40">Is there a reason to accept the gap?</div>
                          <div class="mb-0 w-60 pl-3">
                            <button mat-flat-button class="d-flex w-100 px-0 text-left"
                                    (click)="advisorCommentChangeHandler(client, client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]].BenefitCode)">
                              <p class="pt-0 reason text-wrap">{{client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]].Comment.Message}}
                                <span class="material-icons material-symbols-outlined align-text-top"
                                      style="font-size: 18px;">keyboard_arrow_down</span>
                              </p>
                            </button>
                          </div>
                        </div>
                      </ng-container>
                      <ng-container *ngIf="benefit.BenefitId !== 6 && benefit.BenefitId !== 7">
                        <div class="d-flex mt-3"
                            *ngIf="client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.BenefitCode && quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]]?.MedianCoverAmount, (benefit.QuoteOption?.CoverAmount || 0))">
                          <div class="mb-0 w-40">Is there a reason to accept the gap?</div>
                          <div class="mb-0 w-60 pl-3">
                            <button mat-flat-button class="d-flex w-100 px-0 text-left"
                                    (click)="advisorCommentChangeHandler(client, client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]].BenefitCode)">
                              <p class="pt-0 reason text-wrap">{{client.CheckmonsterData[BenefitCodeList[benefit.BenefitId]].Comment.Message}}
                                <span class="material-icons material-symbols-outlined align-text-top"
                                      style="font-size: 18px;">keyboard_arrow_down</span>
                              </p>
                            </button>
                          </div>
                        </div>
                      </ng-container>
                    </ng-container>
                    <div class="d-flex justify-content-start">
                    </div>
                  </div>
                </div>
                <hr />
              </ng-container>
            </ng-container>
          </div>
          <!-- large screen -->
          <div class="largescreen">
            <div class="row mx-0 mt-5">
              <div class="col-12 d-flex text-center font-weight-bold">
                <div class="col-1"></div>
                <div class="col-2 qm-text-sm">Life Cover</div>
                <div class="col-2 qm-text-sm">Trauma Cover</div>
                <div class="col-2 qm-text-sm">Total & Permanent Disablement</div>
                <div class="col-2 qm-text-sm">Income Protection</div>
                <div class="col-2 qm-text-sm">Mortgage Protection</div>
                <div class="col-1"></div>
              </div>
            </div>
            <div class="border-top-0 row mx-0 progress-block text-center">
              <div class="col-1"></div>
              <div class="col-2">
                <div class="progress-container justify-content-center">
                  <div class="progress progress-bar-vertical qm-bg-none">
                    <div class="progress-bar qm-bg-blue w-20" role="progressbar" aria-valuenow="(client.CheckmonsterData.LI.MedianCoverAmount ? (
                        client.CheckmonsterData.LI.BenefitList && (client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount >
                        client.CheckmonsterData.LI.MedianCoverAmount) ? (
                        (client.CheckmonsterData.LI.MedianCoverAmount / client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount) * 100)
                      : 100) : 0)" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{'height': (client.CheckmonsterData.LI.MedianCoverAmount ? (
                        client.CheckmonsterData.LI.BenefitList && (client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount >
                        client.CheckmonsterData.LI.MedianCoverAmount) ? (
                        (client.CheckmonsterData.LI.MedianCoverAmount / client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount) * 100)
                      : 100) : 0) + '%'}">
                      <span class="progress-text">
                        {{ client.CheckmonsterData.LI.MedianCoverAmount | currency : '$' : 'symbol': '1.0-0'}}
                      </span>
                    </div>
                  </div>
                  <div class="progress progress-bar-vertical qm-bg-none">
                    <div class="progress-bar qm-bg-pumpkin ml-2 w-20"
                      role="progressbar" [ngStyle]="{'height': ((client.CheckmonsterData.LI.BenefitList && 
                      (client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount < client.CheckmonsterData.LI.MedianCoverAmount))
                      ? ((client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount / client.CheckmonsterData.LI.MedianCoverAmount) * 100)
                      : ((client.CheckmonsterData.LI.BenefitList && (client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount
                      >= client.CheckmonsterData.LI.MedianCoverAmount)) ? 100 : 0)) + '%'}"
                      aria-valuenow="((client.CheckmonsterData.LI.BenefitList && 
                      (client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount < client.CheckmonsterData.LI.MedianCoverAmount))
                      ? ((client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount / client.CheckmonsterData.LI.MedianCoverAmount) * 100)
                      : ((client.CheckmonsterData.LI.BenefitList && (client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount
                      >= client.CheckmonsterData.LI.MedianCoverAmount)) ? 100 : 0))"  aria-valuemin="0" aria-valuemax="100">
                      <span class="progress-text">
                        {{ (client.CheckmonsterData.LI.BenefitList ? client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount : 0) | currency : '$' : 'symbol': '1.0-0'}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="progress-container justify-content-center">
                  <div class="progress progress-bar-vertical qm-bg-none">
                    <div class="progress-bar qm-bg-blue w-20" role="progressbar" aria-valuenow="(client.CheckmonsterData.TR.MedianCoverAmount ? (
                        client.CheckmonsterData.TR.BenefitList && (client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount >
                        client.CheckmonsterData.TR.MedianCoverAmount) ? (
                        (client.CheckmonsterData.TR.MedianCoverAmount / client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount) * 100)
                      : 100) : 0)" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{'height': (client.CheckmonsterData.TR.MedianCoverAmount ? (
                        client.CheckmonsterData.TR.BenefitList && (client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount >
                        client.CheckmonsterData.TR.MedianCoverAmount) ? (
                        (client.CheckmonsterData.TR.MedianCoverAmount / client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount) * 100)
                      : 100) : 0) + '%'}">
                      <span class="progress-text">
                        {{ client.CheckmonsterData.TR.MedianCoverAmount | currency : '$' : 'symbol': '1.0-0'}}
                      </span>
                    </div>
                  </div>
                  <div class="progress progress-bar-vertical qm-bg-none">
                    <div class="progress-bar qm-bg-pumpkin ml-2 w-20"
                      role="progressbar" [ngStyle]="{'height': ((client.CheckmonsterData.TR.BenefitList && 
                      (client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount < client.CheckmonsterData.TR.MedianCoverAmount))
                      ? ((client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount / client.CheckmonsterData.TR.MedianCoverAmount) * 100)
                      : ((client.CheckmonsterData.TR.BenefitList && (client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount
                      >= client.CheckmonsterData.TR.MedianCoverAmount)) ? 100 : 0)) + '%'}"
                      aria-valuenow="((client.CheckmonsterData.TR.BenefitList && 
                      (client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount < client.CheckmonsterData.TR.MedianCoverAmount))
                      ? ((client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount / client.CheckmonsterData.TR.MedianCoverAmount) * 100)
                      : ((client.CheckmonsterData.TR.BenefitList && (client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount
                      >= client.CheckmonsterData.TR.MedianCoverAmount)) ? 100 : 0))"  aria-valuemin="0" aria-valuemax="100">
                      <span class="progress-text">
                        {{ (client.CheckmonsterData.TR.BenefitList ? client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount : 0) | currency : '$' : 'symbol': '1.0-0'}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="progress-container justify-content-center">
                  <div class="progress progress-bar-vertical qm-bg-none">
                    <div class="progress-bar qm-bg-blue w-20" role="progressbar" aria-valuenow="(client.CheckmonsterData.TPD.MedianCoverAmount ? (
                        client.CheckmonsterData.TPD.BenefitList && (client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount >
                        client.CheckmonsterData.TPD.MedianCoverAmount) ? (
                        (client.CheckmonsterData.TPD.MedianCoverAmount / client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount) * 100)
                      : 100) : 0)" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{'height': (client.CheckmonsterData.TPD.MedianCoverAmount ? (
                        client.CheckmonsterData.TPD.BenefitList && (client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount >
                        client.CheckmonsterData.TPD.MedianCoverAmount) ? (
                        (client.CheckmonsterData.TPD.MedianCoverAmount /client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount) * 100)
                      : 100) : 0) + '%'}">
                      <span class="progress-text">
                        {{ client.CheckmonsterData.TPD.MedianCoverAmount | currency : '$' : 'symbol': '1.0-0'}}
                      </span>
                    </div>
                  </div>
                  <div class="progress progress-bar-vertical qm-bg-none">
                    <div class="progress-bar qm-bg-pumpkin ml-2 w-20"
                      role="progressbar" [ngStyle]="{'height': ((client.CheckmonsterData.TPD.BenefitList && 
                      (client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount < client.CheckmonsterData.TPD.MedianCoverAmount))
                      ? ((client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount / client.CheckmonsterData.TPD.MedianCoverAmount) * 100)
                      : ((client.CheckmonsterData.TPD.BenefitList && (client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount
                      >= client.CheckmonsterData.TPD.MedianCoverAmount)) ? 100 : 0)) + '%'}"
                      aria-valuenow="((client.CheckmonsterData.TPD.BenefitList && 
                      (client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount < client.CheckmonsterData.TPD.MedianCoverAmount))
                      ? ((client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount / client.CheckmonsterData.TPD.MedianCoverAmount) * 100)
                      : ((client.CheckmonsterData.TPD.BenefitList && (client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount
                      >= client.CheckmonsterData.TPD.MedianCoverAmount)) ? 100 : 0))"  aria-valuemin="0" aria-valuemax="100">
                      <span class="progress-text">
                        {{ (client.CheckmonsterData.TPD.BenefitList ? client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount : 0) | currency : '$' : 'symbol': '1.0-0'}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="progress-container justify-content-center">
                  <div class="progress progress-bar-vertical qm-bg-none">
                    <div class="progress-bar qm-bg-blue w-20" role="progressbar" aria-valuenow="(client.CheckmonsterData.IP.MonthlyCoverAmount ? (
                        client.CheckmonsterData.IP.BenefitList && (client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount >
                        client.CheckmonsterData.IP.MonthlyCoverAmount) ? (
                        (client.CheckmonsterData.IP.MonthlyCoverAmount / client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount) * 100)
                      : 100) : 0)" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{'height': (client.CheckmonsterData.IP.MonthlyCoverAmount ? (
                        client.CheckmonsterData.IP.BenefitList && (client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount >
                        client.CheckmonsterData.IP.MonthlyCoverAmount) ? (
                        (client.CheckmonsterData.IP.MonthlyCoverAmount /client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount) * 100)
                      : 100) : 0) + '%'}">
                      <span class="progress-text">
                        {{ client.CheckmonsterData.IP.MonthlyCoverAmount | currency : '$' : 'symbol': '1.0-0'}}
                      </span>
                    </div>
                  </div>
                  <div class="progress progress-bar-vertical qm-bg-none">
                    <div class="progress-bar qm-bg-pumpkin ml-2 w-20"
                      role="progressbar" [ngStyle]="{'height': ((client.CheckmonsterData.IP.BenefitList && 
                      (client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount < client.CheckmonsterData.IP.MonthlyCoverAmount))
                      ? ((client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount / client.CheckmonsterData.IP.MonthlyCoverAmount) * 100)
                      : ((client.CheckmonsterData.IP.BenefitList && (client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount
                      >= client.CheckmonsterData.IP.MonthlyCoverAmount)) ? 100 : 0)) + '%'}"
                      aria-valuenow="((client.CheckmonsterData.IP.BenefitList && 
                      (client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount < client.CheckmonsterData.IP.MonthlyCoverAmount))
                      ? ((client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount / client.CheckmonsterData.IP.MonthlyCoverAmount) * 100)
                      : ((client.CheckmonsterData.IP.BenefitList && (client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount
                      >= client.CheckmonsterData.IP.MonthlyCoverAmount)) ? 100 : 0))"  aria-valuemin="0" aria-valuemax="100">
                      <span class="progress-text">
                        {{ (client.CheckmonsterData.IP.BenefitList ? client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount : 0) | currency : '$' : 'symbol': '1.0-0'}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-2">
                <div class="progress-container justify-content-center">
                  <div class="progress progress-bar-vertical qm-bg-none">
                    <div class="progress-bar qm-bg-blue w-20" role="progressbar" aria-valuenow="(client.CheckmonsterData.MP.MonthlyCoverAmount ? (
                        client.CheckmonsterData.MP.BenefitList && (client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount >
                        client.CheckmonsterData.MP.MonthlyCoverAmount) ? (
                        (client.CheckmonsterData.MP.MonthlyCoverAmount / client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount) * 100)
                      : 100) : 0)" aria-valuemin="0" aria-valuemax="100"
                      [ngStyle]="{'height': (client.CheckmonsterData.MP.MonthlyCoverAmount ? (
                        client.CheckmonsterData.MP.BenefitList && (client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount >
                        client.CheckmonsterData.MP.MonthlyCoverAmount) ? (
                        (client.CheckmonsterData.MP.MonthlyCoverAmount / client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount) * 100)
                      : 100) : 0) + '%'}">
                      <span class="progress-text">
                        {{ client.CheckmonsterData.MP.MonthlyCoverAmount | currency : '$' : 'symbol': '1.0-0'}}
                      </span>
                    </div>
                  </div>
                  <div class="progress progress-bar-vertical qm-bg-none">
                    <div class="progress-bar qm-bg-pumpkin ml-2 w-20"
                      role="progressbar" [ngStyle]="{'height': ((client.CheckmonsterData.MP.BenefitList && 
                      (client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount < client.CheckmonsterData.MP.MonthlyCoverAmount))
                      ? ((client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount / client.CheckmonsterData.MP.MonthlyCoverAmount) * 100)
                      : ((client.CheckmonsterData.MP.BenefitList && (client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount
                      >= client.CheckmonsterData.MP.MonthlyCoverAmount)) ? 100 : 0)) + '%'}"
                      aria-valuenow="((client.CheckmonsterData.MP.BenefitList && 
                      (client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount < client.CheckmonsterData.MP.MonthlyCoverAmount))
                      ? ((client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount / client.CheckmonsterData.MP.MonthlyCoverAmount) * 100)
                      : ((client.CheckmonsterData.MP.BenefitList && (client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount
                      >= client.CheckmonsterData.MP.MonthlyCoverAmount)) ? 100 : 0))"  aria-valuemin="0" aria-valuemax="100">
                      <span class="progress-text">
                        {{ (client.CheckmonsterData.MP.BenefitList ? client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount : 0) | currency : '$' : 'symbol': '1.0-0'}}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-1"></div>
            </div>
            <div class="list-group-item row mx-0 progress-block text-center pt-0 border-bottom-0">
              <div class="col-1"></div>
              <div class="col-2 w-100 d-flex">
                <p class="text-small text-blue w-50 text-right pr-1">{{ client.CheckmonsterData.LI.MedianCoverAmount | currency : '$' : 'symbol': '1.0-0' }}</p>
                <p class="text-small text-pumpkin w-50 text-left pl-1">
                  {{ (client.CheckmonsterData.LI.BenefitList ? client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount : 0) | currency : '$' : 'symbol': '1.0-0' }}
                </p>
              </div>
              <div class="col-2 w-100 d-flex">
                <p class="text-small text-blue w-50 text-right pr-1">{{ client.CheckmonsterData.TR.MedianCoverAmount | currency : '$' : 'symbol': '1.0-0' }}</p>
                <p class="text-small text-pumpkin w-50 text-left pl-1">
                  {{ (client.CheckmonsterData.TR.BenefitList ? client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount : 0) | currency : '$' : 'symbol': '1.0-0' }}
                </p>
              </div>
              <div class="col-2 w-100 d-flex">
                <p class="text-small text-blue w-50 text-right pr-1">{{ client.CheckmonsterData.TPD.MedianCoverAmount | currency : '$' : 'symbol': '1.0-0' }}</p>
                <p class="text-small text-pumpkin w-50 text-left pl-1">
                  {{ (client.CheckmonsterData.TPD.BenefitList ? client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount : 0) | currency : '$' : 'symbol': '1.0-0' }}
                </p>
              </div>
              <div class="col-2 w-100 d-flex">
                <p class="text-small text-blue w-50 text-right pr-1">{{ client.CheckmonsterData.IP.MonthlyCoverAmount | currency : '$' : 'symbol': '1.0-0' }}</p>
                <p class="text-small text-pumpkin w-50 text-left pl-1">
                  {{ (client.CheckmonsterData.IP.BenefitList ? client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount : 0) | currency : '$' : 'symbol': '1.0-0' }}
                </p>
              </div>
              <div class="col-2 w-100 d-flex">
                <p class="text-small text-blue w-50 text-right pr-1">{{ client.CheckmonsterData.MP.MonthlyCoverAmount | currency : '$' : 'symbol': '1.0-0' }}</p>
                <p class="text-small text-pumpkin w-50 text-left pl-1">
                  {{ (client.CheckmonsterData.MP.BenefitList ? client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount : 0) | currency : '$' : 'symbol': '1.0-0' }}
                </p>
              </div>
              <div class="col-1"></div>
            </div>
            <div class="row mx-0 mt-3 mb-5 justify-content-center">
              <div class="d-flex mx-3">
                <span class="material-icons text-blue qm-bg-blue mx-2">stop</span>
                <span class='qm-text-sm'>Typical for your profile</span>
              </div>
              <div class="d-flex mx-3">
                <span class="material-icons text-pumpkin qm-bg-pumpkin mx-2">stop</span>
                <span class='qm-text-sm'>Your cover</span>
              </div>
            </div>
            <div class="list-group-item row mx-0">
              <div class="font-weight-bold">Chances of this before age 65:</div>
            </div>
            <div class="list-group-item row mx-0 border-top-0">
              <div class="col-12 d-flex text-center align-items-center">
                <div class="col-1"></div>
                <div class="col-2">{{(client.CheckmonsterData.LI?.RiskItemValue || 0 )| percent:'1.0-0'}}</div>
                <div class="col-2">{{(client.CheckmonsterData.TR?.RiskItemValue || 0) | percent:'1.0-0'}}</div>
                <div class="col-2">{{ (client.CheckmonsterData.TPD?.RiskItemValue || 0 )| percent:'1.0-0'}}</div>
                <div class="col-2">{{ (client.CheckmonsterData.IP?.RiskItemValue || 0 )| percent:'1.0-0'}}</div>
                <div class="col-2">{{ (client.CheckmonsterData.MP?.RiskItemValue || 0 )| percent:'1.0-0' }}</div>
                <div class="col-1"></div>
              </div>
            </div>
            <div class="list-group-item row mx-0">
              <div class="font-weight-bold">Included by % of people:</div>
            </div>
            <div class="list-group-item row mx-0 border-top-0">
              <div class="col-12 d-flex text-center align-items-center">
                <div class="col-1"></div>
                <div class="col-2" [ngClass]="{'font-weight-bold': (client.CheckmonsterData.LI.PercentageOfClientSeeking > 30)}">
                  {{client.CheckmonsterData.LI.PercentageOfClientSeeking | number:'1.0-0'}}%
                </div>
                <div class="col-2" [ngClass]="{'font-weight-bold': (client.CheckmonsterData.TR.PercentageOfClientSeeking > 30)}">
                  {{client.CheckmonsterData.TR.PercentageOfClientSeeking | number:'1.0-0'}}%
                </div>
                <div class="col-2" [ngClass]="{'font-weight-bold': (client.CheckmonsterData.TPD.PercentageOfClientSeeking > 30)}">
                  {{client.CheckmonsterData.TPD.PercentageOfClientSeeking | number:'1.0-0'}}%
                </div>
                <div class="col-2" [ngClass]="{'font-weight-bold': (client.CheckmonsterData.IP.PercentageOfClientSeeking > 30)}">
                  {{client.CheckmonsterData.IP.PercentageOfClientSeeking | number:'1.0-0'}}%
                </div>
                <div class="col-2" [ngClass]="{'font-weight-bold': (client.CheckmonsterData.MP.PercentageOfClientSeeking > 30)}">
                  {{client.CheckmonsterData.MP.PercentageOfClientSeeking | number:'1.0-0'}}%
                </div>
                <div class="col-1"></div>
              </div>
            </div>
            <div class="list-group-item row mx-0">
              <div class="font-weight-bold">Things to think about:</div>
            </div>
            <div class="list-group-item row mx-0 border-top-0">
              <div class="col-12 d-flex text-center align-items-center">
                <div class="col-1"></div>
                <div class="col-2">Replace lost income for your family?</div>
                <div class="col-2">Debt? Lost income? <br />Treatment costs?</div>
                <div class="col-2">Debt? Lost income? <br />Home help/rehab?</div>
                <div class="col-2">Will this cover your expenses?</div>
                <div class="col-2">Will this cover your expenses?</div>
                <div class="col-1"></div>
              </div>
            </div>

            <div class="list-group-item row mx-0">
              <div class="font-weight-bold">Requote using typical:</div>
            </div>
            <div class="list-group-item row mx-0 border-top-0">
              <div class="col-12 d-flex text-center align-items-center">
                <div class="col-1"></div>
                <div class="col-2">
                  <mat-checkbox [(ngModel)]="client.CheckmonsterData.LI.IsCheckmonsterRecommendSelected" (change)="checkmonsterSelectHandler(client, 'LI')"
                    [disabled]="(client.CheckmonsterData.LI.BenefitList && client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount === client.CheckmonsterData.LI.MedianCoverAmount) || (!client.CheckmonsterData.LI.BenefitList && client.CheckmonsterData.LI.MedianCoverAmount === 0)">
                  </mat-checkbox>
                </div>
                <div class="col-2">
                  <mat-checkbox [(ngModel)]="client.CheckmonsterData.TR.IsCheckmonsterRecommendSelected" (change)="checkmonsterSelectHandler(client, 'TR')"
                    [disabled]="(client.CheckmonsterData.TR.BenefitList && client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount === client.CheckmonsterData.TR.MedianCoverAmount) || (!client.CheckmonsterData.TR.BenefitList && client.CheckmonsterData.TR.MedianCoverAmount === 0)">
                  </mat-checkbox>
                </div>
                <div class="col-2">
                  <mat-checkbox [(ngModel)]="client.CheckmonsterData.TPD.IsCheckmonsterRecommendSelected" (change)="checkmonsterSelectHandler(client, 'TPD')"
                    [disabled]="(client.CheckmonsterData.TPD.BenefitList && client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount === client.CheckmonsterData.TPD.MedianCoverAmount) || (!client.CheckmonsterData.TPD.BenefitList && client.CheckmonsterData.TPD.MedianCoverAmount === 0)">
                  </mat-checkbox>
                </div>
                <div class="col-2">
                  <mat-checkbox [(ngModel)]="client.CheckmonsterData.IP.IsCheckmonsterRecommendSelected" (change)="checkmonsterSelectHandler(client, 'IP')"
                    [disabled]="(client.CheckmonsterData.IP.BenefitList && client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount === client.CheckmonsterData.IP.MonthlyCoverAmount) || (!client.CheckmonsterData.IP.BenefitList && client.CheckmonsterData.IP.MonthlyCoverAmount === 0)">
                  </mat-checkbox>
                </div>
                <div class="col-2">
                  <mat-checkbox [(ngModel)]="client.CheckmonsterData.MP.IsCheckmonsterRecommendSelected" (change)="checkmonsterSelectHandler(client, 'MP')"
                    [disabled]="(client.CheckmonsterData.MP.BenefitList && client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount === client.CheckmonsterData.MP.MonthlyCoverAmount) || (!client.CheckmonsterData.MP.BenefitList && client.CheckmonsterData.MP.MonthlyCoverAmount === 0)">
                  </mat-checkbox>
                </div>
                <div class="col-1"></div>
              </div>
            </div>
            <!-- Is there a reason to accept the gap? -->
            <ng-container *ngIf="(!client.CheckmonsterData?.LI?.BenefitList?.QuoteOption || quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData.LI.MedianCoverAmount, client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount)) || (!client.CheckmonsterData?.TR?.BenefitList?.QuoteOption || quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData.TR.MedianCoverAmount, client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount)) || (!client.CheckmonsterData?.TPD?.BenefitList?.QuoteOption || quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData.TPD.MedianCoverAmount, client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount)) || !client.CheckmonsterData?.IP?.BenefitList?.QuoteOption || quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData.IP.MonthlyCoverAmount, client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount) || !client.CheckmonsterData?.MP?.BenefitList?.QuoteOption || quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData.MP.MonthlyCoverAmount, client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount)">
                <div class="list-group-item row mx-0">
                    <div class="font-weight-bold">Is there a reason to accept the gap?</div>
                </div>
                <div class="list-group-item row mx-0 border-top-0">
                    <div class='col-10 mx-auto'>
                        <div class="table-responsive">
                            <table class='table table-borderless reason-table'>
                                <tbody>
                                    <tr
                                        *ngIf="!client.CheckmonsterData?.LI?.BenefitList?.QuoteOption || quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData.LI.MedianCoverAmount, client.CheckmonsterData.LI.BenefitList.QuoteOption.CoverAmount)">
                                        <th>
                                            <p class='py-2 mb-0'>Life Cover: </p>
                                        </th>
                                        <td class='text-left'>
                                            <button mat-button class="d-flex w-100"
                                                    (click)="advisorCommentChangeHandler(client, 'LI')">
                                                <p class="reason py-2 mb-0">{{client.CheckmonsterData.LI.Comment.Message}}
                                                    <span aria-hidden="false" aria-label="expand_more icon"
                                                        class="material-icons mr-2 align-text-bottom text-blue scale-80">expand_more</span>
                                                </p>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="!client.CheckmonsterData?.TR?.BenefitList?.QuoteOption || quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData.TR.MedianCoverAmount, client.CheckmonsterData.TR.BenefitList.QuoteOption.CoverAmount)">
                                        <th>
                                            <p class='py-2 mb-0'>Trauma Cover: </p>
                                        </th>
                                        <td>
                                            <button mat-button class="d-flex w-100"
                                                    (click)="advisorCommentChangeHandler(client, 'TR')">
                                                <p class="reason py-2 mb-0">{{client.CheckmonsterData.TR.Comment.Message}}
                                                    <span aria-hidden="false" aria-label="expand_more icon"
                                                        class="material-icons mr-2 align-text-bottom text-blue scale-80">expand_more</span>
                                                </p>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="!client.CheckmonsterData?.TPD?.BenefitList?.QuoteOption || quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData.TPD.MedianCoverAmount, client.CheckmonsterData.TPD.BenefitList.QuoteOption.CoverAmount)">
                                        <th>
                                            <p class="py-2 mb-0">Total & Permanent Disablement: </p>
                                        </th>
                                        <td>
                                            <button mat-button class="d-flex w-100"
                                                    (click)="advisorCommentChangeHandler(client, 'TPD')">
                                                <p class="reason py-2 mb-0">{{client.CheckmonsterData.TPD.Comment.Message}}
                                                    <span aria-hidden="false" aria-label="expand_more icon"
                                                        class="material-icons mr-2 align-text-bottom text-blue scale-80">expand_more</span>
                                                </p>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="!client.CheckmonsterData?.IP?.BenefitList?.QuoteOption || quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData.IP.MonthlyCoverAmount, client.CheckmonsterData.IP.BenefitList.QuoteOption.IpMonthlyCoverAmount)">
                                        <th>
                                            <p class="py-2 mb-0">Income Protection: </p>
                                        </th>
                                        <td>
                                            <button mat-button class="d-flex w-100"
                                                    (click)="advisorCommentChangeHandler(client, 'IP')">
                                                <p class="reason py-2 mb-0">{{client.CheckmonsterData.IP.Comment.Message}}
                                                    <span aria-hidden="false" aria-label="expand_more icon"
                                                        class="material-icons mr-2 align-text-bottom text-blue scale-80">expand_more</span>
                                                </p>
                                            </button>
                                        </td>
                                    </tr>
                                    <tr
                                        *ngIf="!client.CheckmonsterData?.MP?.BenefitList?.QuoteOption || quoteStepCompareCheckmonster.hasCoverAmountGap(client.CheckmonsterData.MP.MonthlyCoverAmount, client.CheckmonsterData.MP.BenefitList.QuoteOption.CoverAmount)">
                                        <th>
                                            <p class="py-2 mb-0">Mortgage Protection: </p>
                                        </th>
                                        <td>
                                            <button mat-button class="d-flex w-100"
                                                    (click)="advisorCommentChangeHandler(client, 'MP')">
                                                <p class="reason py-2 mb-0">{{client.CheckmonsterData.MP.Comment.Message}}
                                                    <span aria-hidden="false" aria-label="expand_more icon"
                                                        class="material-icons mr-2 align-text-bottom text-blue scale-80">expand_more</span>
                                                </p>
                                            </button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </ng-container>

          </div>
        </li>
      </ng-container>
      <!-- confirm changes button -->
      <li class='list-group-item px-0'>
        <div class="row">
          <div class="col-12 text-right">
            <button class="px-4" type="button" mat-flat-button color="qm-pumpkin" [disabled]="!quoteStepCompareCheckmonster.isCheckmonsterEnabled()"
              (click)="quoteStepCompareCheckmonster.confirmCheckmonsterQuote();quoteStepCompareCheckmonster.loginService.doGoogleTracking('quote-checkmonster', 'goToBenefit', '');">
              Confirm changes
            </button>
          </div>
        </div>
      </li>
    </ng-container>
  </ul>
</div>
