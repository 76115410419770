import { Component, Input, OnInit } from '@angular/core';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Benefit } from 'src/app/models/benefit';
import { PeopleEntity } from 'src/app/models/people';
import { SelectItemStruct } from 'src/app/models/select.item';
import { BusinessQuoteService } from 'src/app/service/business/quote/business.quote.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { formatCurrency } from '@angular/common';




@Component({
    // tslint:disable-next-line:component-selector
    selector: 'people-benefit-detail-repeater-component',
    templateUrl: './bus-quote-benefit-repeater.component.html',
    styleUrls: ['./bus-quote-benefit-repeater.component.scss']
})
export class BusinessQuoteBenefitRepeaterComponent implements OnInit {

    @Input() client: PeopleEntity;

    loadingList: SelectItemStruct[];
    loadingListForHealth: SelectItemStruct[];
    calcPeriodList: SelectItemStruct[];
    waitPeriods: SelectItemStruct[];
    benefitPeriods: SelectItemStruct[];

    constructor(
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessQuoteService,
        public loginService: LoginService,
        public snackBar: MatSnackBar,
    ) {

    }

    ngOnInit() {
        this.loadingList = this.sharedFunction.getLoadingList(false);
        this.loadingListForHealth = this.sharedFunction.getLoadingList(true);
        this.calcPeriodList = this.sharedFunction.getCalcPeriodList();
        this.waitPeriods = this.getWaitPeriodList();
        this.benefitPeriods = this.getBenefitPeriodList();
        this.updateRuralBenefitName();
    }

    removeBenefit(benefit: Benefit){
        this.businessService.removeBenefit(this.client, benefit);
    }
    
    checkBenefitRecommendCoverAmount(benefit: Benefit): void {
        if (benefit.QuoteOption.CoverAmount > benefit.RecommendMaxCoverAmount) {
            this.sharedFunction.openSnackBar('', 'Close', 20000, `QuoteMonster suggests a maximum cover amount of
                    ${ formatCurrency(benefit.RecommendMaxCoverAmount, 'en-nz', '$', 'NZD', '1.0-0') } for ${ benefit.BenefitName }`);
        } else {
            this.snackBar.dismiss();
        }
    }

    onIpBenefitWeekWaitPeriodChange(client: PeopleEntity, benefit: Benefit) {
        // check has wop or not

        for (let b of client.BenefitList) {
            if (b.UIBenefitId === 9) {
                b.QuoteOption.WeekWaitPeriod = benefit.QuoteOption.WeekWaitPeriod;

                break;
            }
        }
    }

    setDefaultInsurerValue(value1: SelectItemStruct, value2: SelectItemStruct): boolean {
        if (value1 && value2) {
            return value1.Value === value2.Value;
        } else {
            return false;
        }

    }
    
    getWaitPeriodList(): SelectItemStruct[] {        
        return this.sharedFunction.getBusinessWaitPeriodList();
    }

    getBenefitPeriodList(): SelectItemStruct[] {        
        return this.sharedFunction.getBusinessBenefitPeriodList();
    }
  
    // for previous quote result
    updateRuralBenefitName(): void {
        if (this.client.BenefitList.find(b => b.BenefitId === 1006)) {
            this.client.BenefitList.find(b => b.BenefitId === 1006).BenefitName = "Business Rural Key Person Cover";
        }
    }
}
