import { Component, OnInit, AfterContentChecked, isDevMode } from '@angular/core';
import { LoginService } from '../../service/login.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NeedAnalysisSetting } from '../../models/need.analysis.setting';
import { SharedFunctionService } from '../../service/shared.function.service';
import { NeedAnalysisService } from '../../service/need.analysis.service';

import { ComponentBaseClass } from 'src/app/service/base';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { Observable } from 'rxjs';
import { NavigationStart, Router } from '@angular/router';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { CanComponentDeactivate } from '../shared/can-deactivate-guard.service';
import { QuoteService } from 'src/app/service/quote.service';
import { ApiService } from 'src/app/service/api.service';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'need-setting',
    templateUrl: './need-setting.component.html',
    styleUrls: ['./need-setting.component.scss']
})

export class NeedSettingComponent extends ComponentBaseClass implements OnInit, AfterContentChecked, CanComponentDeactivate {

    showLoading: boolean = false;
    needAnalysisSetting: NeedAnalysisSetting;
    messageShow: boolean = false;

    itemList = [
        { Name: 'KiwiSaver', Value: 1 },
        { Name: 'Home loans', Value: 2 },
        { Name: 'House, property, car, travel, or business insurance', Value: 3 },
        { Name: 'ACC cover', Value: 4 },
        { Name: 'Wills', Value: 5 },
        { Name: 'Trusts', Value: 6 },
        { Name: 'Enduring Power of Attorney', Value: 7 },
        { Name: 'Investments', Value: 8 },
        { Name: 'Cash', Value: 9 }];





    weDoList: any[] = [];
    weDoNotDoList: any[] = [];


    // should like ['value1', 'value 2']
    clientObjectivesSetting = [];

    newClientObjective = '';

    showResetClientObjectivesSetting: boolean = false;


    private currentDraggableEvent: DragEvent;
    private currentDragEffectMsg: string;
    layout: any;
    horizontalLayoutActive: boolean = false;

    private readonly verticalLayout = {
        container: 'row',
        list: 'column',
        dndHorizontal: false
    };
    private readonly horizontalLayout = {
        container: 'row',
        list: 'row',
        dndHorizontal: true
    };

    dndEffectAllowed = 'copyMove';
    hasChange: boolean = true;
    newUrl: string = '';
    isDevMode: boolean = false;



    ableToSeeCheckmonsterSetting: boolean = false;


    constructor(
        public loginService: LoginService,
        public snackBar: MatSnackBar,
        public sharedFunction: SharedFunctionService,
        private needAnalysisService: NeedAnalysisService,
        private router: Router,
        public confirmDialog: ConfirmMessageDialogService,
        public quoteService: QuoteService,
        private apiService: ApiService
    ) {
        super();
    }

    ngOnInit() {
        this.isDevMode = isDevMode();
        this.loginService.checkLoginStatus();
        this.needAnalysisSetting = new NeedAnalysisSetting();
        this.loadNeedAnalysisSetting();

        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.newUrl = event.url;
            }
        });

      this.ableToSeeCheckmonsterSetting = this.loginService.hasFeatureAccess(this.quoteService.quoteRequiredData.CheckMonsterGroups);

    }


    ngAfterContentChecked(): void {

    }



    loadNeedAnalysisSetting() {

        this.showLoading = true;
        this.showDinoLoading();
        this.weDoList = [];
        this.weDoNotDoList = [];

        this.needAnalysisService.getUserNeedCrunchingSetting((response) => {
            if (response) {
                this.needAnalysisSetting.NcWeProvideAdvice = response.NcWeProvideAdvice;
                this.needAnalysisSetting.NcWeDoNotProvideAdvice = response.NcWeDoNotProvideAdvice;


                // TODO: set we provide and we do not provide

                this.needAnalysisSetting.IsProvideLife = response.IsProvideLife;
                this.needAnalysisSetting.IsProvideIpMp = response.IsProvideIpMp;
                this.needAnalysisSetting.IsProvideMedical = response.IsProvideMedical;
                this.needAnalysisSetting.IsProvideTpd = response.IsProvideTpd;
                this.needAnalysisSetting.IsProvideTrauma = response.IsProvideTrauma;
                this.needAnalysisSetting.Description = response.Description;

                let selectedIdList: number[] = [];
                if (response.Description) {
                    try {
                        let obj: any = JSON.parse(response.Description);

                        this.needAnalysisSetting.LifeCoverFuneralCost
                            = this.sharedFunction.getNumberValue(obj.LifeCoverFuneralCost, this.needAnalysisSetting.LifeCoverFuneralCost);

                        this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff
                            = this.sharedFunction.getNumberValue(obj.LifeCoverPercentageOfDebtToPayOff,
                                this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff);

                        this.needAnalysisSetting.LifeCoverReplaceYourIncome
                            = this.sharedFunction.getNumberValue(obj.LifeCoverReplaceYourIncome,
                                this.needAnalysisSetting.LifeCoverReplaceYourIncome);

                        this.needAnalysisSetting.LifeCoverAfterTaxAnnualInterest
                            = this.sharedFunction.getNumberValue(obj.LifeCoverAfterTaxAnnualInterest,
                                this.needAnalysisSetting.LifeCoverAfterTaxAnnualInterest);

                        this.needAnalysisSetting.LifeCoverInflationRate
                            = this.sharedFunction.getNumberValue(obj.LifeCoverInflationRate,
                                this.needAnalysisSetting.LifeCoverInflationRate);

                        this.needAnalysisSetting.TraumaEmergencyFundOfIncome
                            = this.sharedFunction.getNumberValue(obj.TraumaEmergencyFundOfIncome,
                                this.needAnalysisSetting.TraumaEmergencyFundOfIncome);

                        this.needAnalysisSetting.TpdEmerencyFundOfIncome
                            = this.sharedFunction.getNumberValue(obj.TpdEmerencyFundOfIncome,
                                this.needAnalysisSetting.TpdEmerencyFundOfIncome);



                        if (!this.sharedFunction.isNullOrEmptyList(obj.WeDoList)) {
                            for (let id of obj.WeDoList) {
                                for (let item of this.itemList) {
                                    if (id === item.Value && selectedIdList.indexOf(item.Value) === -1) {
                                        this.weDoList.push(item);
                                        selectedIdList.push(item.Value);
                                        break;
                                    }
                                }
                            }
                        }

                        if (!this.sharedFunction.isNullOrEmptyList(obj.WeDoNotDoList)) {
                            for (let id of obj.WeDoNotDoList) {
                                for (let item of this.itemList) {
                                    if (id === item.Value && selectedIdList.indexOf(item.Value) === -1) {
                                        this.weDoNotDoList.push(item);
                                        selectedIdList.push(item.Value);
                                        break;
                                    }
                                }
                            }
                        }
                    } catch (error) {

                    }
                }

                // set client objectives list
                try {
                    this.clientObjectivesSetting = JSON.parse(response.ClientObjectiveListJson);
                } catch (error) {
                    this.clientObjectivesSetting = [];
                }



                // check if objective is empty.
                if (!this.clientObjectivesSetting || this.clientObjectivesSetting.length <= 0) {
                    this.clientObjectivesSetting = [];
                    let objectivesListQm = new NeedAnalysisSetting().getClientObjectiveList();
                    for (let obj of objectivesListQm) {
                        this.clientObjectivesSetting.push(obj.Name);
                    }
                }


                // check non selected item from we do and we do not do list
                // if any item unselected, then move it into we do not do list
                for (let item of this.itemList) {
                    if (selectedIdList.indexOf(item.Value) === -1) {
                        this.weDoNotDoList.push(item);
                    }
                }

            }
            this.loadUserQmSetting();
            this.showLoading = false;
            this.closeDinoLoading();
        });
    }


    updateNeedCrunchingSetting() {
        this.showLoading = true;
        this.showDinoLoading();
        this.needAnalysisSetting.NcWeProvideAdvice = '';
        this.needAnalysisSetting.NcWeDoNotProvideAdvice = '';

        let weDoIdList: number[] = [];
        let weDoNotDoIdList: number[] = [];

        if (this.weDoList) {
            for (let item of this.weDoList) {
                this.needAnalysisSetting.NcWeProvideAdvice += item.Name + ',';
                weDoIdList.push(item.Value);
            }
        }

        if (this.weDoNotDoList) {
            for (let item of this.weDoNotDoList) {
                this.needAnalysisSetting.NcWeDoNotProvideAdvice += item.Name + ',';
                weDoNotDoIdList.push(item.Value);
            }
        }



        let obj: any = {
            WeDoList: weDoIdList,
            WeDoNotDoList: weDoNotDoIdList,
            LifeCoverFuneralCost: this.needAnalysisSetting.LifeCoverFuneralCost,
            LifeCoverPercentageOfDebtToPayOff: this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff,
            LifeCoverReplaceYourIncome: this.needAnalysisSetting.LifeCoverReplaceYourIncome,
            LifeCoverAfterTaxAnnualInterest: this.needAnalysisSetting.LifeCoverAfterTaxAnnualInterest,
            LifeCoverInflationRate: this.needAnalysisSetting.LifeCoverInflationRate,
            TraumaEmergencyFundOfIncome: this.needAnalysisSetting.TraumaEmergencyFundOfIncome,
            TpdEmerencyFundOfIncome: this.needAnalysisSetting.TpdEmerencyFundOfIncome,
        };

        this.needAnalysisSetting.Description = JSON.stringify(obj);

        this.needAnalysisSetting.ClientObjectiveListJson = JSON.stringify(this.clientObjectivesSetting);

        this.needAnalysisService.updateUserNeedCrunchingSetting(this.needAnalysisSetting, (response) => {
            // this.loadNeedAnalysisSetting();
            this.updateUserQmSettings();
            this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK' ,2000);
            this.showLoading = false;
            this.closeDinoLoading();

            if (this.newUrl) {
                this.router.navigate([this.newUrl]);
            }
            
            let currentQuote = this.quoteService.getCurrentQuote(null);
            if (currentQuote.IsQuoteWizard) {
                currentQuote.QuoteWizardData.IsQWSuggestionDataChanged = undefined;
                this.quoteService.saveCurrentQuote(currentQuote);
            }
        });
    }

    resetNeedSetting() {

        let defaultNeedAnalysisSetting = new NeedAnalysisSetting();

        this.needAnalysisSetting.LifeCoverFuneralCost = defaultNeedAnalysisSetting.LifeCoverFuneralCost;

        this.needAnalysisSetting.LifeCoverPercentageOfDebtToPayOff = defaultNeedAnalysisSetting.LifeCoverPercentageOfDebtToPayOff;

        this.needAnalysisSetting.LifeCoverReplaceYourIncome = defaultNeedAnalysisSetting.LifeCoverReplaceYourIncome;

        this.needAnalysisSetting.LifeCoverAfterTaxAnnualInterest = defaultNeedAnalysisSetting.LifeCoverAfterTaxAnnualInterest;

        this.needAnalysisSetting.LifeCoverInflationRate = defaultNeedAnalysisSetting.LifeCoverInflationRate;

        this.needAnalysisSetting.TraumaEmergencyFundOfIncome = defaultNeedAnalysisSetting.TraumaEmergencyFundOfIncome;

        this.needAnalysisSetting.TpdEmerencyFundOfIncome = defaultNeedAnalysisSetting.TpdEmerencyFundOfIncome;


        this.needAnalysisSetting.IsProvideLife = true;
        this.needAnalysisSetting.IsProvideIpMp = true;
        this.needAnalysisSetting.IsProvideMedical = true;
        this.needAnalysisSetting.IsProvideTpd = true;
        this.needAnalysisSetting.IsProvideTrauma = true;


        this.updateNeedCrunchingSetting();
    }


    // **************drag & drop event ***************/
    onDragStart(event: DragEvent) {

        this.currentDragEffectMsg = '';
        this.currentDraggableEvent = event;
    }



    setHorizontalLayout(horizontalLayoutActive: boolean) {

        this.layout = (horizontalLayoutActive) ? this.horizontalLayout : this.verticalLayout;
    }



    // client objective start

    moveClientObjectiveUp(clientObjective: string) {

        let index = this.clientObjectivesSetting.indexOf(clientObjective);

        if (index != 0) {

            this.clientObjectivesSetting[index] =
                this.clientObjectivesSetting.splice(index - 1, 1, this.clientObjectivesSetting[index])[0];

        } else {

            this.clientObjectivesSetting.push(this.clientObjectivesSetting.shift());

        }
    }

    moveClientObjectiveDown(clientObjective: string) {

        let index = this.clientObjectivesSetting.indexOf(clientObjective);

        if (index !== this.clientObjectivesSetting.length - 1) {
            this.clientObjectivesSetting[index] =
                this.clientObjectivesSetting.splice(index + 1, 1, this.clientObjectivesSetting[index])[0];

        } else {
            this.clientObjectivesSetting.unshift(this.clientObjectivesSetting.splice(index, 1)[0]);
        }
    }


    addClientObjective() {

        if (!this.sharedFunction.isNullOrEmptyString(this.newClientObjective)) {
            this.clientObjectivesSetting.push(this.newClientObjective);
        }

        this.newClientObjective = '';
    }

    deleteClientObjective(clientObjective: any) {

        if (!this.sharedFunction.isNullOrEmptyString(clientObjective)) {

            let index = this.clientObjectivesSetting.indexOf(clientObjective);

            if (index >= 0) {
                this.clientObjectivesSetting.splice(index, 1);
            }

        }
    }

    resetNeedSettingClientObj() {
        this.clientObjectivesSetting = [];
        let objectivesListQm = new NeedAnalysisSetting().getClientObjectiveList();
        for (let obj of objectivesListQm) {
            this.clientObjectivesSetting.push(obj.Name);
        }
        this.updateNeedCrunchingSetting();
    }

    canDeactivate(): Observable<boolean> | boolean {

        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();

        messageSetting.Title = 'Alert';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-INFO-SaveChanges');
        messageSetting.NoBtnName = 'NO';
        messageSetting.NewUrl = this.newUrl;

        let allowChange: boolean = true;

        if (this.hasChange) {
            allowChange = false;
            this.confirmDialog.confirm(messageSetting).subscribe((response) => {
                if (response.ReturnValue === false) {
                    this.hasChange = false;
                    this.router.navigate([response.NewUrl]);
                } else if (response.ReturnValue === true) {
                    this.hasChange = false;
                    this.updateNeedCrunchingSetting()
                }
            });
        }

        return allowChange;
    }

    loadUserQmSetting() {
        // load default user qm setting
        this.quoteService.getUserQmSetting((response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_CHECKMONSTER) {
                this.needAnalysisSetting.IsCheckmonsterRecommend = Number(response.QmSetting.DEF_CHECKMONSTER);
            }
        });
    }

    updateUserQmSettings() {
        const newSetting: any = {
            DEF_CHECKMONSTER: this.needAnalysisSetting.IsCheckmonsterRecommend ? "1" : "0"
        };
        this.quoteService.updateUserQmSetting(newSetting, (response) => {
            if (response && response.QmSetting && response.QmSetting.DEF_CHECKMONSTER) {
                this.needAnalysisSetting.IsCheckmonsterRecommend = Number(response.QmSetting.DEF_CHECKMONSTER);
                this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'Close');
            }
        });
    }

}
