import { Injectable, isDevMode } from "@angular/core";
import { UserMenuGroup, UserMenuItem } from "../models/user.menu.group.item.model";
import { DataLoader } from "./data.loader";
import { LoginService } from "./login.service";

@Injectable({
    providedIn: 'root',
})
export class UserMenuService {

    private allUserMenuGroups: UserMenuGroup[];

    constructor(
        private loginService: LoginService,
        private dataLoader: DataLoader,
    ) {
        const requiredData = this.dataLoader.getRequiredData();
        if (requiredData && requiredData.UserMenuGroups && requiredData.UserMenuGroups.length > 0) {
            this.allUserMenuGroups = requiredData.UserMenuGroups;
        }
    }

    getUserMenuGroups(menuCodes: string[], checkPlatform: boolean = false): UserMenuGroup[] {
        let userMenuGroups: UserMenuGroup[] = [];

        for (let menuGroup of this.allUserMenuGroups) {
            // do menu items
            if (this.canBeAddedGroup(menuCodes, menuGroup, checkPlatform)) {
                let userMenuItems: UserMenuItem[] = [];

                for (let menuItem of menuGroup.Items) {
                    if (this.canBeAddedItem(menuItem, checkPlatform)) {
                        let userMenuItem: UserMenuItem = new UserMenuItem();
                        userMenuItem.ItemName = menuItem.ItemName;
                        userMenuItem.ItemActionLink = [];
                        userMenuItem.ItemActionLink.push(menuItem.ItemLink);
                        userMenuItem.TrackingName = menuItem.TrackingName;
                        userMenuItem.PlatformSetting = menuItem.PlatformSetting;
                        userMenuItems.push(userMenuItem);
                    }
                }


                // add menu group
                if (userMenuItems && userMenuItems.length > 0) {
                    let userMenuGroup: UserMenuGroup = new UserMenuGroup();
                    userMenuGroup.GroupName = menuGroup.GroupName;
                    userMenuGroup.Code = menuGroup.Code;
                    userMenuGroup.GroupIcon = menuGroup.GroupIcon;
                    userMenuGroup.Items = userMenuItems;
                    userMenuGroups.push(userMenuGroup);
                }
            }
        }



        return userMenuGroups;
    }



    private canBeAddedGroup(menuCodes: string[], menuGroup: UserMenuGroup, checkPlatform: boolean = false): boolean {

        if (menuCodes && menuCodes.length > 0 && menuCodes.indexOf(menuGroup.Code) < 0) {
            return false;
        }

        // check user access role
        if (menuGroup.MinAccessLevel > 0 && !this.loginService.checkUserAccessRole(menuGroup.MinAccessLevel)) {
            return false;
        }

        // check if PA login
        if (!menuGroup.CanbeAccessByPA && this.loginService.isPaLogin()) {
            return false;
        }

        // check if DevModel only
        if (menuGroup.DevModeOnly && !isDevMode()) {
            return false;
        }
        
        // check platform setting
        if (!menuGroup.GroupPlatformSetting.includes(this.loginService.getPlatformSetting()) && checkPlatform) {
            return false;
        }
      
        // check hasAccessToKiwimonster
        if (menuGroup.Code === 'KIWIMONSTERSETTINGS' && !this.loginService.hasAccessToKiwimonster()) {
           return false;
        }

        return true;
    }

    private canBeAddedItem(menuItem: UserMenuItem, checkPlatform:boolean = false): boolean {
        // check user access role
        if (menuItem.MinAccessLevel > 0 && !this.loginService.checkUserAccessRole(menuItem.MinAccessLevel)) {
            return false;
        }

        // check if PA login
        if (!menuItem.CanbeAccessByPA && this.loginService.isPaLogin()) {
            return false;
        }
        
        // check if PA only
        if (menuItem.ItemLink.indexOf('/setting/pa-security') > -1 && !this.loginService.isPaLogin()) {
            return false;
        }

        // check if DevModel only
        if (menuItem.DevModeOnly && !isDevMode()) {
            return false;
        }
        
        // check platform setting
        if (!menuItem.PlatformSetting.includes(this.loginService.getPlatformSetting()) && checkPlatform) {
            return false;
        }

        return true;
    }
    
    public addTimestampForPdfFile(itemLink: string): string {
        if (itemLink.includes(".pdf")) {
            return `${ itemLink }?t=${ Date.now() }`;
        }
        return itemLink;
    }
}