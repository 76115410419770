import { QuoteOption } from './quote.option';
import { SelectItemStruct } from './select.item';


export class QuoteOptionRural extends QuoteOption {

    KeyPersonPartialDisabilityOption: boolean;
    Booster: boolean;
    constructor() {
        super();
        this.CoverAmount = 0;
        this.KeyPersonPartialDisabilityOption = true;
        this.Booster = false;
        this.WeekWaitPeriod = new SelectItemStruct('4 Weeks', '', 4, false, 0, '', '', '', '', '', '');
        this.BenefitPeriod = new SelectItemStruct('2 Years', '', 2, false, 0, '', '', '', '', '', '');
        
    }
}
