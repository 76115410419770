import { Router } from "@angular/router";
import { ApiService } from "src/app/service/api.service";

export class BaseComponent{

      constructor (
        public router: Router,
        public apiService: ApiService
      ) { }

    checkServiceAccess( moduleCode: string, navigateToUrl: string = 'dashboard'): boolean {
       let isAccessable: boolean = true;

        const accessDeniedServices = this.apiService.getUserToken().AccessDeniedServices;
        console.log('accessDeniedServices', accessDeniedServices);
        if(accessDeniedServices && accessDeniedServices.length > 0){
            for (let i = 0; i < accessDeniedServices.length; i++) {
                if(accessDeniedServices[i].toUpperCase().trim() === moduleCode.toUpperCase().trim()){
                    isAccessable = false;
                    break;
                }
            }
        }

        if(!isAccessable){
            alert('You do not have access to this service. Please contact us for more information.');
           this.router.navigate([navigateToUrl]);            
        }

        return isAccessable;
    }
}