<ul class="list-group">
    <li class="list-group-item active">
        <h4 class='float-left mr-3'>Default Settings</h4>
        <div class='float-right'>
            <recent-quote-component [providerSetting]="userProviderSetting" [isBusiness]='true'></recent-quote-component>
        </div>
    </li>

    <!-- frequency -->
    <li class='list-group-item'>
        <div class='row'>
            <div class='col-12'>
                <mat-form-field>
                    <mat-label>Default instalment frequency</mat-label>
                    <mat-select [(ngModel)]="frequencyLoading"
                                (selectionChange)='changeDefaultFrequency();loginService.doGoogleTracking("business-provider-product-setting", "change-frequency", "");'>
                        <mat-option *ngFor="let f of quoteService.quoteRequiredData.FrequencyList"
                                    [value]='f.Value'>{{f.Name}}
                        </mat-option>
                    </mat-select>
                </mat-form-field>
            </div>
        </div>
    </li>

    <li class="list-group-item active">
        <h4 class='float-left mr-3'>Business - Product Settings</h4>
        <div class='float-right'>
            <!-- reset to default button -->
            <button mat-raised-button color="warn" (click)='showResetAlert = !showResetAlert'
                    title='click here to reset your product settings to default'>Reset to default</button>
            <!-- help button -->
            <button mat-icon-button color='qm-white' class='ml-2 qm-icon-btn-sm'
                    (click)='showHelpInfo = !showHelpInfo;loginService.doGoogleTracking("business-provider-product-setting", "help message", "");'
                    title="help">
                <span class="material-icons">contact_support</span>
            </button>
        </div>
    </li>
    <!-- help message -->
    <li class="list-group-item" *ngIf='showHelpInfo'>
        <h5>Product and Provider Settings</h5>
        <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteSetting-INFO-Help')"></span>
    </li>
    <!-- reset confirm dialog -->
    <li class="list-group-item" *ngIf='showResetAlert'>
        <div class='row alert alert-warning justify-content-center align-items-center mx-1 px-3'>
            <div class='col-auto text-center my-2'>
                <strong>{{sharedFunction.getUiMessageByCode("Share-WARNING-ResetToDefault")}}</strong>
            </div>
            <div class='col-auto my-2 py-1'>
                <!-- reset Yes button -->
                <button mat-raised-button class='mr-2' color="warn"
                        (click)='showResetAlert=false;resetProviderSettings();loginService.doGoogleTracking("business-provider-product-setting", "reset-yes", "");'>YES</button>
                <!-- reset No button -->
                <button mat-raised-button color="qm-black"
                        (click)='showResetAlert=false;loginService.doGoogleTracking("business-provider-product-setting", "reset-no", "");'>NO</button>
            </div>
        </div>
    </li>

    <li class='list-group-item'>
        <div class='table-responsive setting-quote-table'>
            <table class="table">
                <thead>
                    <tr class='table-info-thead'>
                        <div class='row flex-nowrap mx-0'>
                            <div class='col-2 py-2 px-2'>
                                <strong>Select Provider</strong>
                            </div>
                            <!-- <div class='col-2 py-2 px-4'>
                <strong>Health</strong>
              </div> -->
                            <div class='col-2 py-2 px-4'>
                                <strong>Trauma</strong>
                            </div>
                            <div class='col-2 py-2 px-4'>
                                <strong>Key Person</strong>
                            </div>
                            <div class='col-2 py-2 px-4'>
                                <strong>Rural</strong>
                            </div>
                            <!-- <div class='col-2 py-2 px-4'>
                <strong>Mortgage</strong>
              </div> -->
                        </div>
                    </tr>
                </thead>
                <tbody>
                    <tr class='border-bottom' *ngFor="let provider of userProviderSetting">
                        <div class='row flex-nowrap mx-0'>
                            <div class='col-2 py-2 px-2'>
                                <mat-checkbox [(ngModel)]="provider.IsSelected"
                                              (change)="changeProviderSetting(provider)" class="ml-1 provider-checkbox">
                                    <img src="{{provider.ProviderLogoUrl}}?t={{timestamp}}" class='pl-3' />
                                </mat-checkbox>
                            </div>

                            <ng-container *ngIf='provider.IsSelected'>
                                <!--Health-->
                                <!-- <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 1'>
                      <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                        (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                    </ng-container>
                  </ng-container>

                  <ng-container *ngIf='!hasBenefit(provider.BenefitList, 1) '>
                    <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                      (click)='changeProduct(provider, getHealthBenefit(provider))'>Select</button>
                  </ng-container>
                </div> -->

                                <!--Trauma-->
                                <div class='col-2 py-2 px-2'>
                                    <ng-container *ngFor="let benefit of provider.BenefitList">
                                        <ng-container *ngIf='benefit.BenefitId === 1003'>
                                            <button type="button" mat-button
                                                    class='text-wrap text-left line-height-md align-middle'
                                                    (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                                        </ng-container>
                                    </ng-container>
                                </div>

                                <!--key person-->
                                <div class='col-2 py-2 px-2'>
                                    <ng-container *ngFor="let benefit of provider.BenefitList">
                                        <ng-container *ngIf='benefit.BenefitId === 1005'>
                                        <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                                            (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                                        </ng-container>
                                    </ng-container>
                                </div>

                                <!--Rural-->
                                <div class='col-2 py-2 px-2'>
                                    <ng-container *ngFor="let benefit of provider.BenefitList">
                                        <ng-container *ngIf='benefit.BenefitId === 1006'>
                                        <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                                            (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                                        </ng-container>
                                    </ng-container>
                                </div>

                                <!--MP-->
                                <!-- <div class='col-2 py-2 px-2'>
                  <ng-container *ngFor="let benefit of provider.BenefitList">
                    <ng-container *ngIf='benefit.BenefitId === 7  '>
                      <button type="button" mat-button class='text-wrap text-left line-height-md align-middle'
                        (click)='changeProduct(provider, benefit)'>{{benefit.SelectedProductName}}</button>
                    </ng-container>
                  </ng-container>
                </div> -->
                            </ng-container>

                            <ng-container *ngIf='!provider.IsSelected'>
                                <!--Health-->
                                <!-- <div class='col-2 py-2 px-2'>
                </div> -->

                                <!--Trauma-->
                                <div class='col-2 py-2 px-2'>
                                </div>

                                <!--IP taxable-->
                                <!-- <div class='col-2 py-2 px-2'>
                </div> -->

                                <!--IP non-taxable-->
                                <!-- <div class='col-2 py-2 px-2'>
                </div> -->

                                <!--MP-->
                                <!-- <div class='col-2 py-2 px-2'>
                </div> -->
                            </ng-container>
                        </div>
                        <div class='row flex-nowrap mx-0 border-top py-2' *ngIf='provider.ProviderId === 1 && provider.IsSelected '>
                            <div class='col-12'>
                                <span class='alert alert-success d-block mb-0'>
                                    {{sharedFunction.getUiMessageByCode("QuoteSetting-INFO-AIAVitalityOptions")}}
                                </span>
                            </div>
                        </div>
                        <div class='row flex-nowrap mx-0 border-top py-2' *ngIf='provider.ProviderId === 9 && provider.IsSelected '>
                            <div class='col-12'>
                                <span class='alert alert-success d-block mb-0'>
                                    {{sharedFunction.getUiMessageByCode("QuoteSetting-INFO-ChubbVitalityOptions")}}
                                </span>
                            </div>
                        </div>
                        <div class='row flex-nowrap mx-0 border-top py-2'
                            *ngIf='provider.ProviderId === 4 && provider.IsSelected && sharedFunction.getUiMessageByCode("QuoteSetting-INFO-ASTBusiness")'>
                            <div class='col-12'>
                                <span class='alert alert-success d-block mb-0'>
                                    {{sharedFunction.getUiMessageByCode("QuoteSetting-INFO-ASTBusiness")}}
                                </span>
                            </div>
                        </div>
                    </tr>
                </tbody>
            </table>
        </div>
    </li>
</ul>