import { Injectable, isDevMode } from "@angular/core";
import { SavedBusinessRecord } from "src/app/models/business/saved.business.record";
import { BusinessService } from "../business.service";
import { Benefit } from 'src/app/models/benefit';
import { PeopleEntity } from 'src/app/models/people';
import { Person } from 'src/app/models/business/business.person';
import { SelectItemStruct } from 'src/app/models/select.item';
import { UserQmSettingModel } from 'src/app/models/user.qm.setting';
import { Observable, forkJoin } from 'rxjs';
import { Provider } from 'src/app/models/provider';
import { QuoteResultItem } from 'src/app/models/quote.result.item';
import { QuoteResult } from 'src/app/models/quote.result';
import * as dayjs from 'dayjs';
import { BusinessCurrentQuote } from 'src/app/models/business/business-current-quote.model';
import { SpecialQuoteSetting } from 'src/app/models/special.quote.setting';
import { BusinessQuoteNcsRecord } from 'src/app/models/business/business-quote-ncs-record.model';
import { QuoteOptionLife } from 'src/app/models/quote.option.life';
import { QuoteOptionTrauma } from 'src/app/models/quote.option.trauma';
import { QuoteOptionTPD } from 'src/app/models/quote.option.tpd';
import { QuoteOptionKeyPerson } from 'src/app/models/quote.option.keyPerson';
import { QuoteOptionRural } from 'src/app/models/quote.option.rural';


@Injectable({
    providedIn: 'root',
})
export class BusinessQuoteService extends BusinessService {
    currentSavedBusinessRecord: SavedBusinessRecord;
    currentSavedBusinessQuote: BusinessCurrentQuote;
    providerSetting: Provider[] = [];
    errorMessages: string[] = [];
    showReCrunchAlert: boolean = false;

    onInit(): void {
        // get business record
        this.currentSavedBusinessRecord = this.getCurrentBusinessRecord();
        this.currentSavedBusinessRecord.CurrentStep = this.StepBenefits;
        // get business quote
        this.currentSavedBusinessQuote = this.getCurrentBusinessQuote();
        this.currentSavedBusinessQuote.CurrentStep = this.StepBenefits;

        // new quote: set default quote name
        this.currentSavedBusinessQuote.QuoteName = this.setQuoteName();
        
        // get quote clients
        this.currentSavedBusinessQuote.PeopleEntity = this.setQuoteClients(this.currentSavedBusinessRecord.People, this.currentSavedBusinessQuote.PeopleEntity);

        // set quote client benefit
        this.setClientBenefit(this.currentSavedBusinessQuote.PeopleEntity);
        
        this.setProviderSettings(this.currentSavedBusinessQuote, () => {
            // do quote from Need analysis data
            if (this.currentSavedBusinessRecord &&
                this.currentSavedBusinessRecord.BusinessNeedAnalysisData &&
                this.currentSavedBusinessRecord.BusinessNeedAnalysisData.NeedAnalysisAndQuote) {
                this.createQuoteBenefitsFromNeedAnalysis(this.currentSavedBusinessQuote, this.currentSavedBusinessRecord, this.getAvailableBenefits());
                
                this.currentSavedBusinessRecord.BusinessNeedAnalysisData.NeedAnalysisAndQuote = false;
                
                this.currentSavedBusinessQuote.ExtraData = {BusinessId: this.currentSavedBusinessRecord.Id, NeedAnalysisId: this.currentSavedBusinessRecord.BusinessNeedAnalysisData.Id }

                this.doBusinessCrunch('business-need-analysis: save and quote', false, null)
            } else {
                this.closeDinoLoading();
            }
            // save to local storage(after callback)
            this.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
            this.saveCurrentBusinessQuote(this.currentSavedBusinessQuote);
        });
        
        // save to local storage(did not call callback)
        this.saveCurrentBusinessRecord(this.currentSavedBusinessRecord);
        this.saveCurrentBusinessQuote(this.currentSavedBusinessQuote);
    }

    validData(data: SavedBusinessRecord): boolean {
        this.errorMessages = [];
        let isValid: boolean = true;
        return isValid;
    }

    onSave(data: SavedBusinessRecord, callback: (isSuccess: boolean, message: string) => void) {
        this.showDinoLoading();
        // call API to update
        this.createUpdateBusinessRecord(data, (response: SavedBusinessRecord) => {
            if (response && response.Message.MessageCode === 200) {
                // update local data
                this.saveCurrentBusinessRecord(response);
                callback(true, '');
            } else if (response && response.Message.MessageCode !== 200 && response.Message.Message) {
                callback(false, response.Message.Message);
            } else {
                callback(false, this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong'));
            }
            this.closeDinoLoading();
        });
    }

    doBusinessCrunch(quoteReference: string, loadQprRating: boolean, callback: () => void | null): void {
        if (this.validateDataForCrunching(this.currentSavedBusinessQuote)) {

            this.showDinoLoading();
            this.updateBenefitFrequency(this.currentSavedBusinessQuote);

            this.currentSavedBusinessQuote.QuoteReference = quoteReference;
            this.currentSavedBusinessQuote.QuoteResult = new QuoteResult();

            this.currentSavedBusinessQuote.NoExtraData = !loadQprRating;

            this.quoteForBusiness(this.currentSavedBusinessQuote, (response: QuoteResult) => {
                if (response) {
                    let hasValidQuoteResult: boolean = true;
                    this.currentSavedBusinessQuote.QuoteResult = response;
                    this.currentSavedBusinessQuote.AppId = response.AppId;
                    this.setProviderSettings(this.currentSavedBusinessQuote, null);
                    this.currentSavedBusinessQuote.QuoteDate = dayjs(response.QuoteDate).format('DD MMM YYYY, HH:mm');

                    // set UIBenefitId
                    this.currentSavedBusinessQuote.QuoteResult.QuoteResultList.forEach(quoteResult => quoteResult.Result.forEach(client => this.resetQuoteResultUIBenefitId(client.BenefitList)));
                    
                    this.saveCurrentBusinessQuote(this.currentSavedBusinessQuote);

                    // check if has valid quote result 
                    if (this.currentSavedBusinessQuote.QuoteResult.QuoteResultList.filter((item) => item.ErrorMessages.length === 0).length === 0) {
                        this.errorMessages.push(this.sharedFunction.getUiMessageByCode("QuoteStepBenefit-ERROR-NoValidQuoteResult"));
                        hasValidQuoteResult = false;
                    }
                    
                    // remove reCrunchAlert after doCrunch
                    this.showReCrunchAlert = false;
                    
                    if (callback && hasValidQuoteResult) {
                        callback();                
                    }
                }
                this.closeDinoLoading();
            });
        }
    }

    updateBenefitFrequency(currentSavedBusinessQuote: BusinessCurrentQuote): void {
        currentSavedBusinessQuote.PeopleEntity.forEach(client => client.BenefitList.forEach(benefit => benefit.QuoteOption.Frequency = currentSavedBusinessQuote.FrequencyLoading));
    }

    validateDataForCrunching(currentSavedBusinessQuote: BusinessCurrentQuote): boolean {
        this.errorMessages = [];
        let isReadyForCrunching = true;

        // check quote name
        if (!currentSavedBusinessQuote.QuoteName.trim()) {
            this.errorMessages.push(this.sharedFunction.getUiMessageByCode('BusinessQuote-ERROR-QuoteNameRequired'));
        }

        // check is there any benefit
        let totalBenefit: number = 0;
        currentSavedBusinessQuote.PeopleEntity.forEach(client => {
            for (let benefit of client.BenefitList) {
                totalBenefit += client.BenefitList.length;
            }
        });
        if (totalBenefit === 0) {
            this.errorMessages.push(this.sharedFunction.getUiMessageByCode("QuoteStepBenefit-ERROR-TotalBenefits"));
        }

        // check Cover Amount
        currentSavedBusinessQuote.PeopleEntity.forEach(client => {
            for (let benefit of client.BenefitList) {
                if (benefit.BenefitId != 9 && benefit.QuoteOption.CoverAmount <= 0) {
                    this.errorMessages.push(`${ client.FirstName } ${ client.LastName }: Please enter Cover Amount for ${ benefit.BenefitName }`);
                }
            }
        });

        // set some special benefit setting
        let numberOfOwnOcc: number = 0;
        let numberOfAnyOcc: number = 0;
        currentSavedBusinessQuote.PeopleEntity.forEach(client => {
            numberOfOwnOcc = 0;
            numberOfAnyOcc = 0;
            for (let benefit of client.BenefitList) {
                if (benefit.UIBenefitId === 100301 || benefit.UIBenefitId === 100302) {
                    // Trauma Accelerated, Trauma Standalone
                    // shouldn't be able to quote Any Occ and Own Occ together on Trauma TPD add On (TODO: what about Trauma Accelerated Level, Trauma Standalone Level?)
                    if (benefit.QuoteOption.TpdAddOn === true) {
                        if (benefit.QuoteOption.TpdOwnOccupation === true) {
                            numberOfOwnOcc++;
                        } else {
                            numberOfAnyOcc++;
                        }
                    }
                }

                if (benefit.UIBenefitId === 100401 || benefit.UIBenefitId === 100402) {
                    // TPD Accelerated, TPD Standalone
                    // shouldn't be able to quote Any Occ and Own Occ together on TPD  (TODO: what about TPD Accelerated Level, TPD Standalone Level?)
                    if (benefit.QuoteOption.OwnOccupation === true) {
                        numberOfOwnOcc++;
                    } else {
                        numberOfAnyOcc++;
                    }
                }
                
                // set TpdAnyOccupation/TpdOwnOccupation
                if (benefit.UIBenefitId === 100301 || benefit.UIBenefitId === 100302 || benefit.UIBenefitId === 100303 || benefit.UIBenefitId === 100304) {
                     // Trauma Accelerated, Trauma Standalone, Trauma Accelerated Level, Trauma Standalone Level
                    benefit.QuoteOption.TpdAnyOccupation = !benefit.QuoteOption.TpdOwnOccupation;
                }
                
                // set AnyOccupation/OwnOccupation
                if (benefit.UIBenefitId === 100401 || benefit.UIBenefitId === 100402 || benefit.UIBenefitId === 100403 || benefit.UIBenefitId === 100404) {
                     // TPD Accelerated, TPD Standalone, TPD Accelerated Level, TPD Standalone Level
                    benefit.QuoteOption.AnyOccupation = !benefit.QuoteOption.OwnOccupation;
                }
                
                // set LifeCoverAmount
                if (benefit.UIBenefitId === 100301 || benefit.UIBenefitId === 100302 || benefit.UIBenefitId === 100401 || benefit.UIBenefitId === 100402 || benefit.UIBenefitId === 100303 || benefit.UIBenefitId === 100304 || benefit.UIBenefitId === 100403 || benefit.UIBenefitId === 100404) {
                    // Trauma Accelerated, Trauma Standalone, TPD Accelerated, TPD Standalone, Trauma Accelerated Level, Trauma Standalone Level, TPD Accelerated Level, TPD Standalone Level
                    benefit.QuoteOption.LifeCoverAmount = client.BenefitList.find(b => b.UIBenefitId === 100201)?.QuoteOption.CoverAmount || client.BenefitList.find(b => b.UIBenefitId === 100202)?.QuoteOption.CoverAmount || 0;
                }
                
                 
            }

           // check numberOfOwnOcc, numberOfAnyOcc
            if (numberOfAnyOcc > 0 && numberOfOwnOcc > 0) {
                this.errorMessages.push(this.sharedFunction.getUiMessageByCode("QuoteStepBenefit-ERROR-TPD"));
            }
            
        });
        
        if (this.errorMessages.length > 0) {
            isReadyForCrunching = false;
        }

        this.saveCurrentBusinessQuote(this.currentSavedBusinessQuote);
        return isReadyForCrunching;
    }


    getAvailableBenefits(): Benefit[] {
        // 1. create new benefits
        let benefits: Benefit[] = this.createBenefits();
        // 2. set UIBenefitID
        //this.setUIBenefitId(benefits);
        // 3. set IsRelatedBenefit
        this.setIsRelatedBenefit(benefits);
        // 4. set IsDisabledBenefit
        this.checkIsDisabledBenefit(benefits);
        // 5. set benefit option
        this.setDefaultBenefitOptionData(benefits);
        // 6. return benefits
        return benefits;
    }

    createBenefits(): Benefit[] {
        let benefits: Benefit[] = [];
        let life = new Benefit(1002, 'Business Life Cover', 'Life');
        life.UIBenefitId = 100201;
        benefits.push(life);
        let traumaAcc = new Benefit(1003, 'Business Trauma Accelerated', 'Trauma Accelerated');
        traumaAcc.UIBenefitId = 100301;
        benefits.push(traumaAcc);
        let tpdAcc = new Benefit(1004, 'Business TPD Accelerated', 'TPD Accelerated');
        tpdAcc.UIBenefitId = 100401;
        benefits.push(tpdAcc);
        let traumaSta = new Benefit(1003, 'Business Trauma Standalone', 'Trauma Standalone');
        traumaSta.UIBenefitId = 100302;
        benefits.push(traumaSta);
        let tpdSta = new Benefit(1004, 'Business TPD Standalone', 'TPD Standalone');
        tpdSta.UIBenefitId = 100402;
        benefits.push(tpdSta);
        let kp = new Benefit(1005, 'Business Key Person Cover', 'Key Person Cover');
        kp.UIBenefitId = 1005;
        benefits.push(kp);

        //if(isDevMode()){
        let brc = new Benefit(1006, 'Business Rural Key Person Cover', 'Rural Cover');
        brc.UIBenefitId = 1006;
        benefits.push(brc);
        //}        

        let wop = new Benefit(9, 'Waiver of Premium', 'Waiver of Premium');
        wop.UIBenefitId = 9;
        benefits.push(wop);
        
        return benefits;
    }

    setUIBenefitId(benefits: Benefit[]): void {
        for (let b of benefits) {
            // Life
            if (b.BenefitId === 1002 && b.QuoteOption?.CalcPeriod?.Value == 1) {
                b.UIBenefitId = 100201;
            } else if (b.BenefitId === 1002 && b.QuoteOption?.CalcPeriod?.Value != 1) {
                b.UIBenefitId = 100202;
            }
            // Trauma
            else if (b.BenefitId === 1003 && b.QuoteOption?.Accelerated == true && b.QuoteOption?.CalcPeriod?.Value == 1) {
                b.UIBenefitId = 100301;
            } else if (b.BenefitId === 1003 && b.QuoteOption?.Accelerated != true && b.QuoteOption?.CalcPeriod?.Value == 1) {
                b.UIBenefitId = 100302;
            } else if (b.BenefitId === 1003 && b.QuoteOption?.Accelerated == true && b.QuoteOption?.CalcPeriod?.Value != 1) {
                b.UIBenefitId = 100303;
            } else if (b.BenefitId === 1003 && b.QuoteOption?.Accelerated != true && b.QuoteOption?.CalcPeriod?.Value != 1) {
                b.UIBenefitId = 100304;
            }
            // TPD
            else if (b.BenefitId === 1004 && b.QuoteOption?.Accelerated == true && b.QuoteOption?.CalcPeriod?.Value == 1) {
                b.UIBenefitId = 100401;
            } else if (b.BenefitId === 1004 && b.QuoteOption?.Accelerated != true && b.QuoteOption?.CalcPeriod?.Value == 1) {
                b.UIBenefitId = 100402;
            } else if (b.BenefitId === 1004 && b.QuoteOption?.Accelerated == true && b.QuoteOption?.CalcPeriod?.Value != 1) {
                b.UIBenefitId = 100403;
            } else if (b.BenefitId === 1004 && b.QuoteOption?.Accelerated != true && b.QuoteOption?.CalcPeriod?.Value != 1) {
                b.UIBenefitId = 100404;
            } else {
                b.UIBenefitId = b.BenefitId;
            }
        }
    }
    
    // quote result do not have benefitShortName, and benefit name is different from frontend benefit name
    resetQuoteResultUIBenefitId(benefits: Benefit[]): void {
        for (let b of benefits) {
            // Life
            if (b.BenefitId === 1002 && !b.QuoteOption.IsLevel) {
                b.UIBenefitId = 100201; //Life
            } else if (b.BenefitId === 1002 && b.QuoteOption.IsLevel) {
                b.UIBenefitId = 100202; //Life Level
            }else if(b.BenefitId === 1002 ){
                b.UIBenefitId = 100201; //Life
            }
            // Trauma
            else if (b.BenefitId === 1003 && b.QuoteOption.Accelerated && !b.QuoteOption.IsLevel) {
                b.UIBenefitId = 100301; //Trauma Accelerated
            } else if (b.BenefitId === 1003 && b.QuoteOption.Standalone && !b.QuoteOption.IsLevel) {
                b.UIBenefitId = 100302; //Trauma Standalone
            } else if (b.BenefitId === 1003 && b.QuoteOption.Accelerated && b.QuoteOption.IsLevel) {
                b.UIBenefitId = 100303; //Trauma Accelerated Level
            } else if (b.BenefitId === 1003 && b.QuoteOption.Standalone && b.QuoteOption.IsLevel) {
                b.UIBenefitId = 100304; //Trauma Standalone Level
            } else if(b.BenefitId === 1003 && b.QuoteOption.Accelerated){
                b.UIBenefitId = 100301; //Trauma Accelerated
            } else if(b.BenefitId === 1003 && b.QuoteOption.Standalone){
                b.UIBenefitId = 100302; //Trauma Accelerated
            }
            // TPD
            else if (b.BenefitId === 1004 && b.QuoteOption.Accelerated && !b.QuoteOption.IsLevel) {
                b.UIBenefitId = 100401; //TPD Accelerated
            } else if (b.BenefitId === 1004 && b.QuoteOption.Standalone && !b.QuoteOption.IsLevel) {
                b.UIBenefitId = 100402; //TPD Standalone
            } else if (b.BenefitId === 1004 && b.QuoteOption.Accelerated && b.QuoteOption.IsLevel) {
                b.UIBenefitId = 100403; //TPD Accelerated Level
            } else if (b.BenefitId === 1004 && b.QuoteOption.Standalone && b.QuoteOption.IsLevel) {
                b.UIBenefitId = 100404; //TPD Standalone Level
            } else if (b.BenefitId === 1004 && b.QuoteOption.Accelerated) {
                b.UIBenefitId = 100401; //TPD Accelerated
            } else if (b.BenefitId === 1004 && b.QuoteOption.Standalone) {
                b.UIBenefitId = 100402; //TPD Standalone
            } 
            
            else{
                b.UIBenefitId = b.BenefitId;
            }
        }
    }
    
    //quote result benefit name is different from frontend benefit name
    getUiBenefitName(benefit: Benefit): string {
        // check UIBenefitID first
        let benefitName = '';
        let availableUIBenefitsMap = new Map(this.getAvailableBenefits().map(i => [i.UIBenefitId, i]));
        let selectedBenefit = availableUIBenefitsMap.get(benefit.UIBenefitId);
        if(selectedBenefit){
            benefitName = selectedBenefit.BenefitName;
        }else{
            let availableBenefitsMap = new Map(this.getAvailableBenefits().map(i => [i.BenefitId, i]));
            benefitName = availableBenefitsMap.get(benefit.BenefitId).BenefitName;
        }

        return benefitName;
    }

    setIsRelatedBenefit(benefits: Benefit[]): void {
        for (let b of benefits) {
            b.IsRelatedBenefit = false;
            // Business Trauma Accelerated(100301), Business TPD Accelerated(100401), Business Trauma Accelerated - Level(100303), Business TPD Accelerated - Level(100403)
            if (b.UIBenefitId === 100301 || b.UIBenefitId === 100401 || b.UIBenefitId === 100303 || b.UIBenefitId === 100403) {
                b.IsRelatedBenefit = true;
            }
        }
    }

    checkIsDisabledBenefit(benefits: Benefit[]): void {
        for (let b of benefits) {
            // Life(100201) controls: Business Trauma Accelerated(100301), Business TPD Accelerated(100401)
            if (b.UIBenefitId === 100301 || b.UIBenefitId === 100401) {
                b.IsDisabled = !benefits.find(item => item.UIBenefitId === 100201).IsSelected;
                if (b.IsDisabled) {
                    b.IsSelected = false;
                }
            }
            //  Life Level(100202) controls: Business Trauma Accelerated - Level(100303), Business TPD Accelerated - Level(100403)
            if (b.UIBenefitId === 100303 || b.UIBenefitId === 100403) {
                b.IsDisabled = !benefits.find(item => item.UIBenefitId === 100202).IsSelected;
                if (b.IsDisabled) {
                    b.IsSelected = false;
                }
            }
        }
    }

    setDefaultBenefitOptionData(benefits: Benefit[]): void {
        for (let b of benefits) {
            
            // same as when creating new personal benefit (same as Benefit Class)
            if (b.UIBenefitId === 100201) {
                // business life
                b.QuoteOption = new QuoteOptionLife();
                b.RecommendMaxCoverAmount = 5000000;
            } else if (b.UIBenefitId === 100302 || b.UIBenefitId === 100303 || b.UIBenefitId === 100304) {
                // Trauma Standalone, Trauma Accelerated Level, Trauma Standalone Level
                b.QuoteOption = new QuoteOptionTrauma();
                b.RecommendMaxCoverAmount = 2000000;
            } else if (b.UIBenefitId === 100301) {
                // Trauma Accelerated
                b.QuoteOption = new QuoteOptionTrauma();
                b.RecommendMaxCoverAmount = 2000000;
                b.ParentId = 1002;
            } else if (b.UIBenefitId === 100402 || b.UIBenefitId === 100403 || b.UIBenefitId === 100404) {
                // TPD Standalone, TPD Accelerated Level, TPD Standalone Level
                b.QuoteOption = new QuoteOptionTPD();
                b.RecommendMaxCoverAmount = 2000000;
            } else if (b.UIBenefitId === 100401) {
                // TPD Accelerated
                b.QuoteOption = new QuoteOptionTPD();
                b.RecommendMaxCoverAmount = 2000000;
                b.ParentId = 1002;
            }else if (b.UIBenefitId === 100202) {
                // business life level
                b.QuoteOption = new QuoteOptionLife();
                b.RecommendMaxCoverAmount = 5000000;
            }
            
            // set default cover amount
            if (!b.QuoteOption.CoverAmount) {
                b.QuoteOption.CoverAmount = 0;
            }
            // set default loading
            if (!b.QuoteOption.Loading) {
                if (b.BenefitId === 1001) {
                    b.QuoteOption.Loading = this.sharedFunction.getLoadingList(true)[0];
                } else {
                    b.QuoteOption.Loading = this.sharedFunction.getLoadingList(false)[0];
                }
            }
            
            if (b.UIBenefitId === 100202 || b.UIBenefitId === 100303 || b.UIBenefitId === 100403 || b.UIBenefitId === 100304 || b.UIBenefitId === 100404) {
                // reset default calcPeriod
                // reset calcPeriod value when creating benefit (Life Level,Trauma Accelerated Level, TPD Accelerated Level, Trauma Standalone Level, TPD Standalone Level)
                if (b.QuoteOption.CalcPeriod.Value === 1) {
                    b.QuoteOption.CalcPeriod = this.sharedFunction.getCalcPeriodList()[0];
                }
                // set IsLevel
                b.QuoteOption.IsLevel = true;
                // set Indexed
                b.QuoteOption.Indexed = false;
            }
            
            // reset is standalone or not
            if (b.UIBenefitId === 100301 || b.UIBenefitId === 100401 || b.UIBenefitId === 100303 || b.UIBenefitId === 100403 ) {
                // Trauma Accelerated, TPD Accelerated, Trauma Accelerated Level, TPD Accelerated Level
                b.QuoteOption.Standalone = false;
                b.QuoteOption.Accelerated = true;
            }
            
            if (!b.QuoteOption.BusinessEventsIncreaseOption && b.BenefitId !== 9) {
                b.QuoteOption.BusinessEventsIncreaseOption = false;
            }
            
            // Key Person Cover
            if (b.BenefitId === 1005) {
                let quoteOption = b.QuoteOption as QuoteOptionKeyPerson;
                quoteOption.KeyPersonPartialDisabilityOption = quoteOption.KeyPersonPartialDisabilityOption ?? true;
            }
          
            // Rural Cover
            if (b.BenefitId === 1006) {
                let quoteOption = b.QuoteOption as QuoteOptionRural;
                quoteOption.KeyPersonPartialDisabilityOption = quoteOption.KeyPersonPartialDisabilityOption ?? true;
                quoteOption.Booster = false;
            }
        }
    }

    setQuoteName(): string {
        if (this.currentSavedBusinessQuote.QuoteName.trim()) {
            return this.currentSavedBusinessQuote.QuoteName;
        } else {
            //new quote default quote name: BusinessName - dd/mm/yyyy
            return `${ this.currentSavedBusinessRecord.Name } - ${ dayjs(new Date()).format('DD/MM/YYYY') }`;
        }
    }

    getPeopleEntity(client: PeopleEntity | null, person: Person): PeopleEntity {
        if (client) {
            // update client info
            this.updateClientInfoFromPerson(client, person);
            return client;
        } else {
            // create new client
            let newClient: PeopleEntity = new PeopleEntity();
            newClient.IPOccupationId = new SelectItemStruct('1', '', 1, false, -1, '', '', '', '', '', '');
            newClient.TPDOccupationId = new SelectItemStruct('1', '', 1, false, -1, '', '', '', '', '', '');
            newClient.ExtraData = { PersonId: person.Id };
            this.updateClientInfoFromPerson(newClient, person);
            return newClient;
        }

    }

    updateClientInfoFromPerson(client: PeopleEntity, person: Person): void {
        client.FirstName = person.FirstName;
        client.LastName = person.LastName;
        client.Age = person.Age;
        client.Gender = person.Gender;
        client.Smoker = person.IsSmoker;

        client.OccupationId.Value = person.OccClass;
        client.OccupationId.Name = this.sharedFunction.getDisplayName('OccupationClassList', client.OccupationId.Value);

        // IPOccupationId and TPDOccupationId should same as OccupationId
        client.IPOccupationId.Value = client.TPDOccupationId.Value = client.OccupationId.Value;
        client.IPOccupationId.Name = client.TPDOccupationId.Name = client.OccupationId.Value.toString();

        
        client.EmployedStatus.Value = person.EmployeeType;
        client.EmployedStatus.Name = this.sharedFunction.getDisplayName('EmployedStatusList', client.EmployedStatus.Value);
    }

    setClientsId(clients: PeopleEntity[]): void {
        for (let i = 0; i < clients.length; i++) {
            clients[i].ClientId = i;
        }
    }

    setQuoteClients(businessRecordPersons: Person[], savedClients: PeopleEntity[]): PeopleEntity[] {
        let quoteClients: PeopleEntity[] = [];

        // update client info from person or create new client from person
        for (let person of businessRecordPersons) {
            if (person.IsAddressedSoaReportTo) {
                if (savedClients.length > 0) {
                    let peopleEntityMap = new Map(savedClients.map(i => [i.ExtraData.PersonId, i]));
                    let client = peopleEntityMap.get(person.Id);
                    if (client) {
                        // business person is in saved quote people, update people info
                        quoteClients.push(this.getPeopleEntity(client, person));
                    } else {
                        // business person is not in saved quote people, create new people
                        quoteClients.push(this.getPeopleEntity(null, person));
                    }
                } else {
                    quoteClients.push(this.getPeopleEntity(null, person));
                }
            }

        }
        
        this.setClientsId(quoteClients);
        return quoteClients;
    }

    setClientBenefit(quoteClients: PeopleEntity[]): void {
        for (let p of quoteClients) {
            if (p.BenefitList && p.BenefitList.length > 0) {
                // has saved benefits
                for (let b of p.BenefitList) {
                    b.IsSelected = true;
                    b.IsDisabled = false;                
                    this.setUIBenefitId(p.BenefitList); 
                }
            } else {
                // no benefits
                p.BenefitList = [];
            }
        }
    }

    setProviderSettings(currentSavedBusinessQuote: BusinessCurrentQuote, callback: () => void | null): void {
        if (currentSavedBusinessQuote.QuoteResult && currentSavedBusinessQuote.QuoteResult.QuoteResultList && currentSavedBusinessQuote.QuoteResult.QuoteResultList.length > 0) {
            // if have quote results and have at least one client from existing quote, set frequency to same as existing quote, and re-set provider message
            currentSavedBusinessQuote.FrequencyLoading = this.getFrequencyFromQuoteResult(currentSavedBusinessQuote.QuoteResult.QuoteResultList);
            this.resetExistingProvider(currentSavedBusinessQuote.QuoteResult.QuoteResultList);
        } else {
            // new quote, or existing quote but no quote result or no client from existing quote, load user provider setting
            this.showDinoLoading();
            this.getDefaultProviderSettings(callback);
        }
    }

    getDefaultProviderSettings(callback: () => void): void {
        let getDefaultFrequency$ = this.getDefaultFrequencyFromSetting();
        let getDefaultProvider$ = this.getDefaultProviderSetting();
        forkJoin([getDefaultFrequency$, getDefaultProvider$])
            .subscribe(([frequency, providerSetting]) => {
                this.currentSavedBusinessQuote.FrequencyLoading = frequency;
                this.providerSetting = providerSetting.filter(p => p.IsSelected);
                callback();
            });
    }

    getDefaultFrequencyFromSetting(): Observable<number> {
        let frequency: number = 12; // monthly

        return new Observable<number>((observer) => {
            this.getUserQmSetting((response: UserQmSettingModel) => {
                if (response && response.QmSetting && response.QmSetting.DEF_FREQ) {
                    frequency = Number(response.QmSetting.DEF_FREQ);
                }
                observer.next(frequency);
                observer.complete();
            });
        });
    }

    getDefaultProviderSetting(): Observable<Provider[]> {
        let defaultProviderSetting: Provider[] = [];

        return new Observable<Provider[]>((observer) => {
            this.getBusinessProviderSettings((response: any) => {
                if (response && response.Result) {
                    defaultProviderSetting = response.Result;
                }
                observer.next(defaultProviderSetting);
                observer.complete();
            });
        });
    }

    getDefaultResearchReportTypeFromSetting(): Observable<number> {
        let researchReportType: number = 1; 
        return new Observable<number>((observer) => {
            this.getUserQmSetting((response: UserQmSettingModel) => {
                if (response && response.QmSetting && response.QmSetting.DEF_RESEARCH_REPORT_STYLE) {
                    researchReportType = Number(response.QmSetting.DEF_RESEARCH_REPORT_STYLE);
                }
                observer.next(researchReportType);
                observer.complete();
            });
        });
    }

    resetExistingProvider(quoteResultList: QuoteResultItem[]): void {
        this.providerSetting = [];
        if (quoteResultList && quoteResultList.length > 0) {
            for (let result of quoteResultList) {
                // load error message
                let errorMessageCounter: number = 0;
                for (let p of result.Result) {
                    if (p.BenefitList != null && p.BenefitList.length > 0) {
                        for (let b of p.BenefitList) {
                            if (b.ErrorMessage != null && b.ErrorMessage.length > 0) {
                                for (let e of b.ErrorMessage) {
                                    errorMessageCounter++;
                                }
                            }
                        }
                    }
                }

                if (result.Provider.ErrorMessage) {
                    errorMessageCounter = errorMessageCounter + result.Provider.ErrorMessage.length;
                }

                if (result.LinkedProvider && result.LinkedProvider.ErrorMessage) {
                    errorMessageCounter = errorMessageCounter + result.LinkedProvider.ErrorMessage.length;
                }


                // for new APIv3
                if (result.ErrorMessages) {
                    errorMessageCounter = errorMessageCounter + result.ErrorMessages.length;
                }

                // set display total of premium with commion
                result.Provider.DisplayTotalOfPremium = result.Provider.TotalPremium;

                if (result.Provider.Commission && result.Provider.Commission > 0) {
                    result.Provider.DisplayTotalOfPremium -= result.Provider.Commission;
                }


                // if has error message then set total premium to 9999999999 make sure it's in bottom.
                if (errorMessageCounter > 0) {
                    result.Provider.TotalPremium = 9999999999;
                    result.Provider.DisplayTotalOfPremium = result.Provider.TotalPremium;
                }

                // set linked provider logo
                if (result.LinkedProvider) {
                    result.Provider.LinkedProviderLogoUrl = result.LinkedProvider.ProviderLogoUrl;
                } else {
                    result.Provider.LinkedProviderLogoUrl = null;
                }

                this.providerSetting.push(result.Provider);


            }
            this.providerSetting.sort(this.sortQuoteResult);
        }
    }

    getFrequencyFromQuoteResult(quoteResultList: QuoteResultItem[]): number {
        return quoteResultList[0].Result.filter(p => p.BenefitList.length > 0)[0].BenefitList[0].QuoteOption.Frequency;
    }

    sortQuoteResult(provider1: Provider, provider2: Provider) {
        let comparison = 0;

        if (provider1.TotalPremium > provider2.TotalPremium) {
            comparison = 1;
        } else if (provider2.TotalPremium > provider1.TotalPremium) {
            comparison = -1;
        }

        return comparison;
    }
    
    setSpecialQuoteSettings(currentSavedBusinessQuote: BusinessCurrentQuote): void {
        // if there is special provider setting, then set it to special quote setting
        currentSavedBusinessQuote.SpecialQuoteSettings = [];
        for (let quoteResult of currentSavedBusinessQuote.QuoteResult.QuoteResultList) {
            if (quoteResult.Provider.SpecialProviderSetting) {
                let providerSpecialQuoteSetting = new SpecialQuoteSetting();
                providerSpecialQuoteSetting.ProviderId = quoteResult.Provider.ProviderId;
                providerSpecialQuoteSetting.SpecialProviderSetting = quoteResult.Provider.SpecialProviderSetting;
                currentSavedBusinessQuote.SpecialQuoteSettings.push(providerSpecialQuoteSetting);
            }
        }
    }
    
    openExistingQuote(businessRecordId:number, quote: BusinessQuoteNcsRecord) : Observable<BusinessCurrentQuote> {
        // TODO: Create a new model for response

        return new Observable<BusinessCurrentQuote>((observer) => {
            this.quoteForBusinessQuoteDetail(businessRecordId, quote.QuoteId, (response: any) => {
                if (response) {
                    let newBusinessCurrentQuote: BusinessCurrentQuote = new BusinessCurrentQuote();

                    newBusinessCurrentQuote.AppId = response.AppId;
                    newBusinessCurrentQuote.QuoteName = response.QuoteName;
                    newBusinessCurrentQuote.PeopleEntity = response.QuoteModel.QuoteSetting.QuoteEntity.PeopleEntity;
                    newBusinessCurrentQuote.SpecialQuoteSettings = response.QuoteModel.QuoteSetting.QuoteEntity.SpecialQuoteSettings;
                    newBusinessCurrentQuote.QuoteReference = response.QuoteModel.QuoteSetting.QuoteEntity.QuoteReference;
                    newBusinessCurrentQuote.QuoteDate = response.QuoteModel.QuoteResult.QuoteDate;
                    newBusinessCurrentQuote.NoExtraData = response.QuoteModel.QuoteSetting.QuoteEntity.NoExtraData;
                    newBusinessCurrentQuote.ExtraData = response.QuoteModel.QuoteSetting.QuoteEntity.ExtraData;
                    newBusinessCurrentQuote.QuoteResult = response.QuoteModel.QuoteResult;

                    observer.next(newBusinessCurrentQuote);
                    observer.complete();
                }
            });
        });
    }
    
    removeBenefit(client: PeopleEntity, benefit: Benefit){
        benefit.IsSelected = false;
        // Life(100201) is removed: do remove Business Trauma Accelerated(100301), Business TPD Accelerated(100401);
        //Life Level(100202) is removed:  do remove Business Trauma Accelerate - Level(100303), Business TPD Accelerate - Level(100403)
        this.checkIsDisabledBenefit(client.BenefitList);
        // fill IsSelected benefits
        client.BenefitList = client.BenefitList.filter(b => b.IsSelected);
    }
    
    addBenefits(client: PeopleEntity, availableBenefits: Benefit[]): void {
       client.BenefitList = [];
        for (let b of availableBenefits) {
            if (b.IsSelected) {
                client.BenefitList.push(b);
                // add wop when is key person or rural cover
                if (b.BenefitId == 1006 || b.BenefitId == 1005) {
                    // check wop has been selected or not
                    for (let avb of availableBenefits) {
                        if (avb.BenefitId === 9 && avb.IsSelected !== true) {
                            avb.IsSelected = true;
                            break;
                        }
                    }
                }
            }
        }
    }
    
    resetAvailableBenefits(client: PeopleEntity, availableBenefits: Benefit[]): void {
        availableBenefits.forEach((b) => {
            b.IsSelected = false;
        });

        if (client.BenefitList.length > 0) {
            let availableBenefitsMap = new Map(availableBenefits.map(i => [i.UIBenefitId, i]));

            for (let b of client.BenefitList) {
                let benefit = availableBenefitsMap.get(b.UIBenefitId);
                if (benefit) {
                    benefit.QuoteOption = b.QuoteOption;
                    benefit.IsSelected = true;
                }
            }
        }

        this.checkIsDisabledBenefit(availableBenefits);
    }
    
    createQuoteBenefitsFromNeedAnalysis(currentSavedBusinessQuote: BusinessCurrentQuote, currentSavedBusinessRecord: SavedBusinessRecord, availableBenefits: Benefit[]): void {
        
        let businessPersonMap = new Map(currentSavedBusinessRecord.People.map(person => [person.Id, person]));
        let availableBenefitsMap = new Map(availableBenefits.map(benefit => [benefit.UIBenefitId, benefit]));
 

        let totalDebtsAmount = 0;
        if(this.currentSavedBusinessRecord.BusinessDebts && this.currentSavedBusinessRecord.BusinessDebts.length > 0){
            for(let debt of this.currentSavedBusinessRecord.BusinessDebts){
                totalDebtsAmount = totalDebtsAmount + debt.Amount;
            }
        }


        for (let people of currentSavedBusinessQuote.PeopleEntity) {
            
            let personNeedAnalysisData = businessPersonMap.get(people.ExtraData.PersonId);
            
            //  life cover
            if (currentSavedBusinessRecord.BusinessNeedAnalysisData.NeedAnalysisSettings?.IsProvideLife && currentSavedBusinessRecord.BusinessNeedAnalysisData.LifePriority !== 4) {
                //if (personNeedAnalysisData.LifeGrandTotalLifeCoverRequired > 0) {
                    let lifeBenefit: Benefit = JSON.parse(JSON.stringify(availableBenefitsMap.get(100201)));
                    lifeBenefit.IsSelected = true;
                    lifeBenefit.QuoteOption.CoverAmount = (
                        personNeedAnalysisData.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue
                        + personNeedAnalysisData.ShareholderCurrentAccountBalance
                        + personNeedAnalysisData.ShareOfRevenueDependentOnTheirWork / 100.0 * currentSavedBusinessRecord.CurrentTotalRevenue
                        + personNeedAnalysisData.NeedAnalysisData.LifeWhatPercentageOfYourDebtsWouldYouPayOff / 100 * totalDebtsAmount
                        + personNeedAnalysisData.NeedAnalysisData.LifeHowMuchWouldYouLikeToAddToThisSum
                        - personNeedAnalysisData.NeedAnalysisData.LifeLessExistingCoverAmount

                    );
                    
                    people.BenefitList.push(lifeBenefit);
                //}
            }
            
            //  trauma
            if (currentSavedBusinessRecord.BusinessNeedAnalysisData.NeedAnalysisSettings?.IsProvideTrauma && currentSavedBusinessRecord.BusinessNeedAnalysisData.TraumaPriority !== 4) {
                //if (personNeedAnalysisData.TotalTraumaCover > 0) {
                    
                    let traumaBenefit: Benefit = personNeedAnalysisData.NeedAnalysisData.TraumaCoverType === 1 ? JSON.parse(JSON.stringify(availableBenefitsMap.get(100302))):JSON.parse(JSON.stringify(availableBenefitsMap.get(100301)));
        
                    traumaBenefit.IsSelected = true;
                    traumaBenefit.QuoteOption.CoverAmount = (
                        personNeedAnalysisData.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue
                        + personNeedAnalysisData.ShareholderCurrentAccountBalance
                        + personNeedAnalysisData.ShareOfRevenueDependentOnTheirWork / 100.0 * currentSavedBusinessRecord.CurrentTotalRevenue
                        + personNeedAnalysisData.NeedAnalysisData.TraumaWhatPercentageOfYourDebtsWouldYouPayOff / 100 * totalDebtsAmount
                        + personNeedAnalysisData.NeedAnalysisData.TraumaHowMuchWouldYouLikeToAddToThisSum
                        - personNeedAnalysisData.NeedAnalysisData.TraumaLessExistingCoverAmount
                    );
                    
                    people.BenefitList.push(traumaBenefit);
                //}
            }
            
            //  tpd
            if (currentSavedBusinessRecord.BusinessNeedAnalysisData.NeedAnalysisSettings?.IsProvideTpd && currentSavedBusinessRecord.BusinessNeedAnalysisData.TpdPriority !== 4) {
                //if (personNeedAnalysisData.TotalTpdCover > 0) {
                    
                    let tpdBenefit: Benefit = personNeedAnalysisData.NeedAnalysisData.TpdCoverType === 1 ? JSON.parse(JSON.stringify(availableBenefitsMap.get(100402))):JSON.parse(JSON.stringify(availableBenefitsMap.get(100401)));
        
                    tpdBenefit.IsSelected = true;
                    tpdBenefit.QuoteOption.CoverAmount = (
                        personNeedAnalysisData.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue
                        + personNeedAnalysisData.ShareholderCurrentAccountBalance
                        + personNeedAnalysisData.ShareOfRevenueDependentOnTheirWork / 100.0 * currentSavedBusinessRecord.CurrentTotalRevenue
                        + personNeedAnalysisData.NeedAnalysisData.TpdWhatPercentageOfYourDebtsWouldYouPayOff / 100 * totalDebtsAmount
                        + personNeedAnalysisData.NeedAnalysisData.TpdHowMuchWouldYouLikeToAddToThisSum                        
                    );
                    
                    people.BenefitList.push(tpdBenefit);
                //}
            }
            
            // if added Business Trauma Accelerated(100301) or  Business TPD Accelerated(100401), then add Life(100201) (as Life(100201) controls Trauma Accelerated(100301) and TPD Accelerated(100401))
            if (people.BenefitList.filter(b => b.UIBenefitId === 100301 || b.UIBenefitId === 100401).length > 0 && people.BenefitList.filter(b => b.UIBenefitId === 100201).length === 0) {
                let lifeBenefit: Benefit = JSON.parse(JSON.stringify(availableBenefitsMap.get(100201)));
                lifeBenefit.IsSelected = true;
                lifeBenefit.QuoteOption.CoverAmount = (
                    personNeedAnalysisData.Shareholding / 100.0 * currentSavedBusinessRecord.CurrentBusinessValue
                        + personNeedAnalysisData.ShareholderCurrentAccountBalance
                        + personNeedAnalysisData.ShareOfRevenueDependentOnTheirWork / 100.0 * currentSavedBusinessRecord.CurrentTotalRevenue
                        + personNeedAnalysisData.NeedAnalysisData.LifeWhatPercentageOfYourDebtsWouldYouPayOff / 100 * totalDebtsAmount
                        + personNeedAnalysisData.NeedAnalysisData.LifeHowMuchWouldYouLikeToAddToThisSum
                        - personNeedAnalysisData.NeedAnalysisData.LifeLessExistingCoverAmount
                );

                people.BenefitList.unshift(lifeBenefit);
            }


            // Key person cover
            if (currentSavedBusinessRecord.BusinessNeedAnalysisData.KeyPersonPriority !== 4) {
                
                //if (personNeedAnalysisData.LifeGrandTotalLifeCoverRequired > 0) {
                    let keyPersonBenefit: Benefit = JSON.parse(JSON.stringify(availableBenefitsMap.get(1005)));
                    keyPersonBenefit.IsSelected = true;
                    keyPersonBenefit.QuoteOption.CoverAmount = (
                        personNeedAnalysisData.PackageValueOfKeyPerson
                                                    - personNeedAnalysisData.NeedAnalysisData.KeyPersonLessExistingCoverAmount
                    );
                    
                    people.BenefitList.push(keyPersonBenefit);

                    // also add WOP
                    let wopBenefit: Benefit = JSON.parse(JSON.stringify(availableBenefitsMap.get(9)));
                    wopBenefit.IsSelected = true;
                    people.BenefitList.push(wopBenefit);
                //}
            }
            
        }
    }
    
}