import { Component, Input, OnInit, isDevMode } from '@angular/core';
import { PlatformSettingComponent } from '../platform-setting.component';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';
import { QuoteService } from 'src/app/service/quote.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
  selector: 'platform-setting-switch',
  templateUrl: './platform-setting-switch.component.html',
  styleUrls: ['./platform-setting-switch.component.scss']
})
export class PlatformSettingSwitchComponent extends PlatformSettingComponent implements OnInit {

  @Input("isWhiteTextLogo") isWhiteTextLogo: boolean = true;
  isDevMode: boolean = false;
  hasAccessToKiwimonster: boolean = false;
  constructor (

    public loginService: LoginService,
    public quoteService: QuoteService,
    public snackBar: MatSnackBar,
    public router: Router,
    public sharedFunction: SharedFunctionService
  ) {
    super(loginService, quoteService, snackBar, router, sharedFunction);
  }

  ngOnInit(): void {
    this.isDevMode = isDevMode();
    this.hasAccessToKiwimonster = this.loginService.hasAccessToKiwimonster();
  }

}
