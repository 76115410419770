
import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CurrencyMaskModule } from 'ng2-currency-mask';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HttpClientModule } from '@angular/common/http';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBottomSheetModule } from '@angular/material/bottom-sheet';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatNativeDateModule, MatRippleModule, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule, MatStepperIntl } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatBadgeModule } from '@angular/material/badge';
import { A11yModule } from '@angular/cdk/a11y';
import { BidiModule } from '@angular/cdk/bidi';
import { OverlayModule } from '@angular/cdk/overlay';
import { PlatformModule } from '@angular/cdk/platform';
import { ObserversModule } from '@angular/cdk/observers';
import { PortalModule } from '@angular/cdk/portal';
import { DragDropModule } from '@angular/cdk/drag-drop';


// app start
import { AppComponent } from './app.component';
import { PublicPageComponent } from './public.page.component';
import { PrivatePageComponent } from './private.page.component';
import { NavMenuComponent } from './components/navmenu/navmenu.component';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { DashboardArchivesComponent } from './components/dashboard/dashboard-archives.component';



import { HomeComponent } from './components/home/home.component';
import { RecentQuoteComponent } from './components/home/recent-quote.component';
import { BlogPageComponent } from './components/shared/blog-page.component';


// about
import { AboutComponent } from './containers/about/about.component';
import { AboutOverviewComponent } from './components/about/about-overview.component';
import { AboutUsingQuotemonsterComponent } from './components/about/about-using-quotemonster.component';
import { AboutFaqsComponent } from './components/about/about-faqs.component';

// login register forgotten password
import { LoginComponent } from './components/login/login.component';
import { SignupComponent } from './components/login/signup.component';
import { VerifyDeviceComponent } from './components/login/verify-device.component';
import { ContactComponent } from './components/contact/contact.component';
import { NoticeComponent } from './components/notice/notice.component';
import { UserNoticeDialog } from './components/notice/notice.component';
import { ForgottenPasswordDialogComponent } from './components/login/forgotten-password-dialog.component';
import { ResetPasswordComponent } from './components/login/reset-password.component';
import { UpdatePaPasswordComponent } from './components/login/update-pa-password.component';


// quote component
import { QuoteStepMenuComponent } from './containers/quote/quote-step-menu.component';
import { QuoteClientComponent } from './components/quote/quote-client.component';
import { QuoteCheckmonsterComponent } from './components/quote/quote-checkmonster.component';
import { BenefitOrAnalysisSelectorDialogComponent } from './components/quote/benefit-analysis-selector-dialog.component';


import { QuotePersonalBenefitComponent } from './components/quote/quote-p-benefit.component';
import { QuotePersonalBenefitSelectorComponent } from './components/quote/quote-p-benefit-selector.component';
import { QuotePersonalBenefitListComponent } from './components/quote/quote-p-benefit-list.component';
import { QuotePersonalBenefitSettingComponent } from './components/quote/quote-p-benefit-setting.component';
import { QuotePersonalBenefitResultComponent } from './components/quote/quote-p-benefit-result.component';


import { QuoteCompareComponent } from './components/quote/quote-compare.component';
import { QuoteCompareChartsComponent } from './components/quote/quote-compare-charts.component';
import { QuoteCompareDocumentComponent } from './components/quote/quote-compare-document.component';
import { QuoteCompareResearchComponent } from './components/quote/quote-compare-research.component';
import { QuoteCompareCheckmonsterComponent } from './components/quote/quote-compare-checkmonster.component';
import { QuoteCompareHeadToHeadComponent } from './components/quote/quote-compare-h2h.component';
import { QuoteCompareUnderwritingComponent } from './components/quote/quote-compare-underwriting.component';
import { QuoteReportComponent } from './components/quote/quote-report.component';
import { QuoteResultDialogComponent } from './components/quote/quote-result-dialog.component';
// tslint:disable-next-line:max-line-length
import { AssumptionsGuideToProjectingPremiumDialogComponent } from './components/quote/assumptions-guide-to-projecting-premium-dialog.component';
import { QuoteReportDialogComponent } from './components/quote/quote-report-dialog.component';
import { SaveAndCloseComponent } from './components/quote/save-close-quote.component';
import { QuoteStepCompare } from './service/quote/quote.step.compare';
import { QuoteStepCompareChart } from './service/quote/quote.step.compare.chart';
import { QuoteStepCompareDocument } from './service/quote/quote.step.compare.document';
import { QuoteStepCompareResearch } from './service/quote/quote.step.compare.research';
import { QuoteStepCompareCheckmonster } from './service/quote/quote.step.compare.checkmonster';
import { QuoteStepCompareH2H } from './service/quote/quote.step.compare.h2h';
import { QuoteStepCompareUnderwriting } from './service/quote/quote.step.compare.underwriting';
import { QuoteStepReport } from './service/quote/quote.step.compare.report';
import { AppReportsDialogComponent } from './components/dashboard/app-reports-dialog.component';

// need analysis component
import { NcStepMenuComponent } from './containers/need-analysis/nc-step-menu.component';
import { NeedAnalysisNatureComponent } from './components/need-analysis/need-analysis-nature.component';
import { NeedAnalysisScopeComponent } from './components/need-analysis/need-analysis-scope.component';
import { NeedAnalysisRecommendationComponent } from './components/need-analysis/need-analysis-recommendation.component';
import { NeedAnalysisObjectivesComponent } from './components/need-analysis/need-analysis-objectives.component';
import { NeedAnalysisNeedCalComponent } from './components/need-analysis/need-analysis-need-cal.component';
import { NeedAnalysisFinancialComponent } from './components/need-analysis/need-analysis-financial.component';
import { NeedAnalysisExistingComponent } from './components/need-analysis/need-analysis-existing.component';
import { NeedAnalysisService } from './service/need.analysis.service';
import { SoaSectionDetailEditorDialog } from './components/setting/soa-section-detail-editor-dialog';

// setting component
import { SettingMenuComponent } from './containers/setting/setting-menu.component';
import { QuoteSettingComponent } from './components/setting/quote-setting.component';
import { CommissionSettingComponent } from './components/setting/commission-setting.component';
import { UserProviderSettingDialog } from './components/setting/quote-setting-dialog';
import { UserSettingComponent } from './components/setting/user-setting.component';
import { OccupationSettingComponent } from './components/setting/occupation-setting.component';
import { NeedSettingComponent } from './components/setting/need-setting.component';
import { BuyNowSettingComponent } from './components/setting/buy-now-setting.component';
import { CrmSettingComponent } from './components/setting/crm-setting.component';
import { OccupationDescriptionDialogSheet } from './components/setting/occupation-description-dialog-sheet.component';
import { SoaSettingComponent } from './components/setting/soa-setting.component';
import { OccupationSettingDialogComponent } from './components/setting/occupation-setting-dialog.component';
import { SecuritySettingComponent } from './components/setting/security-setting.component';
import { MainSettingComponent } from './components/setting/main-setting.component';
import { UserProfileComponent } from './components/setting/user-profile.component';
import { ResearchSettingComponent } from './components/setting/research-setting.component';

// research
import { ResearchMenuComponent } from './containers/research/research.menu.component';
import { ResearchHomeComponent } from './components/research/home/research-home.component';
import { ResearchHomeOurApproachComponent } from './components/research/home/research-home.ourApproach.component';
import { ResearchHomeFeaturesComponent } from './components/research/home/research-home.features.component';
import { ResearchHomeMethodologyComponent } from './components/research/home/research-home.methodology.component';
import { ResearchHomeFaqsComponent } from './components/research/home/research-home.faqs.component';
import { ResearchHomeAdvertisingComponent } from './components/research/home/research-home.advertising.component';
import { ResearchHomeInfographicsComponent } from './components/research/home/research-home.infographics.component';

import { ResearchToolsIndependentResearchComponent } from './components/research/tools/research-tools.independentResearch.component';
import { ResearchToolsFuneralPlansComponent } from './components/research/tools/research-tools.funeralPlans.component';
import { ResearchToolsBusinessOverheadsCoverComponent } from './components/research/tools/research-tools.businessOverheadsCover.component';
import { ResearchToolsDisabilityClaimsModelComponent } from './components/research/tools/research-tools.disabilityClaimsModel.component';
import { ResearchToolsKeyPersonRatingComponent } from './components/research/tools/research-tools.keyPersonRating.component';
import { ResearchToolsInsurerSelectionScreenComponent } from './components/research/tools/research-tools.insurerSelectionScreen.component';
import { ResearchPolicyWordingComponent } from './components/research/policy/research-policy-wording.component';
import { ResearchPolicyDocumentComponent } from './components/research/policy/research-policy-document.component';
import { ResearchPolicyWordingDialog } from './components/research/policy/research-policy-wording.dialog';
import { ResearchPolicyWordingV2Dialog } from './components/research/policy/research-policy-wording-v2.dialog';
import { ResearchToolsLegacyResearchComponent } from './components/research/tools/research-tools.legacyResearch.component';

// subscription
import { ResearchSubscriptionComponent } from './components/research/subscription/research-subscription.component';
import { ResearchSubscriptionPaymentsComponent } from './components/research/subscription/research-subscription-payments.component';
import { ResearchSubscriptionBillingAddressComponent } from './components/research/subscription/research-subscription-billing-address';
import { ResearchSubscriptionInvoicesComponent } from './components/research/subscription/research-subscription-invoices.component';
import { ResearchSubscriptionTermsComponent } from './components/research/subscription/research-subscription-terms.component';
import { ResearchSubscriptionExistingLicensesComponent } from './components/research/subscription/research-subscription-existing-licenses.component';
import { ResearchSubscriptionCompletePaymentComponent } from './components/research/subscription/research-subscription-completePayment.component';
import { ResearchLicenseOrderComponent } from './components/research/subscription/research-license-order.component';
import { ResearchLicenseOrderPaymentSelectComponent } from './components/research/subscription/research-license-orderPaymentSelect.component';
import { ResearchLicenseOrderPayOnlineDialogComponent } from './components/research/subscription/research-license-order-payonline-dialog.component';
import { ResearchLicenseOrderDetailComponent } from './components/research/subscription/research-license-order-detail.component';
// qm data service
import { LoginService } from './service/login.service';
import { FeedbackService } from './service/feedback.service';
import { ApiAddressService } from './service/api.address.service';
import { BlogService } from './service/blog.service';
import { DataLoaderService } from './service/data.loader.service';
import { DataLoader } from './service/data.loader';
import { QuoteService } from './service/quote.service';
import { QuoteStepClient } from './service/quote/quote.step.client';
import { QuoteStepBenefit } from './service/quote/quote.step.benefit';
import { UserService } from './service/user.service';
import { UserSecurityService } from './service/user.security.service';
import { SysConfigService } from './service/sys.config';



// qm ads service
import { QmadsDirective } from './service/qmads.directive';
import { QmadsService } from './service/qmads.service';
import { QmadsPresentComponent } from './components/qmads/qmads-present.component';
import { AdHtmlComponent } from './components/qmads/ad-html.component';
import { AdImageComponent } from './components/qmads/ad-image.component';



// tools
import { SafeUrlPipe } from './service/safe.url.pipe';
import { SafeHtmlPipe } from './service/safe.html.pipe';
import { AutofocusDirective } from './service/autofocus.directive';
import { GeneralMessageDialogComponent } from './components/shared/general-message-dialog.component';
import { QuestionDialogComponent } from './components/shared/question-dialog.component';

import { CanDeactivateGuard } from './components/shared/can-deactivate-guard.service';
import { ConfirmMessageDialogService } from './components/shared/confirm-message-dialog.service';
import { ConfirmMessageDialogComponent } from './components/shared/confirm-message-dialog.component';
import { SharedFunctionService } from './service/shared.function.service';
import { ResizeService } from './service/screen.resize.service';

// routing
import { AppRoutingModule } from './app-routing.module';
import { Router, UrlSerializer } from '@angular/router';
import { ApiService } from './service/api.service';
import { LowerCaseUrlSerializer } from './tools/lower.case.url.serializer';
import { PricingComponent } from './components/shared/pricing/pricing.component';

import { EditorModule, TINYMCE_SCRIPT_SRC } from '@tinymce/tinymce-angular';
import { SideMenuComponent } from './containers/side-menu/side-menu.component';
import { UserMenuService } from './service/user.menu.service';
import { AdPublicShowComponent } from './components/qmads/ad-public-show.component';
import { GuideStartButtonComponent } from './components/shared/guide-start-button/guide-start-button.component';
import { ACEListComponent } from './components/research/tools/adviser-claims-experience/ace-list/ace-list.component';
import { ACEDetailsComponent } from './components/research/tools/adviser-claims-experience/ace-details/ace-details.component';
import { ACECommentsComponent } from './components/research/tools/adviser-claims-experience/ace-comments/ace-comments.component';
import { CpdTrainingRequestComponent } from './components/research/tools/cpd-training-request-component/cpd-training-request-component.component';
import { AutoSelectDirective } from './tools/auto.select.directive';
import { StandaloneHeadToHeadComponent } from './components/research/tools/standalone-head-to-head/standalone-head-to-head.component';
import { MfaMethodSelectComponent } from './components/login/mfa-method-select/mfa-method-select.component';
import { RealignInkBarDirective } from './tools/realign-ink-bar.directive';
import { QuoteWizardClientOverviewComponent } from './components/quote/quote-wizard/quote-wizard-client-overview.component';
import { QuoteWizardIpMpComponent } from './components/quote/quote-wizard/quote-wizard-ip-mp.component';
import { SelectSearchComponent } from './components/shared/select-search/select-search/select-search.component';
import { QuoteWizardSuggestionComponent } from './components/quote/quote-wizard/quote-wizard-suggestion/quote-wizard-suggestion.component';
import { EditQuoteNameDialogComponent } from './components/dashboard/edit-quote-name-dialog/edit-quote-name-dialog.component';
import { ScopeNoteDialogComponent } from './components/need-analysis/scope-note-dialog/scope-note-dialog.component';
import { QuoteReportPremiumBarChartDialogComponent } from './components/quote/quote-report-premium-bar-chart-dialog/quote-report-premium-bar-chart-dialog.component';
import { SpeechRecognitionComponent } from './components/shared/speech-recognition/speech-recognition.component';
import { UploadPolicyComponent } from './components/quote/policy-based-quote/upload-policy.component';
import { PolicySummaryComponent } from './components/quote/policy-based-quote/policy-summary.component';
import { FactFindDashboardComponent } from './components/fact-find/fact-find-dashboard/fact-find-dashboard.component';
import { FactFindCreationDialogComponent } from './components/fact-find/fact-find-creation-dialog/fact-find-creation-dialog.component';
import { FactFindResetPasswordDialogComponent } from './components/fact-find/fact-find-reset-password-dialog/fact-find-reset-password-dialog.component';
import { FactFindCopyDialogComponent } from './components/fact-find/fact-find-copy-dialog/fact-find-copy-dialog.component';
import { FactFindPublicComponent } from './components/fact-find/fact-find-public/fact-find-public.component';

import { FactFindDetailsComponent } from './components/fact-find/fact-find-details/fact-find-details.component';
import { FactFindObjectivesComponent } from './components/fact-find/fact-find-details/fact-find-objectives/fact-find-objectives.component';
import { FactFindScopeComponent } from './components/fact-find/fact-find-details/fact-find-scope/fact-find-scope.component';
import { FactFindExistingComponent } from './components/fact-find/fact-find-details/fact-find-existing/fact-find-existing.component';
import { FactFindFinancialComponent } from './components/fact-find/fact-find-details/fact-find-financial/fact-find-financial.component';

import { BusinessDashboardComponent } from './components/business/dashboard/business-dashboard.component';
import { BusinessService } from './service/business/business.service';
import { BusinessDetailComponent } from './components/business/detail/business-detail.component';
import { BusinessDetailService } from './service/business/business.detail.service';
import { BusinessStepMenuComponent } from './containers/business/business-step-menu.component';
import { BusinessPeopleComponent } from './components/business/people/people.component';
import { BusinessPeopleService } from './service/business/business.people.service';
import { BusinessFinancialPositionComponent } from './components/business/financial-position/financial-position.component';
import { BusinessFinancialPositionService } from './service/business/business.financial.position.service';
import { BusinessFinancialPerformanceComponent } from './components/business/financial-performance/financial-performance.component';
import { BusinessFinancialPerformanceService } from './service/business/business.financial.performance.service';
import { BusinessExistingCoversService } from './service/business/business.existing.covers.service';
import { BusinessExistingCoverComponent } from './components/business/existing-covers/bus-existing-covers.component';
import { BusinessQuoteService } from './service/business/quote/business.quote.service';
import { BusinessQuoteBenefitDetailComponent } from './components/business/quote/benefit/bus-quote-benefit.component';
import { BusinessNcNatureComponent } from './components/business/nature/bus-nc-nature.component';
import { BusinessNcNatureService } from './service/business/business-need-analysis/business.nc.nature.service';
import { BusinessNcObjectivesService } from './service/business/business-need-analysis/business.nc.objectives.service';
import { BusinessNcObjectivesComponent } from './components/business/objectives/bus-nc-objectives.component';
import { BusinessNcScopePrioritiesComponent } from './components/business/scope-and-priorities/bus-nc-scope-priorities.component';
import { BusinessNcScopePrioritiesService } from './service/business/business-need-analysis/business.nc.scope.priorities.service';
import { BusinessNcCalculationsService } from './service/business/business-need-analysis/business.nc.calculations.service';
import { BusinessNcCalculationsComponent } from './components/business/nc-calculations/bus-nc-calculations.component';
import { BusinessQuoteStepMenuComponent } from './containers/business/business-quote-step-menu.component';
import { MatTreeModule } from '@angular/material/tree';
import { BusinessQuoteBenefitRepeaterComponent } from './components/business/quote/benefit/bus-quote-benefit-repeater.component';
import { BusinessQuoteBenefitDetailSettingComponent } from './components/business/quote/benefit/bus-quote-benefit-setting-result.component';
import { BusinessQuoteSettingComponent } from './components/setting/business/business-quote-setting/business-quote-setting.component';
import { BusinessQuoteBenefitSelectorDialogComponent } from './components/business/quote/benefit/bus-quote-benefit-selector-dialog.component';
import { BusinessQuoteBenefitQuoteResultDialogComponent } from './components/business/quote/benefit/bus-quote-benefit-quote-result-dialog.component';
import { ExistingQuoteListDialogComponent } from './components/business/people/existing-quote-list-dialog.component';
import { BusinessQuoteResearchIndexComponent } from './components/business/quote/research/business-quote-research-index.component';
import { BusinessQuoteChartsComponent } from './components/business/quote/research/business-quote-charts/business-quote-charts.component';
import { BusinessQuoteDocumentComponent } from './components/business/quote/research/business-quote-document/business-quote-document.component';
import { BusinessQuoteResearchComponent } from './components/business/quote/research/business-quote-research/business-quote-research.component';
import { BusinessQuoteH2hComponent } from './components/business/quote/research/business-quote-h2h/business-quote-h2h.component';
import { BusinessQuoteReportComponent } from './components/business/quote/report/business-quote-report.component';
import { BusinessSoaSettingComponent } from './components/setting/business/business-soa-setting/business-soa-setting.component';
import { SaveCloseBusinessRecordComponent } from './components/business/save-close-business-record/save-close-business-record.component';
import {BookTrainingClaimTrialComponent} from './components/about/book-training-claim-trial.component';
import { CheckmonsterReasonDialogComponent } from './components/quote/checkmonster-reason-dialog/checkmonster-reason-dialog.component';
import { OauthLoginComponent } from './components/third-party-company/oauth-login/oauth-login.component';
import { ThirdPartyCompaniesAccessSettingComponent } from './components/setting/third-party-companies-access-setting/third-party-companies-access-setting.component';
import { OneClickLoginComponent } from './components/third-party-company/one-click-login/one-click-login.component';
import { QuoteComparePriceChangeHistoryComponent } from './components/quote/quote-compare-price-change-history.component';
import { KiwiMonsterDashboardComponent } from './components/kiwimonster/kiwimonster-dashboard.component';
import { KiwiMonsterClientComponent } from './components/kiwimonster/kiwimonster-client/kiwimonster-client.component';
import { KiwiMonsterSaveCloseBtnComponent } from './components/kiwimonster/kiwimonster-save-close-btn/kiwimonster-save-close-btn.component';
import { KiwiMonsterStepMenuComponent } from './containers/kiwimonster-step-menu/kiwimonster-step-menu.component';
import { KiwiMonsterKiwiSaverComponent } from './components/kiwimonster/kiwimonster-kiwisaver/kiwimonster-kiwisaver.component';
import { KiwiMonsterFundsDialogComponent } from './components/kiwimonster/kiwimonster-funds-dialog/kiwimonster-funds-dialog.component';
import { KiwiMonsterCompareComponent } from './components/kiwimonster/kiwimonster-compare/kiwimonster-compare.component';
import { NZBNSelectCompanyDialogComponent } from './components/business/detail/nzbn-select-company-dialog/nzbn-select-company-dialog.component';
import { NZBNSearchDialogComponent } from './components/business/nzbn-search-dialog/nzbn-search-dialog.component';
import { NZBNCompanyDetailComponent } from './components/business/nzbn-search-dialog/nzbn-company-detail/nzbn-company-detail.component';

import { datadogRum } from '@datadog/browser-rum';
import { PlatformSettingComponent } from './components/setting/platform-setting/platform-setting.component';
import { KiwiMonsterExcludeFundsDialogComponent } from './components/kiwimonster/kiwimonster-kiwisaver/kiwimonster-exclude-funds-dialog/kiwimonster-exclude-funds-dialog.component';
import { CustomSnackbarComponent } from './components/shared/custom-snackbar/custom-snackbar.component';
import { PlatformSettingSwitchComponent } from './components/setting/platform-setting/platform-setting-switch/platform-setting-switch.component';
import { KiwiMonsterHomeComponent } from './components/home/kiwimonster-home/kiwimonster-home.component';
import { KiwiMonsterReportComponent } from './components/kiwimonster/kiwimonster-report/kiwimonster-report.component';
import { KiwiMonsterFaqComponent } from './components/kiwimonster/kiwimonster-faq/kiwimonster-faq.component';
import { KiwiMonsterHowItWorksComponent } from './components/kiwimonster/kiwimonster-how-it-works/kiwimonster-how-it-works.component';
import { TermsAgreementDialogComponent } from './components/login/terms-agreement-dialog/terms-agreement-dialog.component';
import { environment } from 'src/environments/environment';
import { KiwiMonsterPreferredFundsComponent } from './components/setting/kiwimonster/kiwimonster-preferred-funds/kiwimonster-preferred-funds.component';
import { KiwiMonsterFundSelectionDialogComponent } from './components/setting/kiwimonster/kiwimonster-fund-selection-dialog/kiwimonster-fund-selection-dialog.component';



@NgModule({
  declarations: [
    AppComponent,
    PublicPageComponent,
    PrivatePageComponent,

    NavMenuComponent,
    DashboardComponent,
    DashboardArchivesComponent,

    LoginComponent,
    SignupComponent,
    VerifyDeviceComponent,
    ResetPasswordComponent,
    HomeComponent,
    BlogPageComponent,
    RecentQuoteComponent,
    ForgottenPasswordDialogComponent,
    UpdatePaPasswordComponent,

    AboutComponent,
    AboutOverviewComponent,
    AboutUsingQuotemonsterComponent,
    AboutFaqsComponent,

    ContactComponent,
    NoticeComponent,
    UserNoticeDialog,

    QuoteStepMenuComponent,
    QuoteClientComponent,
    QuoteCheckmonsterComponent,
    CheckmonsterReasonDialogComponent,
    BenefitOrAnalysisSelectorDialogComponent,

    QuotePersonalBenefitComponent,
    QuotePersonalBenefitSelectorComponent,
    QuotePersonalBenefitListComponent,
    QuotePersonalBenefitSettingComponent,
    QuotePersonalBenefitResultComponent,

    QuoteCompareComponent,
    QuoteCompareChartsComponent,
    QuoteCompareDocumentComponent,
    QuoteCompareResearchComponent,
    QuoteCompareCheckmonsterComponent,
    QuoteCompareHeadToHeadComponent,
    QuoteCompareUnderwritingComponent,
    QuoteReportComponent,
    OccupationDescriptionDialogSheet,
    QuoteResultDialogComponent,
    AssumptionsGuideToProjectingPremiumDialogComponent,
    QuoteReportDialogComponent,
    SaveAndCloseComponent,

    NcStepMenuComponent,
    NeedAnalysisNatureComponent,
    NeedAnalysisScopeComponent,
    NeedAnalysisRecommendationComponent,
    NeedAnalysisRecommendationComponent,
    NeedAnalysisObjectivesComponent,
    NeedAnalysisNeedCalComponent,
    NeedAnalysisFinancialComponent,
    NeedAnalysisExistingComponent,
    SoaSectionDetailEditorDialog,

    SettingMenuComponent,
    QuoteSettingComponent,
    CommissionSettingComponent,
    UserProviderSettingDialog,
    UserSettingComponent,
    OccupationSettingComponent,
    NeedSettingComponent,
    BuyNowSettingComponent,
    CrmSettingComponent,
    SoaSettingComponent,
    OccupationSettingDialogComponent,
    SecuritySettingComponent,
    MainSettingComponent,
    UserProfileComponent,
    ResearchSettingComponent,

    ResearchMenuComponent,
    ResearchHomeComponent,
    ResearchHomeOurApproachComponent,
    ResearchHomeFeaturesComponent,
    ResearchHomeFaqsComponent,
    ResearchHomeMethodologyComponent,
    ResearchHomeAdvertisingComponent,
    ResearchHomeInfographicsComponent,
    ResearchToolsIndependentResearchComponent,
    ResearchToolsFuneralPlansComponent,
    ResearchToolsBusinessOverheadsCoverComponent,
    ResearchToolsDisabilityClaimsModelComponent,
    ResearchToolsKeyPersonRatingComponent,
    ResearchToolsLegacyResearchComponent,
    ResearchToolsInsurerSelectionScreenComponent,
    ResearchPolicyWordingComponent,
    ResearchPolicyDocumentComponent,

    ResearchSubscriptionComponent,
    ResearchLicenseOrderComponent,
    ResearchLicenseOrderDetailComponent,
    ResearchLicenseOrderPayOnlineDialogComponent,
    ResearchLicenseOrderPaymentSelectComponent,
    ResearchSubscriptionExistingLicensesComponent,
    ResearchSubscriptionCompletePaymentComponent,
    ResearchSubscriptionPaymentsComponent,
    ResearchSubscriptionBillingAddressComponent,
    ResearchSubscriptionInvoicesComponent,
    ResearchSubscriptionTermsComponent,
    ResearchPolicyWordingDialog,
    ResearchPolicyWordingV2Dialog,

    GeneralMessageDialogComponent,
    QuestionDialogComponent,
    ConfirmMessageDialogComponent,
    AppReportsDialogComponent,

    QmadsDirective,
    QmadsPresentComponent,
    AdHtmlComponent,
    AdImageComponent,

    SafeUrlPipe,
    SafeHtmlPipe,
    AutofocusDirective,
    PricingComponent,
    SideMenuComponent,
    AdPublicShowComponent,
    GuideStartButtonComponent,
    ACEListComponent,
    ACEDetailsComponent,
    ACECommentsComponent,
    CpdTrainingRequestComponent,
    AutoSelectDirective,
    StandaloneHeadToHeadComponent,
    MfaMethodSelectComponent,
    RealignInkBarDirective,
    QuoteWizardClientOverviewComponent,
    QuoteWizardIpMpComponent,
    SelectSearchComponent,
    QuoteWizardSuggestionComponent,
    EditQuoteNameDialogComponent,
    ScopeNoteDialogComponent,
    QuoteReportPremiumBarChartDialogComponent,
    SpeechRecognitionComponent,
    UploadPolicyComponent,
    PolicySummaryComponent,
    FactFindDashboardComponent,
    FactFindCreationDialogComponent,
    FactFindResetPasswordDialogComponent,
    FactFindCopyDialogComponent,
    FactFindPublicComponent,

    FactFindDetailsComponent,
    FactFindObjectivesComponent,
    FactFindScopeComponent,
    FactFindExistingComponent,
    FactFindFinancialComponent,


    BusinessStepMenuComponent,
    BusinessDashboardComponent,
    BusinessDetailComponent,
    BusinessPeopleComponent,
    BusinessFinancialPositionComponent,
    BusinessFinancialPerformanceComponent,
    BusinessExistingCoverComponent,
    
    BusinessNcNatureComponent,
    BusinessNcObjectivesComponent,
    BusinessNcScopePrioritiesComponent,
    BusinessNcCalculationsComponent,
    BusinessQuoteStepMenuComponent,

    BusinessQuoteBenefitDetailComponent,
    BusinessQuoteBenefitRepeaterComponent,
    BusinessQuoteBenefitDetailSettingComponent,
    BusinessQuoteSettingComponent,
    BusinessQuoteBenefitSelectorDialogComponent,
    BusinessQuoteBenefitQuoteResultDialogComponent,
    ExistingQuoteListDialogComponent,
    BusinessQuoteResearchIndexComponent,
    BusinessQuoteChartsComponent,
    BusinessQuoteDocumentComponent,
    BusinessQuoteResearchComponent,
    BusinessQuoteH2hComponent,
    BusinessQuoteReportComponent,
    BusinessSoaSettingComponent,
    SaveCloseBusinessRecordComponent,
    
    BookTrainingClaimTrialComponent,
    OauthLoginComponent,
    ThirdPartyCompaniesAccessSettingComponent,
    OneClickLoginComponent,
    QuoteComparePriceChangeHistoryComponent,
    KiwiMonsterDashboardComponent,
    KiwiMonsterStepMenuComponent,
    KiwiMonsterClientComponent,
    KiwiMonsterSaveCloseBtnComponent,
    KiwiMonsterKiwiSaverComponent,
    KiwiMonsterFundsDialogComponent,
    KiwiMonsterCompareComponent,
    NZBNSelectCompanyDialogComponent,
    NZBNSearchDialogComponent,
    NZBNCompanyDetailComponent,
    PlatformSettingComponent,
    KiwiMonsterExcludeFundsDialogComponent,
    CustomSnackbarComponent,
    PlatformSettingSwitchComponent,
    KiwiMonsterHomeComponent,
    KiwiMonsterReportComponent,
    KiwiMonsterFaqComponent,
    KiwiMonsterHowItWorksComponent,
    TermsAgreementDialogComponent,
    KiwiMonsterPreferredFundsComponent,
    KiwiMonsterFundSelectionDialogComponent,
  ],

  entryComponents: [
    AdHtmlComponent,
    AdImageComponent,
    UserProviderSettingDialog,
    UserNoticeDialog,
    OccupationDescriptionDialogSheet,
    BenefitOrAnalysisSelectorDialogComponent,
    QuoteResultDialogComponent,
    AssumptionsGuideToProjectingPremiumDialogComponent,
    QuoteReportDialogComponent,
    ResearchPolicyWordingDialog,
    ResearchPolicyWordingV2Dialog,
    OccupationSettingDialogComponent,
    GeneralMessageDialogComponent,
    ResearchLicenseOrderPayOnlineDialogComponent,
    QuestionDialogComponent,
    ForgottenPasswordDialogComponent,
    ConfirmMessageDialogComponent,
    AppReportsDialogComponent,
    SoaSectionDetailEditorDialog,
    EditQuoteNameDialogComponent,
    ScopeNoteDialogComponent,
    QuoteReportPremiumBarChartDialogComponent,
    FactFindCreationDialogComponent,
    FactFindResetPasswordDialogComponent,
    FactFindCopyDialogComponent,
    BusinessQuoteBenefitSelectorDialogComponent,
    BusinessQuoteBenefitQuoteResultDialogComponent,
    ExistingQuoteListDialogComponent,
    CheckmonsterReasonDialogComponent,
    KiwiMonsterFundsDialogComponent,
    NZBNSelectCompanyDialogComponent,
    NZBNSearchDialogComponent,
    KiwiMonsterExcludeFundsDialogComponent,
    CustomSnackbarComponent,
    TermsAgreementDialogComponent,
    KiwiMonsterFundSelectionDialogComponent,
  ],

  imports: [
    CommonModule,
    HttpClientModule,
    FormsModule,
    BrowserModule,
    BrowserAnimationsModule,
    ReactiveFormsModule,
    CurrencyMaskModule,

    MatAutocompleteModule,
    MatButtonModule,
    MatButtonToggleModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatGridListModule,
    MatIconModule,
    MatInputModule,
    MatListModule,
    MatMenuModule,
    MatNativeDateModule,
    MatPaginatorModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatRadioModule,
    MatRippleModule,
    MatSelectModule,
    MatSidenavModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatSortModule,
    MatTableModule,
    MatTabsModule,
    MatToolbarModule,
    MatTooltipModule,
    MatBadgeModule,
    MatStepperModule,
    A11yModule,
    BidiModule,
    ObserversModule,
    OverlayModule,
    PlatformModule,
    PortalModule,
    MatBottomSheetModule,
    DragDropModule,
    MatSidenavModule,

    AppRoutingModule,

    EditorModule,
    MatTreeModule
  ],
  providers: [
    FeedbackService,
    QmadsService,
    ApiAddressService,
    SysConfigService,
    LoginService,
    BlogService,
    DataLoaderService,
    DataLoader,
    QuoteService,
    QuoteStepClient,
    QuoteStepBenefit,
    QuoteStepCompare,
    QuoteStepCompareChart,
    QuoteStepCompareDocument,
    QuoteStepCompareResearch,
    QuoteStepCompareCheckmonster,
    QuoteStepCompareH2H,
    QuoteStepCompareUnderwriting,
    QuoteStepReport,
    UserService,
    UserSecurityService,
    MatStepperIntl,
    SharedFunctionService,
    NeedAnalysisService,
    ResizeService,

    CanDeactivateGuard,
    ConfirmMessageDialogService,

    ApiService,
    UserMenuService,
    DatePipe,

    
    BusinessService,
    BusinessDetailService,
    BusinessPeopleService,
    BusinessFinancialPositionService,
    BusinessFinancialPerformanceService,
    BusinessExistingCoversService,
    BusinessQuoteService,
    BusinessNcNatureService,
    BusinessNcObjectivesService,
    BusinessNcScopePrioritiesService,
    BusinessNcCalculationsService,
    
    {
      provide: UrlSerializer,
      useClass: LowerCaseUrlSerializer,
    },

    {
      provide: MAT_DATE_LOCALE,
      useValue: "en-NZ",
    },

    { provide: TINYMCE_SCRIPT_SRC, useValue: 'tinymce/tinymce.min.js' }
  ],
  bootstrap: [AppComponent],
})
export class AppModule {
  constructor(router: Router) {

      datadogRum.init({
        applicationId: '27471101-5f2b-4dd0-8f32-6bbbb8f3eed6',
        clientToken: 'pubba8534ff57eef8d054ad24f4ba8ec412',
        // `site` refers to the Datadog site parameter of your organization
        // see https://docs.datadoghq.com/getting_started/site/
        site: 'ap1.datadoghq.com',
        service: 'qmfrontend',
        env: isDevMode() ? 'dev' : 'prod',
        // Specify a version number to identify the deployed version of your application in Datadog
        version: environment.webVersion, 
        // add tracking urls.
        allowedTracingUrls: ["https://quotemonster.co.nz", "https://*.quotemonster.co.nz", "https://kiwimonster.co.nz", "https://*.kiwimonster.co.nz"],
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input',
      });      
   }
}
