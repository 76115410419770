<ul class="list-group quote-compare-research-page">
  <li class="list-group-item active">
    <div class='row align-items-center'>
      <div class='col'>
        <h5 class="mb-0">Research</h5>
      </div>
      <div class='col text-right' *ngIf='isDevMode'>
        <button mat-icon-button class="qm-icon-btn-sm">
          <span class="material-icons">contact_support</span>
        </button>
      </div>
    </div>
  </li>

  <li class="list-group-item border-top-0">
    <div class='row'>
      <div class='col-md-6'>
        <mat-form-field class='res-benefit'>
          <mat-select placeholder="Select a benefit" [(ngModel)]="quoteStepCompareResearch.selectedBenefit"
            [compareWith]='setDefaultValue' (openedChange)='reloadQprItem($event)'>
            <mat-option *ngFor="let benefit of quoteStepCompareResearch.currentQuote.AvailableQPRBenefitList"
              [value]='benefit'>
              <span [innerHtml]='benefit.BenefitName'></span>
              <span [innerHtml]='benefit.BenefitShortName'></span>
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='col-md-3' *ngIf='quoteStepCompareResearch.selectedBenefit.BenefitId > 0'>
        <mat-form-field>
          <mat-select placeholder="Order by" [(ngModel)]="quoteStepCompareResearch.selectedBenefit.QprItemOrderBy"
            (openedChange)='reloadQprItem($event)'>
            <mat-option [value]=1>
              Importance
            </mat-option>
            <mat-option [value]=2>
              Alphabetical
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
      <div class='col-md'>
        <mat-form-field class='res-options'>
          <mat-select placeholder="Research Report Options" required
            [(ngModel)]="quoteStepCompareResearch.currentQuote.ResearchReportType"
            (selectionChange)='updateResearchReportSetting()'>
            <mat-option [value]=1>
              {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchStar')}}
            </mat-option>
            <mat-option [value]=2>
              {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchHeatmap')}}
            </mat-option>
            <mat-option [value]=3>
              {{sharedFunction.getUiMessageByCode('Share-INFO-ResearchOverview')}}
            </mat-option>
          </mat-select>
        </mat-form-field>
      </div>
    </div>
    <div class='row'>
      <div class='col-12'>
        <mat-accordion>
          <mat-expansion-panel [expanded]="qprCompanySelectorOpenState" (opened)="qprCompanySelectorOpenState = true"
            (closed)="qprCompanySelectorOpenState = false" class='res-company'>
            <mat-expansion-panel-header class="my-2">
              <mat-panel-title>
                <h5 class="mb-0">Select Companies</h5>
              </mat-panel-title>
              <mat-panel-description>
                <span
                  class='w-75 text-truncate'>{{quoteStepCompareResearch.getSelectedQprProviderNameList()}}</span>
              </mat-panel-description>
            </mat-expansion-panel-header>
            <ul class='row px-3'
              *ngIf='quoteStepCompareResearch.selectedBenefit && quoteStepCompareResearch.selectedBenefit.QprCompanyList'>
              <ng-container *ngFor="let qprCompany of quoteStepCompareResearch.selectedBenefit.QprCompanyList">
                <li class="no-list-style col-sm-6 col-md-4 col-lg-3">
                  <mat-checkbox [checked]='qprCompany.IsSelected' (change)='checkTotalSelectedQprCompany(qprCompany)'>
                    {{qprCompany.Name}}
                  </mat-checkbox>
                </li>
              </ng-container>
            </ul>
            <div class='row my-3'>
              <div class='col-auto my-1'>
                <button type="button" mat-flat-button color="qm-blue"
                  (click)='selectAllBanks();quoteStepCompareResearch.loginService.doGoogleTracking("quote-compare-research", "select all banks", "");'>
                  Select Banks
                </button>
              </div>
              
              <div class="col-auto my-1">
                <button type="button" mat-flat-button color="warn"
                (click)='resetDefaultQprCompany();quoteStepCompareResearch.loginService.doGoogleTracking("quote-compare-research", "reset", "");'>
                Reset to default
                </button>
              </div>
            
              <div class="col-auto my-1">
                <button type="button" mat-flat-button color="qm-blue"
                  (click)='saveSelectedQprCompany();quoteStepCompareResearch.loginService.doGoogleTracking("quote-compare-research", "saveSetting", "");'>
                  Save
                </button>
              </div>

              <div class="col-auto my-1">
                <button type="button" mat-flat-button color="qm-black"
                  (click)='qprCompanySelectorOpenState = false;quoteStepCompareResearch.loginService.doGoogleTracking("quote-compare-research", "close", "");'>
                  Close
                </button>
              </div>

            </div>

            <div class='row no-gutters' *ngIf='showMoreThen12CompaniesAlert'>
              <div class='col px-3 alert alert-warning' [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-WARNING-Only12Companies')">
              </div>
            </div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
    </div>
  </li>

  <li class="list-group-item border-top-0 qpr-item-holder pb-0" *ngIf='quoteStepCompareResearch.currentQuote.QprResults'>
    <div class='table-responsive'>
      <table class='table table-hover research-report mb-0'>
        <thead>
          <ng-container
            *ngFor="let itemLine of quoteStepCompareResearch.currentQuote.QprResults; index as i; let even = even;">
            <tr class='align-middle'>
              <!-- LOGO, STAR, QPRRATING start -->
              <ng-container *ngIf="itemLine.Type === 'LOGO' || itemLine.Type === 'STAR' || itemLine.Type === 'QPRRATING'">
                <td *ngIf='i === 0' rowspan="3" class='align-middle'>
                  <img src='/assets/images/qprlogo.jpg' alt='QPR' style='max-width: 250px;' />
                </td>
  
                <!-- logo start -->
                <ng-container *ngIf='itemLine.Type === "LOGO"'>
                  <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                    <th class='border-bottom-0 position-rel h-60px'>
                      <img src='{{quoteStepCompareResearch.displayQprItem(itemLine, providerId).StringValue + timeSpan}}'
                        alt="{{quoteStepCompareResearch.displayQprItem(itemLine, providerId).Name}}"
                        class='provider-logo position-middle' />
                    </th>
                  </ng-container>
                </ng-container>
                <!-- logo end -->
  
                <!-- STAR start -->
                <ng-container *ngIf='itemLine.Type === "STAR"'>
                  <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                    <th class='text-center align-top border-bottom-0'>
                      <div
                        [innerHTML]='quoteStepCompareResearch.getQprStarRating(quoteStepCompareResearch.displayQprItem(itemLine, providerId).Value)'>
                      </div>
                    </th>
                  </ng-container>
                </ng-container>
                <!-- STAR end -->
  
                <!-- rating start -->
                <ng-container *ngIf='itemLine.Type === "QPRRATING"'>
                  <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                    <th class='text-center align-top border-bottom-0'>
                      <div [innerHtml]='quoteStepCompareResearch.displayQprItem(itemLine, providerId).DisplayValue'>
                      </div>
                    </th>
                  </ng-container>
                </ng-container>
                <!-- rating end -->
              </ng-container>
              <!-- LOGO, STAR, QPRRATING end -->
  
              <!-- QPRPRODUCTNAME start -->
              <ng-container *ngIf="itemLine.Type === 'QPRPRODUCTNAME'">
                <th class='qpr-y-axis-td align-middle align-top border-bottom-0 bg-light'>
                  Provided By
                </th>
  
                <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                  <th class='text-center align-top border-bottom-0 bg-light'>
                    <span class='d-block qm-text-mid text-bold'>
                      {{quoteStepCompareResearch.displayQprItem(itemLine,
                      providerId).Name}}
                    </span>
                    <span class='d-block qm-text-xsm qpr-provider-product-name'>
                      ({{quoteStepCompareResearch.displayQprItem(itemLine,
                      providerId).QprProductName}})
                    </span>
                  </th>
                </ng-container>
              </ng-container>
              <!-- QPRPRODUCTNAME end -->
            </tr>
          </ng-container>
        </thead>
        <tbody>
          <ng-container
            *ngFor="let itemLine of quoteStepCompareResearch.currentQuote.QprResults; index as i; let even = even;">
            <tr class='align-middle'>
  
              <!-- package score start -->
              <ng-container *ngIf='itemLine.Type === "QPRPACKAGEITEM"'>
                <th class='qpr-y-axis-td align-top'>
                  <strong>{{itemLine.Name}}</strong>
                </th>
  
                <ng-container *ngIf='itemLine.MaxValue > 0'>
                  <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                    <td class='text-center'>
                      <span class='d-block text-bold'>
                        {{quoteStepCompareResearch.displayQprItem(itemLine,
                        providerId).Value}}
                      </span>
                      <span class='d-block qm-text-mid'>
                        ({{quoteStepCompareResearch.displayQprItem(itemLine,
                        providerId).StringValue}})
                      </span>
                    </td>
                  </ng-container>
                </ng-container>
  
                <ng-container *ngIf='itemLine.MaxValue <= 0'>
                  <td class='text-center' [colSpan]='quoteStepCompareResearch.availableQprProviderIds.length'>
                    -----not included-----
                  </td>
                </ng-container>
              </ng-container>
              <!-- package score end -->
  
  
              <!-- qpr item start -->
              <ng-container *ngIf="itemLine.Type === 'QPRITEM'">
                <!-- name start -->
                <td class='qpr-y-axis-td'>
                  <button mat-button color="qm-blue" (click)='showQprItemDetail(itemLine);quoteStepCompareResearch.loginService.doGoogleTracking("quote-compare-research", "show QPR item detail", "");' title="QPR Item Detail">{{itemLine.Name}}</button>
                </td>
                <!-- items loop -->
                <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                  <td class='text-center'>
                    <span title='{{quoteStepCompareResearch.displayQprItem(itemLine, providerId).Name}}'
                      [innerHtml]='quoteStepCompareResearch.displayQprItem(itemLine, providerId).DisplayValue'></span>
                  </td>
                </ng-container>
              </ng-container>
              <!-- qpr item end -->
            </tr>
          </ng-container>

          <ng-container *ngFor="let itemLine of quoteStepCompareResearch.currentQuote.QprResults;">
            <tr class='align-middle'>
              <!-- SECONDARYBENEFITSSCORE start -->
               <ng-container *ngIf='itemLine.Type === "SECONDARYBENEFITSSCORE"'>
                  <th class='qpr-y-axis-td align-middle bg-light'>
                    <strong>{{itemLine.Name}}</strong>
                  </th>
                 <ng-container *ngIf='quoteStepCompareResearch.selectedBenefit.QprItemOrderBy === 1'>
                    <ng-container *ngIf="quoteStepCompareResearch.currentQuote.ResearchReportType === 1; else textDescription">
                      <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                        <th class='text-center align-top bg-light'>
                          {{quoteStepCompareResearch.displayQprItem(itemLine,
                          providerId).Value.toFixed(2)}}
                        </th>
                      </ng-container>
                    </ng-container>
                    <ng-template #textDescription>
                      <td class='text-center align-top bg-light' [colSpan]='quoteStepCompareResearch.availableQprProviderIds.length' 
                          [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-SecondaryItems')">
                      </td>
                    </ng-template>
                 </ng-container>
              </ng-container>
               <!-- SECONDARYBENEFITSSCORE end -->
             </tr>
          </ng-container>
          

          <ng-container *ngFor="let itemLine of quoteStepCompareResearch.currentQuote.QprResults;">
            <tr class='align-middle'>              
              <!-- TOTALBENEFITSSCORE, TOTALWEIGHTEDIQRATING start -->
              <ng-container
                *ngIf='(itemLine.Type === "TOTALBENEFITSSCORE" || itemLine.Type ==="TOTALWEIGHTEDIQRATING")'>
  
                <th class='qpr-y-axis-td align-top bg-light'>
                  <strong>{{itemLine.Name}}</strong>
                </th>
  
                <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                  <th class='text-center align-top bg-light'>
                    {{quoteStepCompareResearch.displayQprItem(itemLine,
                    providerId).Value.toFixed(2)}}
                  </th>
                </ng-container>
              </ng-container>
              <!-- TOTALBENEFITSSCORE, TOTALWEIGHTEDIQRATING end --></tr>
          </ng-container>

          <ng-container *ngFor="let itemLine of quoteStepCompareResearch.currentQuote.QprResults;">
            <tr class='align-middle'>
              <!-- rating start -->
                <ng-container *ngIf='itemLine.Type === "QPRRATING" && quoteStepCompareResearch.currentQuote.ResearchReportType === 1'>
                  <th class='qpr-y-axis-td align-top bg-light'>
                  <strong>Percentage Rating</strong>
                </th>
                  <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                    <th class='text-center align-top bg-light'>
                      <!-- <ng-container *ngIf='quoteStepCompareResearch.selectedBenefit.BenefitId < 0'>
                        {{quoteStepCompareResearch.displayQprItem(itemLine, providerId).Value| number:"1.0-0":"en-NZ"}}%
                      </ng-container> -->
                        <div [innerHtml]='quoteStepCompareResearch.displayQprItem(itemLine, providerId).DisplayValue'>
                        </div>
                    </th>
                  </ng-container>
                </ng-container>
                <!-- rating end -->
            </tr>
          </ng-container>
          
        </tbody>
        <tfoot>
          <ng-container *ngFor="let itemLine of quoteStepCompareResearch.currentQuote.QprResults;">
            <tr class='align-middle'>
              <!-- QPRPRODUCTNAME start -->
              <ng-container *ngIf="itemLine.Type === 'QPRPRODUCTNAME'">
                <th class='qpr-y-axis-td align-middle align-top border-bottom-0'>
                  Provided By
                </th>
  
                <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                  <th class='text-center align-top border-bottom-0'>
                    <span class='d-block qm-text-mid text-bold'>
                      {{quoteStepCompareResearch.displayQprItem(itemLine,
                      providerId).Name}}
                    </span>
                    <span class='d-block qm-text-xsm qpr-provider-product-name'>
                      ({{quoteStepCompareResearch.displayQprItem(itemLine,
                      providerId).QprProductName}})
                    </span>
                  </th>
                </ng-container>
              </ng-container>
              <!-- QPRPRODUCTNAME end -->
            </tr>
          </ng-container>
          <ng-container *ngFor="let itemLine of quoteStepCompareResearch.currentQuote.QprResults;">
            <tr class='align-middle'>
              <ng-container *ngIf="itemLine.Type === 'LOGO'">
                <td>
                  &nbsp;
                </td>
                <ng-container *ngFor="let providerId of quoteStepCompareResearch.availableQprProviderIds">
                  <th class='position-rel h-60px'>
                    <img src='{{quoteStepCompareResearch.displayQprItem(itemLine, providerId).StringValue + timeSpan}}'
                      alt="{{quoteStepCompareResearch.displayQprItem(itemLine, providerId).Name}}"
                      class='provider-logo position-middle' />
                  </th>
                </ng-container>
              </ng-container>
            </tr>
          </ng-container>
        </tfoot>
      </table>
    </div>
  </li>

  <li class="list-group-item text-center qm-text-xsm" [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-StarRatingNote')">
  </li>

  <li class="list-group-item text-center" *ngIf='quoteStepCompareResearch.currentQuote.ResearchReportType === 2 || quoteStepCompareResearch.currentQuote.ResearchReportType ===3'>
    <strong class="qm-text-xsm">Word & Colour: </strong>
    <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-HeatmapGAP')"></span>
    <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-HeatmapPoor')"></span>
    <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-HeatmapMarginal')"></span>
    <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-HeatmapFair')"></span>
    <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-HeatmapGood')"></span>
    <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-HeatmapVeryGood')"></span>
    <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-HeatmapExcellent')"></span>
    <span [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-HeatmapSuperior')"></span>
    <ng-container *ngIf='quoteStepCompareResearch.currentQuote.ResearchReportType === 3'>
      <br>
      <span class="qpr-item-description" [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-BenefitOverviewOffered')"></span>
      <span class="qpr-item-description" [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-BenefitOverviewNotOffered')"></span>
      <span class="qpr-item-description" [innerHTML]="sharedFunction.getUiMessageByCode('QuoteCompareResearch-INFO-BenefitOverviewOptional')"></span>
    </ng-container>
  </li>
</ul>
