import { Component, OnInit, isDevMode } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ResearchPolicyWordingV2Dialog } from 'src/app/components/research/policy/research-policy-wording-v2.dialog';
import { Benefit } from 'src/app/models/benefit';
import { QprCompany } from 'src/app/models/qpr.company';
import { PolicyWordingItem, PolicyWordingListItem } from 'src/app/models/qpr.models/policy.wording';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { BusinessQuoteResearchService } from 'src/app/service/business/quote/business.quote.research.service';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';

@Component({
    selector: 'business-quote-research',
    templateUrl: './business-quote-research.component.html',
    styleUrls: ['./business-quote-research.component.scss']
})
export class BusinessQuoteResearchComponent implements OnInit {
    qprCompanySelectorOpenState: boolean = false;
    showMoreThen12CompaniesAlert: boolean = false;
    isDevMode: boolean = false;
    timeSpan: string = '';
    constructor (
        public sharedFunction: SharedFunctionService,
        public businessService: BusinessQuoteResearchService,
        public loginService: LoginService,
        public dialog: MatDialog,
    ) { }

    ngOnInit(): void {
        this.timeSpan = '?s=' + new Date().getTime().toString();
        this.businessService.onInit();
        this.isDevMode = isDevMode();
    }

    reloadQprItem(value: boolean) {
        if (value === false) {
            this.businessService.loadQprItem(this.businessService.currentSavedBusinessRecord, this.businessService.currentSavedBusinessQuote);
            this.qprCompanySelectorOpenState = false;
        }
    }

    updateResearchReportSetting() {
        this.businessService.saveCurrentBusinessQuote(this.businessService.currentSavedBusinessQuote);
    }

    checkTotalSelectedQprCompany(company: QprCompany) {

        let totalSelected: number = 0;
        for (let c of this.businessService.selectedBenefit.QprCompanyList) {
            if (c.IsSelected === true) {
                totalSelected = totalSelected + 1;
            }
        }


        company.IsSelected = !company.IsSelected;

        if (totalSelected > 12) {
            // company.IsSelected = false;
            // TODO: Show alert
            this.showMoreThen12CompaniesAlert = true;
        } else if (totalSelected <= 0) {
            this.showMoreThen12CompaniesAlert = true;
        } else {
            this.showMoreThen12CompaniesAlert = false;
        }

    }

    selectAllBanks() {

        let totalSelected: number = 0;

        for (let qprCompany of this.businessService.selectedBenefit.QprCompanyList) {
            if (qprCompany.IsBank === true) {
                qprCompany.IsSelected = true;
                totalSelected++;
            }
        }

        if (totalSelected > 12) {
            // company.IsSelected = false;
            // TODO: Show alert
            this.showMoreThen12CompaniesAlert = true;
        } else {
            this.showMoreThen12CompaniesAlert = false;
        }
    }

    resetDefaultQprCompany() {
        let quoteResult = this.businessService.currentSavedBusinessQuote.QuoteResult;
        // console.log('quoteResult=', quoteResult);
        // console.log('this.quoteStepCompareResearch.selectedBenefit.QprCompanyList=', this.quoteStepCompareResearch.selectedBenefit.QprCompanyList);
        for (let qprCompany of this.businessService.selectedBenefit.QprCompanyList) {
            qprCompany.IsSelected = false;
            if (quoteResult && quoteResult.QuoteResultList && quoteResult.QuoteResultList.length > 0) {
                for (let result of quoteResult.QuoteResultList) {
                    if (result.Provider && qprCompany.CompanyCode === result.Provider.QprCode) {
                        qprCompany.IsSelected = true;
                        break;
                    }
                }
            } else if (quoteResult && quoteResult.BankQuoteResultList && quoteResult.BankQuoteResultList.length > 0) {
                for (let result of quoteResult.BankQuoteResultList) {
                    if (result.Provider && qprCompany.CompanyCode === result.Provider.QprCode) {
                        qprCompany.IsSelected = true;
                        break;
                    }
                }
            }
        }

        this.qprCompanySelectorOpenState = false;
        this.businessService.setAllBenefitCompanySameAsPackageAndReloadQprItem(this.businessService.selectedBenefit, this.businessService.currentSavedBusinessQuote, this.businessService.currentSavedBusinessRecord);
    }

    saveSelectedQprCompany() {
        this.qprCompanySelectorOpenState = false;
        this.businessService.setAllBenefitCompanySameAsPackageAndReloadQprItem(this.businessService.selectedBenefit, this.businessService.currentSavedBusinessQuote, this.businessService.currentSavedBusinessRecord);
    }

    showQprItemDetail(qprItemLine: any) {
        let qprItemName = qprItemLine.Name;
        let policyWordingListItem = new PolicyWordingListItem();

        for (let providerId of this.businessService.availableQprProviderIds) {
            let providerPolicyWordingItem: PolicyWordingItem = new PolicyWordingItem();
            let providerItem = qprItemLine.Items.filter(item => item.ProviderId === providerId).reduce((obj, item) => (obj[item.Name] = item.Value, obj), {});
            providerPolicyWordingItem.DefaultCompanyCodeList = [providerItem.QprCode];
            providerPolicyWordingItem.ProductCode = providerItem.QprProductCode;
            providerPolicyWordingItem.ProductCodeExt = providerItem.QprProductCodeExt;
            providerPolicyWordingItem.QprItem = qprItemName;
            policyWordingListItem.List.push(providerPolicyWordingItem);
        }

        const dialogRef = this.dialog.open(ResearchPolicyWordingV2Dialog, {
            data: { PolicyWordingListItem: policyWordingListItem, IsBusiness: true },
            minWidth: '80%',
            panelClass: "policy-wordingV2-panel",
            autoFocus: false,
            restoreFocus: false
        });
    }

    setDefaultValue(value1: Benefit, value2: Benefit): boolean {
        if (value1 && value2) {
            return value1.UIBenefitId === value2.UIBenefitId;
        } else {
            return false;
        }
    }

    getFormattedQprProductName(itemLine: any, providerId: number, researchReportType: number, quoteRequiredData: QuoteRequiredData): string {
        let productName = this.businessService.displayQprItem(
            itemLine,
            providerId,
            researchReportType,
            quoteRequiredData
        ).QprProductName;

        return this.formatQprProductName(productName);
    }

    formatQprProductName(value: string): string {
        if (!value) return '';

        let searchText = '(for farmers)';
        let index = value.toLowerCase().indexOf(searchText);

        if (index !== -1) {
            // Remove "(for Farmers)" and trim the remaining text
            let mainText = value.substring(0, index).trim();
            return `(${ mainText })<br/>(For Farmers)`; // Add new line
        }

        return `(${ value })`;
    }
}
