import { Injectable } from '@angular/core';
import { ComponentBaseClass } from '../base';
import { KiwiMonsterService } from './kiwimonster.service';
import { PreferredFund } from 'src/app/models/kiwimonster/kiwimonster-preferred-fund.model';
import { ApiAddressService } from '../api.address.service';
import { ApiService } from '../api.service';

@Injectable({
  providedIn: 'root',
})

export class KiwiMonsterSettingService extends ComponentBaseClass {

  constructor (
    private apiAddressService: ApiAddressService,
    private apiService: ApiService,
    private kiwiMonsterService: KiwiMonsterService
  ) {
    super();
  }

  // local storage
  setCurrentPreferredFunds(currentPreferredFunds: PreferredFund[]): void {
    window.sessionStorage.setItem('currentPreferredFunds', JSON.stringify(currentPreferredFunds));
  }

  getCurrentPreferredFunds(): PreferredFund[] {
    return JSON.parse(window.sessionStorage.getItem('currentPreferredFunds'));
  }

  removeCurrentPreferredFunds(): void {
    sessionStorage.removeItem("currentPreferredFunds");
  }

  getKiwiMonsterUserPreferredFunds(callback: (data: PreferredFund[]) => void) {
    this.apiService.callApi<PreferredFund[]>(
      '', this.apiAddressService.getKiwiMonsterPreferredFundsUrl(),
      (response) => {
        callback(response);
      }
    );
  }

  updateKiwiMonsterUserPreferredFunds(funds: PreferredFund[], callback: (data: PreferredFund[]) => void) {
    this.apiService.callApi<PreferredFund[]>(
      funds, this.apiAddressService.updateKiwiMonsterPreferredFundsUrl(),
      (response) => {
        callback(response);
      }
    );
  }
}