import { Component, OnDestroy, OnInit } from '@angular/core';
import { PreferredFund } from 'src/app/models/kiwimonster/kiwimonster-preferred-fund.model';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';
import { KiwiMonsterSettingService } from 'src/app/service/kiwimonster/kiwimonster-setting.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { KiwiMonsterFundSelectionDialogComponent } from '../kiwimonster-fund-selection-dialog/kiwimonster-fund-selection-dialog.component';
import { MatDialog } from '@angular/material/dialog';
import { KiwiMonsterFund } from 'src/app/models/kiwimonster/kiwimonster-fund.model';
import { ConfirmMessageDialogService } from 'src/app/components/shared/confirm-message-dialog.service';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/service/login.service';
import { CanComponentDeactivate } from 'src/app/components/shared/can-deactivate-guard.service';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-kiwimonster-preferred-funds',
  templateUrl: './kiwimonster-preferred-funds.component.html',
  styleUrls: ['./kiwimonster-preferred-funds.component.scss']
})
export class KiwiMonsterPreferredFundsComponent implements OnInit, OnDestroy, CanComponentDeactivate {
  preferredFunds: PreferredFund[] = [];
  categories: QuoteDataObj[] = [];

  errorMessage: string = '';
  originalPreferredFunds: PreferredFund[] = [];
  constructor (
    private kiwiMonsterSettingService: KiwiMonsterSettingService,
    public sharedFunction: SharedFunctionService,
    private dialog: MatDialog,
    public confirmDialog: ConfirmMessageDialogService,
    public router: Router,
    public loginService: LoginService,
  ) { }

  ngOnInit(): void {
    this.categories = this.sharedFunction.quoteRequiredData.KiwiMonsterFundStatusInRetirementList;
    this.preferredFunds = this.generateInitFunds();
    this.getData();
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    this.kiwiMonsterSettingService.removeCurrentPreferredFunds();
  }

  getData(): void {
    this.kiwiMonsterSettingService.showDinoLoading();
    this.kiwiMonsterSettingService.getKiwiMonsterUserPreferredFunds((response) => {
      this.afterGetData(response);
      this.kiwiMonsterSettingService.closeDinoLoading();
    });
  }

  afterGetData(response: PreferredFund[], openSnackBar?: boolean): void {

    if (response) {
      if (this.kiwiMonsterSettingService.getCurrentPreferredFunds()?.length > 0) {
        // if refresh the page, get changed fund from localstorage
        this.preferredFunds = this.kiwiMonsterSettingService.getCurrentPreferredFunds();
        this.originalPreferredFunds = JSON.parse(JSON.stringify(this.fillInitFunds(response, this.generateInitFunds())));
      } else {
        this.preferredFunds = this.fillInitFunds(response, this.preferredFunds);
        this.kiwiMonsterSettingService.setCurrentPreferredFunds(this.preferredFunds);
        this.originalPreferredFunds = JSON.parse(JSON.stringify(this.preferredFunds));
      }

      if (openSnackBar) {
        this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
      }
    } else {
      this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-WARNING-SomethingWrong');
    }
  }

  openSelectDialog(provider: string, order: number): void {
    let currentPreferredFund: PreferredFund = this.preferredFunds.find(f => f.FundBaseTypeCode === provider && f.SelectOrder === order);

    let otherSelectedQmOfferSchemeFundIds: number[] = this.getOtherSelectedQmOfferSchemeFundIds(provider, order);

    const dialogRef = this.dialog.open(KiwiMonsterFundSelectionDialogComponent, {
      data: { CurrentPreferredFund: currentPreferredFund, OtherSelectedIds: otherSelectedQmOfferSchemeFundIds },
      width: '80%',
      disableClose: true,
      restoreFocus: false,
      autoFocus: false
    });

    dialogRef.afterClosed().subscribe((response: KiwiMonsterFund | null) => {
      let index = this.preferredFunds.findIndex(f => f.FundBaseTypeCode === provider && f.SelectOrder === order);

      if (index !== -1) { // Ensure the fund exists in the array
        if (response?.Id > 0) {
          this.preferredFunds[index].QmOfferSchemeFundId = response.Id;
          this.preferredFunds[index].FundName = response.FundName;
          this.preferredFunds[index].SchemeName = response.SchemeName;
        } else {
          this.preferredFunds[index] = new PreferredFund(provider, order); // Correctly update the array entry
        }
      }

      this.kiwiMonsterSettingService.setCurrentPreferredFunds(this.preferredFunds);

    });
  }

  save(): void {
    this.kiwiMonsterSettingService.showDinoLoading();
    let updatePreferredFunds = this.preferredFunds.filter(f => f.QmOfferSchemeFundId > 0);
    this.kiwiMonsterSettingService.updateKiwiMonsterUserPreferredFunds(updatePreferredFunds, (response) => {
      this.afterGetData(response, true);
      this.kiwiMonsterSettingService.closeDinoLoading();
    });
  }

  getSpecificFundName(provider: string, order: number): string {
    let fund = this.preferredFunds.find(f => f.FundBaseTypeCode === provider && f.SelectOrder === order);
    return fund.QmOfferSchemeFundId > 0 ? PreferredFund.getDisplayName(fund) : 'Select';
  }

  generateInitFunds(): PreferredFund[] {
    let initFunds: PreferredFund[] = [];
    for (let category of this.categories) {
      for (let index = 1; index < 4; index++) {
        let fund = new PreferredFund(category.Value.toString(), index);
        initFunds.push(fund);
      }
    }
    return initFunds;
  }

  fillInitFunds(response: PreferredFund[], initFunds: PreferredFund[]): PreferredFund[] {
    initFunds.forEach(initFund => {
      let matchingFund = response.find(
        resFund => resFund.FundBaseTypeCode === initFund.FundBaseTypeCode && resFund.SelectOrder === initFund.SelectOrder
      );
      if (matchingFund) {
        Object.assign(initFund, matchingFund);
      }
    });

    return initFunds;
  }

  getCurrentQmOfferSchemeFundId(provider: string, order: number): number {
    return this.preferredFunds.find(f => f.FundBaseTypeCode === provider && f.SelectOrder === order).QmOfferSchemeFundId;
  }

  getOtherSelectedQmOfferSchemeFundIds(provider: string, order: number): number[] {
    let otherSelectedQmOfferSchemeFundIds: number[] = [];
    let otherFunds = this.preferredFunds.filter(f => f.FundBaseTypeCode === provider && f.SelectOrder !== order);
    if (otherFunds.length > 0) {
      otherFunds.forEach(f => {
        otherSelectedQmOfferSchemeFundIds.push(f.QmOfferSchemeFundId);
      });
    }
    return otherSelectedQmOfferSchemeFundIds;
  }

  getButtonColor(provider: string, order: number): string {
    let fund = this.preferredFunds.find(f => f.FundBaseTypeCode === provider && f.SelectOrder === order);
    return fund?.QmOfferSchemeFundId > 0 ? 'qm-blue' : 'qm-gray';
  }

  isSameWithOriginal(provider: string, order: number): boolean {
    let currentFund = this.preferredFunds.find(f => f.FundBaseTypeCode === provider && f.SelectOrder === order);
    let originalFund = this.originalPreferredFunds.find(f => f.FundBaseTypeCode === provider && f.SelectOrder === order);

    return currentFund?.QmOfferSchemeFundId === originalFund?.QmOfferSchemeFundId;
  }

  getOriginalFundName(provider: string, order: number): string {
    let fund = this.originalPreferredFunds.find(f => f.FundBaseTypeCode === provider && f.SelectOrder === order);
    return fund?.QmOfferSchemeFundId > 0 ? PreferredFund.getDisplayName(fund) : 'Unset';
  }

  hasDifferences(originalFunds: PreferredFund[], currentPreferredFunds: PreferredFund[]): boolean {
    return originalFunds.some(originalFund => {
      const currentFund = currentPreferredFunds.find(
        f => f.FundBaseTypeCode === originalFund.FundBaseTypeCode &&
          f.SelectOrder === originalFund.SelectOrder
      );

      return currentFund?.QmOfferSchemeFundId !== originalFund.QmOfferSchemeFundId;
    });
  }

  canDeactivate(): Observable<boolean> {
    if (this.hasDifferences(this.originalPreferredFunds, this.preferredFunds)) {
      let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
      messageSetting.Title = 'Alert';
      messageSetting.Message = 'You have unsaved changes. Are you sure you want to leave?';
      messageSetting.NoBtnName = 'NO';

      return this.confirmDialog.confirm(messageSetting).pipe(
        map(response => response?.ReturnValue === true) // Extract boolean value
      );
    }
    return of(true); // If no changes, allow navigation
  }
}
