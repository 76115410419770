import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { PaUserToken } from 'src/app/models/access.token.models/user.token';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';

@Component({
  selector: 'terms-agreement-dialog',
  templateUrl: './terms-agreement-dialog.component.html',
  styleUrls: ['./terms-agreement-dialog.component.scss']
})
export class TermsAgreementDialogComponent extends ComponentBaseClass implements OnInit {
  isAgree: boolean = false;
  userToken: PaUserToken;
  hideSkipBtn: boolean = false;

  isPaLogin: boolean = false;
  paUserId: number = -1;
  constructor (
    public loginService: LoginService,
    public router: Router,
    public dialogRef: MatDialogRef<TermsAgreementDialogComponent>
  ) {
    super();
  }

  ngOnInit(): void {
    this.userToken = this.loginService.getUserToken();
    this.hideSkipBtn = this.userToken.SkipAgreeTermsCounter > 0;
    this.isPaLogin = this.loginService.isPaLogin();
    if (this.isPaLogin) {
      this.paUserId = this.loginService.getCurrentPaLoginUserId();
    }
    
    this.loginService.setAwaitingTermsAgreement(true);
  }

  confirmAgree(): void {
    if (this.isAgree) {
      this.showDinoLoading();
      if (this.isPaLogin) {
        this.loginService.updatePaUserTermsAgreement(this.isAgree, this.paUserId, (response) => {
          this.goToDashboard();
        });
      } else {
        this.loginService.updateUserTermsAgreement(this.isAgree, (response) => {
          this.goToDashboard();
        });
      }
    }
  }

  skip(): void {
    if (!this.hideSkipBtn) {
      this.showDinoLoading();
      if (this.isPaLogin) {
        this.loginService.deletePaUserTermsAgreement(this.paUserId, (response) => {
          this.goToDashboard();
        });
      } else {
        this.loginService.deleteUserTermsAgreement((response) => {
          this.goToDashboard();
        });
      }
    }
  }

  goToDashboard(): void {
    this.loginService.removeAwaitingTermsAgreements();
    this.dialogRef.close();
    // go to next page depend on platform setting
    let dashboardUrl = this.loginService.platformDashboard(this.loginService.getPlatformSetting());
    this.router.navigate([`/${ dashboardUrl }`]);
  }

  close(): void {
    this.loginService.logout();
    this.dialogRef.close();
  }

}
