import { Component, OnInit, isDevMode } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { QuoteStepCompare } from '../../service/quote/quote.step.compare';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { QuoteStepCompareCheckmonster } from 'src/app/service/quote/quote.step.compare.checkmonster';
import { AccessRole } from 'src/app/models/access.token.models/access.role';


@Component({
    // tslint:disable-next-line:component-selector
    selector: 'quote-compare',
    templateUrl: './quote-compare.component.html',
    styleUrls: ['./quote-compare.component.scss']
})
export class QuoteCompareComponent implements OnInit {

    currentPanel: number = 1;
    accessRole: AccessRole = new AccessRole();
    isUserAccessRole: boolean = false;
    isDevMode: boolean = false;

    constructor(
        public quoteStepCompare: QuoteStepCompare,
        public route: ActivatedRoute,
        public router: Router,
        public sharedFunction: SharedFunctionService,
        private confirmDialog: ConfirmMessageDialogService,
        public quoteStepCompareCheckmonster: QuoteStepCompareCheckmonster,
    ) {

    }


    ngOnInit() {
        this.quoteStepCompare.init();
        this.isUserAccessRole = (this.quoteStepCompare.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_AM)
          || this.quoteStepCompare.loginService.checkUserAccessRole(this.accessRole.USER_ROLE_QM));
        this.isDevMode = isDevMode();
    }


    goBack() {
        if (this.quoteStepCompare.currentQuote.IsQuoteWizard) {
            if (this.isUserAccessRole && this.quoteStepCompare.IsCheckmonsterRecommendSettings) {
                this.quoteStepCompare.loginService.doGoogleTracking("quote-wizard-compare", "goToCheckmonster", "");
                this.router.navigate(['/quote-wizard/checkmonster']);
            } else {
                this.quoteStepCompare.loginService.doGoogleTracking("quote-wizard-compare", "goToBenefit", "");
                this.router.navigate(['/quote-wizard/personal/benefit']);
            }
        } else if (this.quoteStepCompare.currentQuote.IsPolicyBasedQuote) {
            if (this.isUserAccessRole && this.quoteStepCompare.IsCheckmonsterRecommendSettings) {
                this.quoteStepCompare.loginService.doGoogleTracking("policy-based-quote", "goToCheckmonster", "");
                this.router.navigate(['/policy-based/checkmonster']);
            } else {
                this.quoteStepCompare.loginService.doGoogleTracking("policy-based-quote", "goToBenefit", "");
                this.router.navigate(['/policy-based/personal/benefit']);
            }
        } else {
            if (this.isUserAccessRole && this.quoteStepCompare.IsCheckmonsterRecommendSettings) {
                this.quoteStepCompare.loginService.doGoogleTracking("quote-compare", "goToCheckmonster", "");
                this.router.navigate(['/quote/checkmonster']);
            } else {
                this.quoteStepCompare.loginService.doGoogleTracking("quote-compare", "goToBenefit", "");
                this.router.navigate(['/quote/personal/benefit']);
            }
        }
    }

    goNext() {
        if (this.isUserAccessRole && this.quoteStepCompareCheckmonster.isCheckmonsterEnabled()) {
            let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
            messageSetting.Title = this.sharedFunction.getUiMessageByCode('Checkmonster-INFO-applyCheckmonsterTitle');
            messageSetting.Message = this.sharedFunction.getUiMessageByCode('Checkmonster-INFO-applyCheckmonster');
            messageSetting.NoBtnName = 'NO';
            this.quoteStepCompare.loginService.doGoogleTracking("quote-checkmonster", "checkmonsterConfirmationDialog", "");
            this.confirmDialog.confirm(messageSetting).subscribe((value) => this.confirmCheckmonsterRecommend(value.ReturnValue));  
        
        } else {
            if (this.quoteStepCompare.currentQuote.IsQuoteWizard) {
                this.router.navigate(['/quote-wizard/report']);
            } else if (this.quoteStepCompare.currentQuote.IsPolicyBasedQuote) {
                this.router.navigate(['/policy-based/report']);
            } else {
                this.router.navigate(['/quote/report']);
            }
        }
    }

    switchPanel(panelId: number) {
        if (this.currentPanel !== panelId) {
            this.currentPanel = panelId;
        }
    }

    confirmCheckmonsterRecommend(value) {
        if (value) {
            this.quoteStepCompareCheckmonster.confirmCheckmonsterQuote();
        } else {
            if (this.quoteStepCompare.currentQuote.IsQuoteWizard) {
                this.quoteStepCompare.loginService.doGoogleTracking("quote-wizard-compare-checkmonster", "goToReport", "");
                this.router.navigate(['/quote-wizard/report']);
            } else {
                this.quoteStepCompare.loginService.doGoogleTracking("quote-compare-checkmonster", "goToReport", "");
                this.router.navigate(['/quote/report']);
            }
        }
    }
    
    checkIsPricingChangeHistoryAvailable(): boolean {
        return this.quoteStepCompare.loginService.hasFeatureAccess(this.quoteStepCompare.quoteRequiredData.PricingChangeHistoryGroups);
    }


}

