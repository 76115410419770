import { Component, isDevMode, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { LoginService } from '../../service/login.service';
import { DataLoader } from '../../service/data.loader';
import { QuoteRequiredData } from '../../models/quote.required.data';
import { UserService } from '../../service/user.service';
import { ApiAddressService } from '../../service/api.address.service';
import { DataLoaderService } from '../../service/data.loader.service';
import { ComponentBaseClass } from '../../service/base';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { Observable } from 'rxjs';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { ConfirmMessageDialogService } from '../shared/confirm-message-dialog.service';
import { Router, NavigationStart } from '@angular/router';
import { UserDetail } from 'src/app/models/user.detail';
import { CanComponentDeactivate } from '../shared/can-deactivate-guard.service';
import { map, startWith } from 'rxjs/operators';
import { QuoteDataObj } from 'src/app/models/quote.data.obj';

@Component({
    // tslint:disable-next-line:component-selector
    selector: 'user-setting',
    templateUrl: './user-setting.component.html',
    styleUrls: ['./user-setting.component.scss']
})
export class UserSettingComponent extends ComponentBaseClass implements OnInit, CanComponentDeactivate {
 
    userGroupListOptions: Observable<QuoteDataObj[]>;
    regionListOptions: Observable<QuoteDataObj[]>;
 
    userDetailSettingForm: FormGroup;
    userDefaultLogo: string;
    dobMonths = [];
    dobYears = [];
    quoteRequiredData: QuoteRequiredData;
    userDetail: UserDetail;
    errorMessage: string = '';
    selectedFiles: FileList;
    showRemoveUserLogoAlert: boolean = false;
    showRemoveUserAvatarAlert: boolean = false;
    hasChange: boolean = false;
    newUrl: string = '';
    userDefaultAvatar: string;
    hideWhyIsNeeded: boolean = true;
    isDevMode: boolean = isDevMode();

    initialFormValue: any;

    constructor(
        public loginService: LoginService,
        private userDetailSettingFormBuilder: FormBuilder,
        public snackBar: MatSnackBar,
        private dataLoader: DataLoader,
        private userService: UserService,
        private apiAddressService: ApiAddressService,
        private dataLoaderService: DataLoaderService,
        public sharedFunction: SharedFunctionService,
        public dialog: MatDialog,
        private router: Router,
        public confirmDialog: ConfirmMessageDialogService
    ) {
        super();
    }






    ngOnInit() {
        this.loginService.checkLoginStatus();

        this.dobMonths = this.dataLoader.getMonths();
        this.dobYears = this.dataLoader.getDoBYears();

        // get required data
        this.quoteRequiredData = this.dataLoader.getRequiredData();
        // set user default logo
        this.userDefaultLogo = this.quoteRequiredData.UserDefaultLogo;
        // set user default avatar
        this.userDefaultAvatar = this.quoteRequiredData.UserDefaultAvatar;
        // get user detail
        this.userDetail = new UserDetail();
        this.selectedFiles = null;
        this.buildForm();
        this.getUserDetail();


        this.router.events.subscribe(event => {
            if (event instanceof NavigationStart) {
                this.newUrl = event.url;
            }
        });
        
        this.getSearchOptions();
    } 


    canDeactivate(): Observable<boolean> | boolean {
        if (this.loginService.isPaLogin()) {
            return true;
        } else {
            let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();

            messageSetting.Title = 'Alert';
            messageSetting.Message = this.sharedFunction.getUiMessageByCode('Share-INFO-SaveChanges');
            messageSetting.NoBtnName = 'NO';
            messageSetting.NewUrl = this.newUrl;

            let allowChange: boolean = true;

            if (this.hasChange) {
                allowChange = false;

                // clear change status
                this.hasChange = false;
                this.confirmDialog.confirm(messageSetting).subscribe((response) => {
                    if (response.ReturnValue === false) {
                        // go to next page
                        if (response.NewUrl) {
                            this.router.navigate([response.NewUrl]);
                        }
                    } else if (response.ReturnValue === true) {
                        // call save function then
                        this.doUpdateUserDetail(this.userDetailSettingForm.value, response.NewUrl);
                        this.loginService.doGoogleTracking('user-setting', 'updateDetail', '');
                    }
                });
            }

            return allowChange;
        }

    }



    getUserDetail(): void {
        this.showDinoLoading();
        this.userService.getUserDetail((response) => {
            if (response) {
                this.userDetail = response;
                // bind data to form
                if (this.userDetail.UserLogo) {
                    this.userDefaultLogo = this.userDetail.UserLogo;
                }


                this.userDetailSettingForm.setValue({
                    FirstName: this.userDetail.FirstName,
                    LastName: this.userDetail.LastName,
                    Title: this.userDetail.Title,
                    AdviserTypeIdV2: this.userDetail.AdviserTypeIdV2,
                    AdviserTypeValueV2: this.userDetail.AdviserTypeValueV2,
                    FAPName: this.userDetail.FAPName,
                    FSPNumberForFAP: this.userDetail.FSPNumberForFAP,
                    //Group: this.userDetail.GroupId,
                    DisputeResolutionService: this.userDetail.DisputesResolutionServiceId,
                    BusinessName: this.userDetail.BusinessName,
                    MobilePhone: this.userDetail.Phone,
                    AddressLine1: this.userDetail.Address1,
                    AddressLine2: this.userDetail.Address2,
                    AddressCity: this.userDetail.City,
                    AddressPostCode: this.userDetail.PostCode,
                    Region: this.userDetail.RegionId,
                    GroupSearchInput: '',
                    RegionSearchInput:''
                });

                this.initialFormValue = this.userDetailSettingForm.value;
                this.bindingUserDetailChangeMonitor();
            }

            this.closeDinoLoading();
        });

    }

    buildForm(): void {
        this.userDetailSettingForm = this.userDetailSettingFormBuilder.group({
            'FirstName': [null, Validators.required],
            'LastName': [null, Validators.required],
            'Title': [null],
            //'DobMonth': [null, Validators.required],
            //'DobYear': [null, Validators.required],
            'AdviserTypeIdV2': [null, Validators.required],
            'AdviserTypeValueV2': [null, Validators.required],
            'FAPName': [null, Validators.required],
            'FSPNumberForFAP': [null, Validators.required],
            // 'Group': [null, Validators.required],
            'DisputeResolutionService': [null, Validators.required],
            'BusinessName': [null],
            'MobilePhone': [null, Validators.required],
            'AddressLine1': [null, Validators.required],
            'AddressLine2': [null],
            'AddressCity': [null, Validators.required],
            'AddressPostCode': [null, Validators.required],
            'Region': [null, Validators.required],
            'GroupSearchInput': [null],
            'RegionSearchInput':[null]
        });
    }

    doUpdateUserDetail(formData, newUrl): void {


        this.userDetail = new UserDetail();
        this.userDetail.FirstName = formData.FirstName;
        this.userDetail.LastName = formData.LastName;
        this.userDetail.Title = formData.Title;
        //this.userDetail.Birthday = formData.DobMonth + ':' + formData.DobYear;
        this.userDetail.AdviserTypeIdV2 = formData.AdviserTypeIdV2;
        this.userDetail.AdviserTypeValueV2 = formData.AdviserTypeValueV2;
        this.userDetail.FAPName = formData.FAPName;
        this.userDetail.FSPNumberForFAP = formData.FSPNumberForFAP;
        this.userDetail.GroupId = this.quoteRequiredData.UserGroupList[0].Value;//formData.Group;
        this.userDetail.DisputesResolutionServiceId = formData.DisputeResolutionService;
        this.userDetail.BusinessName = formData.BusinessName;
        this.userDetail.Phone = formData.MobilePhone;
        this.userDetail.Address1 = formData.AddressLine1;
        this.userDetail.Address2 = formData.AddressLine2;
        this.userDetail.City = formData.AddressCity;
        this.userDetail.PostCode = formData.AddressPostCode;
        this.userDetail.RegionId = formData.Region;

        if (this.validateUserDetail()) {
            this.showDinoLoading();
            this.userService.updateUserDetail(this.userDetail, (response) => {
                if (response && response.Messages && response.Messages.length > 0) {
                    this.errorMessage = '';
                    for (let mes of response.Messages) {
                        this.errorMessage += mes.Message + '<br />';
                    }

                } else {
                    this.getUserDetail();
                    this.sharedFunction.openSnackBar('Share-SUCCESS-Updated', 'OK', 2000);
                    this.errorMessage = '';

                    if (newUrl) {
                        this.router.navigate([newUrl]);
                    }
                }
                this.closeDinoLoading();
            });
        } else {
            // someting wrong please double check your enter.
        }

    }

    validateUserDetail(): boolean {
        let pass: boolean = true;

        if (this.sharedFunction.isNullOrEmptyString(this.userDetail.FirstName)) {
            // this.errorMessage = 'Please enter first name';
            pass = false;
        } else if (this.sharedFunction.isNullOrEmptyString(this.userDetail.LastName)) {
            // this.errorMessage = 'Please enter last name';
            pass = false;
        }
        // else if (!this.userDetail.Birthday) {
        //     this.errorMessage = 'Please select Birth Date';
        //     pass = false;
        // }
        // else if (this.sharedFunction.isNullOrEmptyString(this.userDetail.Email)) {
        //     this.errorMessage = 'Please enter Login Email';
        //     pass = false;
        // }
        else if (this.userDetail.AdviserTypeIdV2 === null || this.userDetail.AdviserTypeIdV2 < 0) {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-SelectAdviserType');
            pass = false;
        }
        else if (this.sharedFunction.isNullOrEmptyString(this.userDetail.AdviserTypeValueV2)) {
            // this.errorMessage = 'Please select FSP or QFE Number';
            pass = false;
        }
        else if (this.sharedFunction.isNullOrEmptyString(this.userDetail.FAPName)) {
            // this.errorMessage = 'Please enter FAP name';
            pass = false;
        }
        else if (this.sharedFunction.isNullOrEmptyString(this.userDetail.FSPNumberForFAP)) {
            // this.errorMessage = 'Please enter FAP FSP Number';
            pass = false;
        }
        // else if (this.userDetail.GroupId === null || this.userDetail.GroupId < 0) {
        //     this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-SelectGroup');
        //     pass = false;
        // }
        // else if (this.userDetail.ProfessionalAssId === null || this.userDetail.ProfessionalAssId < 0) {
        //     this.errorMessage = 'Please select Professional Assoc';
        //     pass = false;
        // } 
        else if (this.sharedFunction.isNullOrEmptyString(this.userDetail.Phone)) {
            // this.errorMessage = 'Please enter Phone';
            pass = false;
        } else if (this.sharedFunction.isNullOrEmptyString(this.userDetail.Address1)) {
            // this.errorMessage = 'Please enter Address';
            pass = false;
        } else if (this.sharedFunction.isNullOrEmptyString(this.userDetail.City)) {
            // this.errorMessage = 'Please enter City';
            pass = false;
        } else if (this.sharedFunction.isNullOrEmptyString(this.userDetail.PostCode)) {
            // this.errorMessage = 'Please enter Postcode';
            pass = false;
        } else if (this.userDetail.RegionId === null || this.userDetail.RegionId < 0) {
            this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-SelectRegion');
            pass = false;
        } else {
            this.errorMessage = '';
        }



        return pass;
    }



    selectFile(event: any) {
        this.selectedFiles = event.target.files;
    }

    uploadLogo(): void {
        if (this.selectedFiles !== null && this.selectedFiles.length > 0) {
            this.showDinoLoading();
            this.dataLoaderService.uploadFile(
                this.selectedFiles[0],
                this.apiAddressService.updateUserLogoUrl(),
                (response) => {
                    if (response && response.MessageCode && response.MessageCode === 200) {
                        this.userDefaultLogo = response.Message;
                    }
                    this.closeDinoLoading();
                    this.selectedFiles = null;
                });
        }
    }

    removeUserLogo(): void {
        this.showDinoLoading();
        this.userService.removeUserLogo((response) => {
            if (response && response.MessageCode === 200) {
                this.userDefaultLogo = this.quoteRequiredData.UserDefaultLogo;
            }
            this.closeDinoLoading();
        });
    }


    showRemoveLogoBtn(): boolean {
        let value: boolean = false;

        if (this.userDefaultLogo && this.userDefaultLogo !== this.quoteRequiredData.UserDefaultLogo) {
            value = true;
        }

        return value;
    }

    bindingUserDetailChangeMonitor() {
        // register user detail setting change monitor
        this.hasChange = false;
        // const initialValue = this.userDetailSettingForm.value;
        this.userDetailSettingForm.valueChanges.subscribe((value) => {
            // if new value is different from initial vale, hasChange is true
            if (JSON.stringify(this.initialFormValue) !== JSON.stringify(value)) {
                this.hasChange = true;
            } else {
                this.hasChange = false;
            }
        });
    }
    
    getSearchOptions() {
        // this.userGroupListOptions = this.userDetailSettingForm.get('GroupSearchInput').valueChanges.pipe(
        //     startWith(''),
        //     map((value) => {
        //         return this.sharedFunction.mapSelectOptions(value, this.quoteRequiredData.UserGroupList, 'Name');
        //     })
        // );

        this.regionListOptions = this.userDetailSettingForm.get('RegionSearchInput').valueChanges.pipe(
            startWith(''),
            map((value) => {
                return this.sharedFunction.mapSelectOptions(value, this.quoteRequiredData.RegionList, 'Name');
            })
        );
    }



    // avatar part
    // uploadAvatar(): void {
    //     if (this.selectedFiles !== null && this.selectedFiles.length > 0) {
    //         this.showDinoLoading();
    //         this.dataLoaderService.uploadFile(
    //             this.selectedFiles[0],
    //             this.apiAddressService.updateUserAvatarUrl(),
    //             (response) => {
    //                 if (response && response.MessageCode && response.MessageCode === 200) {
    //                     this.userDefaultAvatar = response.Message;
    //                 }
    //                 this.closeDinoLoading();
    //                 this.selectedFiles = null;
    //             });
    //     }
    // }

    // removeUserAvatar(): void {
    //     this.showDinoLoading();
    //     this.userService.removeUserAvatar((response) => {
    //         if (response && response.MessageCode === 200) {
    //             this.userDefaultAvatar = this.quoteRequiredData.UserDefaultAvatar;
    //         }
    //         this.closeDinoLoading();
    //     });
    // }


    // showRemoveAvatarBtn(): boolean {
    //     let value: boolean = false;

    //     if (this.userDefaultAvatar && this.userDefaultAvatar !== this.quoteRequiredData.UserDefaultAvatar) {
    //         value = true;
    //     }

    //     return value;
    // }
}
