import { Component, OnInit, ViewChild, isDevMode } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginator } from '@angular/material/paginator';
import { MatTableDataSource } from '@angular/material/table';
import { Router } from '@angular/router';
import { KiwiMonsterQuote } from 'src/app/models/kiwimonster/kiwimonster-quote.model';
import { BusinessService } from 'src/app/service/business/business.service';
import { KiwiMonsterService } from 'src/app/service/kiwimonster/kiwimonster.service';
import { LoginService } from 'src/app/service/login.service';
import { QuoteService } from 'src/app/service/quote.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { AppReportsDialogComponent } from '../dashboard/app-reports-dialog.component';
import { BaseComponent } from '../shared/base-component';
import { ApiService } from 'src/app/service/api.service';

@Component({
  selector: 'kiwimonster-dashboard',
  templateUrl: './kiwimonster-dashboard.component.html',
  styleUrls: ['./kiwimonster-dashboard.component.scss']
})
export class KiwiMonsterDashboardComponent extends BaseComponent implements OnInit  {
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  displayedColumns = ['Name', 'When', 'Action'];
  dataSource: MatTableDataSource<KiwiMonsterQuote>;

  savedKiwiMonsterQuotes: KiwiMonsterQuote[] = [];
  selectedKiwiMonsterQuote: KiwiMonsterQuote = null;
  isDevMode: boolean = false;
  
  constructor (
    private kiwimonsterService: KiwiMonsterService,
    public loginService: LoginService,
    public sharedFunction: SharedFunctionService,
    public router: Router,
    private personalQuoteService: QuoteService,
    public businessService: BusinessService,
    public dialog: MatDialog,
    public apiService: ApiService
  ) {
    super(router, apiService);
   }

  ngOnInit(): void {
    this.isDevMode = isDevMode();

    // check access
    if (!this.checkServiceAccess('KM')) {
      return;
    }

    // make sure recent quote is kiwimonster quote
    this.personalQuoteService.removeCurrentQuote();
    this.businessService.removeCurrentBusinessRecord();
    this.kiwimonsterService.removeCurrentKMQuote();
    
    this.getKiwiMonsterQuotes();
  }

  getKiwiMonsterQuotes(): void {
    this.kiwimonsterService.showDinoLoading();
    this.kiwimonsterService.getKiwiMonsterQuotes((response: KiwiMonsterQuote[]) => {
      if (response) {
        // 1. get data
        this.savedKiwiMonsterQuotes = response;
        // 2. set Paginator
        this.setPaginator();
        // 3. bind table data source
        this.bindTableDataSource();
      }
      this.kiwimonsterService.closeDinoLoading();
    });
  }


  setPaginator(): void {
    this.paginator._intl.itemsPerPageLabel = "Clients Per Page";
    this.paginator.pageSize = 10;
    this.paginator.pageSizeOptions = [10, 25, 100];
    this.paginator.showFirstLastButtons = true;
    this.paginator._intl.getRangeLabel = (
      page: number,
      pageSize: number,
      length: number
    ) => {
      if (length === 0) {
        return `Page 1 / 1`;
      }
      const amountPages = Math.ceil(length / pageSize);
      return `Page ${ page + 1 } / ${ amountPages }`;
    };

    this.paginator._formFieldAppearance = "outline";
  }

  bindTableDataSource(): void {
    // 1. set dataSource
    this.dataSource = new MatTableDataSource(this.savedKiwiMonsterQuotes);
    // 2. bind paginator
    this.dataSource.paginator = this.paginator;
    // filter value
    this.dataSource.filterPredicate = (
      data: KiwiMonsterQuote,
      filter: string
    ): boolean => {
      return data.QuoteName?.toLowerCase().trim().indexOf(filter) >= 0 || data.QuoteName?.toLowerCase().trim().indexOf(filter) >= 0;
    };
  }

  applyFilter(filterValue: string) {
    filterValue = filterValue.toLowerCase().trim();
    this.dataSource.filter = filterValue;
  }

  openKiwiMonsterQuote(quote: KiwiMonsterQuote): void {
    this.kiwimonsterService.showDinoLoading();
    this.kiwimonsterService.getExistingKiwiMonsterQuote(quote.QuoteId, (response) => {
      if (response) {
        this.kiwimonsterService.setCurrentKMQuote(response);
        this.router.navigate(["/kiwimonster/quote/client"]);
      }
      this.kiwimonsterService.closeDinoLoading();
    });
  }

  showReports(quote: KiwiMonsterQuote): void {
    this.kiwimonsterService.showDinoLoading();
    this.kiwimonsterService.getKiwiMonsterReportList(quote.QuoteId, (response) => {
      if (response && response.length > 0) {
        // show popup window to display data
        this.dialog.open(AppReportsDialogComponent, {
          data: { reports: response, showCheckbox: false },
          maxWidth: "100vw",
          width: "800px",
          panelClass: "report-panel",
          disableClose: true,
          autoFocus: false,
          restoreFocus: false,
        });
      }
      this.kiwimonsterService.closeDinoLoading();
    });
  }

  viewKiwiMonsterQuote(quote: KiwiMonsterQuote): void {

  }

  deleteKiwiMonsterQuote(quote: KiwiMonsterQuote): void {
    this.kiwimonsterService.showDinoLoading();
    this.kiwimonsterService.deleteKiwiMonsterQuote(quote.QuoteId, (response) => {
      if (response) {
        this.savedKiwiMonsterQuotes = response;
        this.bindTableDataSource();
      };
      this.kiwimonsterService.closeDinoLoading();
    });
  }

  startNewKiwiMonsterQuote(): void {
    this.router.navigate(["/kiwimonster/quote/client"]);
  }
}
