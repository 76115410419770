import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { MfaMethod } from 'src/app/models/access.token.models/mfa-method.model';
import { GeneralMessageDialogSetting } from 'src/app/models/general-message-dialog-setting';
import { GeneralResponseMessage } from 'src/app/models/messages/general.response.message';
import { ComponentBaseClass } from 'src/app/service/base';
import { LoginService } from 'src/app/service/login.service';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { ConfirmMessageDialogService } from '../../shared/confirm-message-dialog.service';

@Component({
    selector: 'mfa-method-select',
    templateUrl: './mfa-method-select.component.html',
    styleUrls: ['./mfa-method-select.component.scss'],
})
export class MfaMethodSelectComponent extends ComponentBaseClass implements OnInit {
    allNotActivatedMfaMethods: MfaMethod[] = [];
    currentStep: number = 1;
    QRCode: string = '';
    errorMessage: string = '';
    selectedMfaMethod: string = '';

    constructor(
        public loginService: LoginService,
        private router: Router,
        private route: ActivatedRoute,
        public sharedFunction: SharedFunctionService,
        public sysConfig: SysConfigService,
        public confirmDialog: ConfirmMessageDialogService,
    ) {
        super();
    }

    ngOnInit(): void {
        this.getCurrentStep();
        
        if (this.loginService.getCurrentLoginUserName()) {
            if (this.currentStep === 1) {
                this.getAllNotActivatedMfaMethods();
            } else {
                this.addMfaMethod('TOTP');
            }
        } else {
        // if no user name then back to login.
            this.router.navigate(['/login']);
        }
        
        
    }

    getAllNotActivatedMfaMethods(): void {
        this.errorMessage = '';
        this.showDinoLoading();

        this.loginService.getAllMfaMethods((response) => {
            if (response && response.length > 0) {
                this.allNotActivatedMfaMethods = response.filter((item) => item.IsActivated === false);
                if (this.allNotActivatedMfaMethods.length > 0) {
                    // set default method is TOTP, if TOTP is activated, then set the first one to be the default method
                    if (this.allNotActivatedMfaMethods.filter((item) => item.MethodCode === 'TOTP').length > 0) {
                        this.selectedMfaMethod = 'TOTP';
                    } else {
                        this.selectedMfaMethod = this.allNotActivatedMfaMethods[0].MethodCode;
                    }
                }
            }
            // if all methods are activated, then show an error message
            if (this.allNotActivatedMfaMethods.length < 1) {
                this.errorMessage = this.sharedFunction.getUiMessageByCode('MfaMethodSelect-ERROR-NoAvailableMethod');
            }
        });
        this.closeDinoLoading();
    }

    addMfaMethod(methodCode: string): void {
        this.errorMessage = '';
        this.showDinoLoading();

        this.loginService.addMfaMethod(methodCode, (response) => {
            if (response && response.MessageCode === 200) {
                if (response.ExtValue === 'EMAIL') {
                    // 1. do google tracking
                    this.loginService.doGoogleTracking('MFA', 'add Email method', '');
                    // 2. set message that should be showed in v code verify page
                    this.loginService.setLoginMfaInfo(response, this.loginService.getCurrentLoginUserId());
                    // 3. go to v code verify page
                    this.goCheckMfaVCode('EMAIL');
                } else if (response.ExtValue === 'TOTP' && response.Message.indexOf('data:image/png;base64') >= 0) {
                    // 1. do google tracking
                    this.loginService.doGoogleTracking('MFA', 'add TOTP method', '');
                    // 2. set QR code
                    this.QRCode = response.Message;
                    // 3. set message that should be showed in v code verify page
                    let mfaInfo = new GeneralResponseMessage();
                    mfaInfo.Message = this.sharedFunction.getUiMessageByCode('VerifyDevice-INFO-EnterVCode');
                    mfaInfo.ExtValue = 'TOTP'
                    this.loginService.setLoginMfaInfo(mfaInfo, this.loginService.getCurrentLoginUserId());
                }
            } else if (response && response.MessageCode !== 200) {
                this.errorMessage = response.Message;
                this.currentStep = 1;
            } else {
                this.errorMessage = this.sharedFunction.getUiMessageByCode('Share-ERROR-Failed');
                this.currentStep = 1;
            }
        });
        this.closeDinoLoading();
    }

    goCheckMfaVCode(mfaMethodCode: string): void {
        this.router.navigate(['/verify-device'], { queryParams: { methodcode: mfaMethodCode } });
    }

    setSelectedMfaMethod(mfaMethodCode: string) {
        if (this.selectedMfaMethod === mfaMethodCode) {
            this.selectedMfaMethod = '';
        } else {
            this.selectedMfaMethod = mfaMethodCode;
        }
    }
    
    // only for standard user login
    skipAddingMFA(): void {
        this.loginService.clearLoginMfaInfo();
        let isAgreeTerms = this.loginService.getUserToken().AgreeTerms;
        if (!isAgreeTerms) {
            //  go to terms agreement dialog
            this.loginService.openTermsAgreementDialog();
        } else {
            // go to next page depend on platform setting
            let dashboardUrl = this.loginService.platformDashboard(this.loginService.getPlatformSetting());
            this.router.navigate([`/${ dashboardUrl }`]);
            this.loginService.doGoogleTracking('MFA', 'Confirm-SKIP-MFA', 'YES');
        }
    }
    
    confirmSkip(){
        let messageSetting: GeneralMessageDialogSetting = new GeneralMessageDialogSetting();
        messageSetting.Title = 'Alert';
        messageSetting.Message = this.sharedFunction.getUiMessageByCode(
            'MfaMethodSelect-WARNING-ConfirmSkip'
        );
        messageSetting.NoBtnName = 'NO';
        this.confirmDialog.confirm(messageSetting).subscribe((response) => {
            if (response.ReturnValue === true) {
                this.skipAddingMFA();
            }
        });

        this.loginService.doGoogleTracking('MFA', 'SKIP-MFA', 'Btn-Click');
    }
    
    getCurrentStep(): void {
        this.route.queryParams.subscribe((params) => {
            if (params && params.step) {
                this.currentStep = Number(params.step);
            } else {
                this.currentStep = 1;
            }
        });
    }
    
    backOrNextToPage(step: number): void {
        if (this.selectedMfaMethod !== 'EMAIL') {
            this.router.navigate(['/mfa-methods-select'], { queryParams: { step: step } });
            if (step === 1) {
                this.getAllNotActivatedMfaMethods();
            } 
        }
    }
    
}
