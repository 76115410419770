<div class="content-wrapper bg-white p-3 p-sm-5">
  <div mat-dialog-title>
    <h3 class='text-center'>
      While You Were at the Beach, Our Lawyers Were Updating the Fine Print!
    </h3>
  </div>
  <div mat-dialog-content>
    <ul class="list-group border-none">
      <li class="list-group-item">
        <p>
          We’ve updated our <a href="/TermsOfUse.html" target="_blank" class="text-blue"
             (click)='loginService.doGoogleTracking("termsAgreement", "go to Terms of Use & Privacy Policy", "");'>
            <strong>Terms of Use & Privacy Policy</strong>
          </a> to keep things fresh, legal, and slightly more interesting than watching paint dry.
        </p>
        <p>
          What's changed? Some boring but important updates about, <strong>Kiwimonster</strong>, <strong>data protection
            and slick new tools like API’s</strong>—because the future is coming, and our policies need to keep up!
        </p>
        <p>
          Before you continue, please read the new terms: <a href="/TermsOfUse.html" target="_blank" class="text-blue"
             (click)='loginService.doGoogleTracking("termsAgreement", "go to Terms of Use & Privacy Policy", "");'>
            <strong>Terms of Use & Privacy Policy</strong></a>
        </p>
         <p>
          <mat-checkbox [(ngModel)]="isAgree">
            I agree the &nbsp;
          </mat-checkbox>
          <a href="/TermsOfUse.html" target="_blank" rel="noopener" class="text-blue"
             (click)='loginService.doGoogleTracking("termsAgreement", "go to Terms of Use & Privacy Policy", "");'>
            <strong>Terms of Use & Privacy Policy</strong>
          </a>
        </p>
      </li>
      <li class="list-group-item text-right">
        <button type="button" class="btn btn-link mr-2 text-danger align-bottom" *ngIf="!hideSkipBtn"
                (click)='skip();loginService.doGoogleTracking("termsAgreement", "skip agree terms", "");'>
          Skip one time
        </button>
        <button mat-flat-button color="qm-blue" class='qm-flat-btn-lg w-sm-100'
                (click)='confirmAgree();loginService.doGoogleTracking("termsAgreement", "agree terms", "");'
                [disabled]="!isAgree">
          Agree & Continue
        </button>
      </li>
    </ul>
  </div>
  <!-- <mat-dialog-actions class="d-inline">
    <button mat-mini-fab color="qm-darkGray" (click)='close();loginService.doGoogleTracking("termsAgreement", "close termsAgreement dialog and logout", "");'>
      <span class="material-icons" aria-label="delete">clear</span>
    </button>
  </mat-dialog-actions> -->
</div>