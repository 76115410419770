import { Component, OnInit, Input, isDevMode } from "@angular/core";
import { QuoteService } from "../../service/quote.service";
import { LoginService } from "../../service/login.service";
import { CurrentQuote } from "../../models/current.quote";
import { Router } from "@angular/router";
import { MatSidenav } from "@angular/material/sidenav";
import { UserMenuService } from "src/app/service/user.menu.service";
import { UserMenuGroup } from "src/app/models/user.menu.group.item.model";
import { UserService } from "src/app/service/user.service";
import { NavMenuComponent } from "src/app/components/navmenu/navmenu.component";
import { UserDetail } from "src/app/models/user.detail";
import { QmLicense } from "src/app/models/qm.license.models/qm.license";
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { AccessRole } from 'src/app/models/access.token.models/access.role';
import { ApiService } from 'src/app/service/api.service';
import { MatDialog } from '@angular/material/dialog';
import { NZBNSearchDialogComponent } from 'src/app/components/business/nzbn-search-dialog/nzbn-search-dialog.component';

@Component({
  selector: "app-side-menu",
  templateUrl: "./side-menu.component.html",
  styleUrls: ["./side-menu.component.scss"],
})
export class SideMenuComponent extends NavMenuComponent implements OnInit {

  @Input() qmSideMenu: MatSidenav;

  isLogined: boolean = false;
  userMenuGroups: UserMenuGroup[];
  isDevMode: boolean = false;
  accessRole: AccessRole = new AccessRole();
  isKiwiMonsterAvailable: boolean = false;
  currentPlatformSetting: number = 1;
  constructor(
    public router: Router,
    public loginService: LoginService,
    public userService: UserService,
    public userMenuService: UserMenuService,
    private quoteService: QuoteService,
    public sharedFunction: SharedFunctionService,
    private apiService: ApiService,
    private dialog: MatDialog,
  ) {
    super(router, loginService, userService, userMenuService);
  }


  ngOnInit(): void {
    this.isDevMode = isDevMode();
    this.userMenuGroups = [];

    this.isLogined = this.loginService.isLogin();

    this.userDetail = new UserDetail();
    this.userDetail.FirstName = '';
    this.userDetail.UserLogo = '';

    this.currentLicense = new QmLicense();
    this.currentLicense.Name = 'FREE';
    this.loadUserInfo();
    
    this.currentPlatformSetting = this.loginService.getPlatformSetting();
  }

  loadUserMenu() {
    this.userMenuGroups = this.userMenuService.getUserMenuGroups([
      "PQUOTESETTINGS",
      "BUSINESSSETTINGS",
      "RESEARCHTOOLS",
      "ADVISERRESOURCES",
      "RESEARCHRESOURCES"
    ],true);

    this.updateTimestampForPdf();
    this.handleUserMenuGroups(this.userMenuGroups);
  }


  startPersonalQuote() {
    if (this.currentPlatformSetting === 1) {
      this.quoteService.saveCurrentQuote(new CurrentQuote());
      this.router.navigate(["/quote/client"]);
      this.loginService.doGoogleTracking("side-menu", "newCrunching", "");
    } else {
      this.router.navigate(["/dashboard"]);
      this.loginService.doGoogleTracking("side-menu", "go to personal dashboard", "");
    }
  }
    
  startQuoteWizard() {
    let newQuoteWizard = new CurrentQuote();
    newQuoteWizard.IsQuoteWizard = true;
    this.quoteService.saveCurrentQuote(newQuoteWizard);
    this.router.navigate(['/quote-wizard/client-overview']);
  }
  
  startStandaloneH2H(): void {
    this.router.navigate(['/research/tools/standalone-head-to-head']);
  }

  startBusinessInsurance(): void {
    if (this.currentPlatformSetting === 2) {
      this.dialog.open(NZBNSearchDialogComponent, {
        width: '80%',
        maxWidth: '1200px',
        disableClose: true,
        restoreFocus: false,
        autoFocus: false
      });
      this.loginService.doGoogleTracking("side-menu", "do business insurance", "")
    } else {
      this.router.navigate(['/business/dashboard']);
      this.loginService.doGoogleTracking("side-menu", "go to business insurance dashboard", "")
    }
  }
    
  startKiwiMonster(): void {
    if (this.currentPlatformSetting === 3) {
      this.router.navigate(["/kiwimonster/quote/client"]);
      this.loginService.doGoogleTracking("side-menu", "do kiwiMonster quote", "")
    } else {
      this.router.navigate(['/kiwimonster/dashboard']);
      this.loginService.doGoogleTracking("side-menu", "go kiwiMonster dashboard", "")
    }
  }
    
  updateTimestampForPdf(): void {
    this.userMenuGroups.forEach(group => {
      group.Items.forEach(item => {
        item.ItemActionLink[0] = this.userMenuService.addTimestampForPdfFile(item.ItemActionLink[0]);
      });
    });
  }
  
  handleUserMenuGroups(userMenuGroups: UserMenuGroup[]): void {
    let order = ["PQUOTESETTINGS", "BUSINESSSETTINGS", "RESEARCHTOOLS", "ADVISERRESOURCES", "RESEARCHRESOURCES"];
    userMenuGroups.sort((a, b) => order.indexOf(a.Code) - order.indexOf(b.Code));

    userMenuGroups.forEach(group => {
      if (group.Code === "BUSINESSSETTINGS") {
        group.Items = group.Items.filter(item => item.ItemName !== "SOA Customisation");
      }
    });
  }
  
  updateUserMenuGroups(): UserMenuGroup[] {
    if (this.currentPlatformSetting !== this.loginService.getPlatformSetting()) {
      setTimeout(() => {
        this.currentPlatformSetting = this.loginService.getPlatformSetting();
        this.loadUserMenu();
      });
    }
    return this.userMenuGroups;
  }
  
}
