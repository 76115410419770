import { AfterViewInit, Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { NgForm } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { QuoteReportDialogComponent } from 'src/app/components/quote/quote-report-dialog.component';
import { ConfirmMessageDialogService } from 'src/app/components/shared/confirm-message-dialog.service';
import { StandaloneHead2Head } from 'src/app/models/standalone-head2head/standalone-head2head.model';
import { QuoteStepCompareH2H } from 'src/app/service/quote/quote.step.compare.h2h';
import { SharedFunctionService } from 'src/app/service/shared.function.service';
import { SysConfigService } from 'src/app/service/sys.config';
import { UserService } from 'src/app/service/user.service';
import { LegacyProductLine } from 'src/app/models/standalone-head2head/legacy-product-line.model';
import { StandaloneHead2HeadService } from 'src/app/service/standalone-head-to-head.service';
import { LegacyCompany } from 'src/app/models/standalone-head2head/legacy-company.model';
import { LoginService } from 'src/app/service/login.service';
import { LegacyProduct } from 'src/app/models/standalone-head2head/legacy-product.model';
import { LegacyDocVer } from 'src/app/models/standalone-head2head/legacy-doc-ver.model';
import { StandaloneHead2HeadResult } from 'src/app/models/standalone-head2head/standalone-head2head-result.model';
import { LegacyProductType } from 'src/app/models/standalone-head2head/legacy-product-type.model';
import { ComponentBaseClass } from 'src/app/service/base';
import { DataLoader } from 'src/app/service/data.loader';
import { QuoteRequiredData } from 'src/app/models/quote.required.data';
import { UserMenuService } from 'src/app/service/user.menu.service';

@Component({
    selector: 'standalone-head-to-head',
    templateUrl: './standalone-head-to-head.component.html',
    styleUrls: ['./standalone-head-to-head.component.scss'],
})
export class StandaloneHeadToHeadComponent extends ComponentBaseClass implements OnInit, AfterViewInit {
    @ViewChild('standaloneH2HForm') standaloneH2HForm: NgForm;
    @ViewChild('benefitField', { read: ElementRef }) benefitField: ElementRef;
    
    ageList: number[];
    productLineOptions: LegacyProductLine[] = [];
    companyOptions: LegacyCompany[] = [];
    productLeftOptions: LegacyProduct[] = [];
    productRightOptions: LegacyProduct[] = [];
    versionLeftOptions: LegacyDocVer[] = [];
    versionRightOptions: LegacyDocVer[] = [];

    
    standaloneH2HObj: StandaloneHead2Head = new StandaloneHead2Head();
    selectedBenefit: LegacyProductLine = null;

    selectedLeftCompany: LegacyCompany = null;
    selectedRightCompany: LegacyCompany = null;
    
    selectedLeftProduct: LegacyProduct = null;
    selectedRightProduct: LegacyProduct = null;
    
    selectedLeftVersion: LegacyDocVer= null;
    selectedRightVersion: LegacyDocVer = null;

    isLifeSelected: boolean = false;
    isIncomeProtectionSelected: boolean = false;

    errorMsgs: string[] = [];
    standaloneHeadToHeadResult: StandaloneHead2HeadResult = null;

    quoteRequiredData: QuoteRequiredData;
    InsurerPassBacksAndPolicyTermsUrl: string = '';
    constructor (
        public standaloneH2HService: StandaloneHead2HeadService,
        public loginService: LoginService,
        public sharedFunction: SharedFunctionService,
        public dialog: MatDialog,
        public sysConfig: SysConfigService,
        public confirmDialog: ConfirmMessageDialogService,
        public userService: UserService,
        public quoteStepCompareH2H: QuoteStepCompareH2H,
        public dataLoader: DataLoader,
        public userMenuService: UserMenuService
    ) {
        super();
    }

    ngOnInit(): void {
        this.createAgeList();
        this.getH2HProductLines();
        this.quoteRequiredData = this.dataLoader.getRequiredData();
        // get Insurer Pass Backs and Policy Terms link from requiredData.json
        this.InsurerPassBacksAndPolicyTermsUrl = this.userMenuService.getUserMenuGroups(['RESEARCHTOOLS'])[0].Items.find(item => item.ItemName === 'Insurer Pass Backs and Policy Terms').ItemActionLink[0];
        // add timestamp
        this.InsurerPassBacksAndPolicyTermsUrl = this.userMenuService.addTimestampForPdfFile(this.InsurerPassBacksAndPolicyTermsUrl);
    }

    ngAfterViewInit(): void {
        this.standaloneH2HForm.valueChanges.subscribe(() => {
            this.clearErrorAndH2HResult();
        });
    }
    
    // get product lines options data
    getH2HProductLines(): void {
        this.standaloneH2HService.showDinoLoading();
        this.standaloneH2HService.getStandaloneH2HProductLines((response: LegacyProductLine[]) => {
            if (response) {
                this.productLineOptions = response;
                // remove Wellness benefit
                let index = 0;
                for(let b of this.productLineOptions){
                    if(b.ProdLineCode == 'WE'){
                        break;
                    }
                    index++;
                }
                this.productLineOptions.splice(index, 1);
            }
            this.standaloneH2HService.closeDinoLoading();
        })
    }
    
    // do legacy search when product type/ product line changing to get companies and products option list data
    doLegacyQprSearch(): void {
        this.errorMsgs = [];
        let productTypeCode: string = this.standaloneH2HObj.ProductType || '';
        let productLineCode: string = this.selectedBenefit?.ProdLineCode || '';
        if (productTypeCode && productLineCode) {
            this.showDinoLoading();
            this.standaloneH2HService.callLegacyQprSearch(productTypeCode, productLineCode, (response: LegacyCompany[]) => {
                if (response) {
                    // create companyOptions
                    this.createCompanyOptions(response);

                    if (this.companyOptions.length > 0) {
                        
                        // set product full name and add live version
                        this.addProductFullNameAndLiveVersion(this.companyOptions);
                        
                        // set selectedCompany value
                        this.selectedLeftCompany = this.selectedRightCompany = this.companyOptions[0];
                        
                        // create productOptions
                        this.productLeftOptions = this.selectedLeftCompany.ProductTypes[0].ProductLines[0].Products;
                        this.productRightOptions = this.selectedRightCompany.ProductTypes[0].ProductLines[0].Products;
                        
                        // set selectedProduct
                        this.selectedLeftProduct = this.productLeftOptions[0];
                        this.selectedRightProduct = this.productRightOptions[0];

                        // create version options
                        this.versionLeftOptions = this.selectedLeftProduct.DocumentVersions;
                        this.versionRightOptions = this.selectedRightProduct.DocumentVersions;
                        
                        // set selectedVersion
                        this.selectedLeftVersion = this.versionLeftOptions[0];
                        this.selectedRightVersion = this.versionRightOptions[0];
                    } else {
                        this.selectedBenefit = null;
                        this.benefitField.nativeElement.blur();
                        this.selectedLeftCompany = this.selectedRightCompany = null;
                        this.selectedLeftProduct = this.selectedRightProduct = null;
                        this.selectedLeftVersion = this.selectedRightVersion = null;
                        this.productLeftOptions = this.productRightOptions = [];
                        this.versionLeftOptions = this.versionRightOptions = [];
                        this.errorMsgs.push(this.sharedFunction.getUiMessageByCode('StandaloneHeadToHead-ERROR-NoData'));
                    }
                }
                this.closeDinoLoading();
            });
        } 
    }

    // age is 1 - 99
    createAgeList(): void {
        this.ageList = [];
        for (let i = 1; i < 100; i++) {
            this.ageList.push(i);
        }
    }
    
    createCompanyOptions(companies : LegacyCompany[]): void {
        this.companyOptions = [];

        for(let company of companies){
            if(company.ProductTypes && company.ProductTypes.length > 0){
                for(let productType of company.ProductTypes){
                    if(productType.ProductLines && productType.ProductLines.length > 0){
                        for(let productLine of productType.ProductLines){
                            // remove product if do not have any doc version and current rating
                            if(productLine.Products && productLine.Products.length > 0){
                                productLine.Products = productLine.Products.filter((product: LegacyProduct) => {
                                    return product.NumberOfDocVersions > 0 || product.HasCurrentQprRating == true;
                                });
                            } // end remove product
                        }// end loop product line
                        // remove empty product line
                        productType.ProductLines = productType.ProductLines.filter((prodLine:LegacyProductLine) => {
                            return prodLine.Products && prodLine.Products.length > 0;
                        });
                    }// end remove product line
                }
                // remove empty product type
                company.ProductTypes = company.ProductTypes.filter((prodType: LegacyProductType) => {
                    return prodType.ProductLines && prodType.ProductLines.length > 0;
                });
            }// end remove empty product type
        }

        // remove empty company
        companies = companies.filter((com: LegacyCompany) => {
            return com.ProductTypes && com.ProductTypes.length > 0;
        }); // remove empty company end


        if(companies && companies.length > 0){
            this.companyOptions = companies;
        }
    }
    


    addProductFullNameAndLiveVersion(companies: LegacyCompany[]): void {
        for (let company of companies) {
            
            let products: LegacyProduct[] = company.ProductTypes[0].ProductLines[0].Products;
            
            if (products.length > 0) {
                // create Live version
                let liveVersion: LegacyDocVer = LegacyDocVer.createLiveVersion();
                
                products.forEach((legacyProduct) => {
                    // add product full name
                    LegacyProduct.createFullProductName(legacyProduct);

                    if(legacyProduct.HasCurrentQprRating){
                        // add live version
                        legacyProduct.DocumentVersions.unshift(liveVersion);
                    }
                    
                    
                    // add version display name and date string
                    legacyProduct.DocumentVersions.forEach((version) => {
                        LegacyDocVer.createDisplayNameAndDate(version);
                    })
                });
            }
        }
    }
    
    // when selectedCompany is changed, update selectedProduct and selectedVersion
    updateProduct(rightLeftCompany: string): void {
        if (rightLeftCompany === 'left') {
            this.productLeftOptions = this.selectedLeftCompany.ProductTypes[0].ProductLines[0].Products;
            this.selectedLeftProduct = this.productLeftOptions[0];
            this.updateVersion('left');
        } else {
            this.productRightOptions = this.selectedRightCompany.ProductTypes[0].ProductLines[0].Products;
            this.selectedRightProduct = this.productRightOptions[0];
            this.updateVersion('right');
        }
    }
    
    // when selectedProduct is changed, update selectedVersion
    updateVersion(rightLeftProduct: string): void {
        if (rightLeftProduct === 'left') {
            this.versionLeftOptions = this.selectedLeftProduct.DocumentVersions;
            this.selectedLeftVersion = this.versionLeftOptions[0];
        } else {
            this.versionRightOptions = this.selectedRightProduct.DocumentVersions;
            this.selectedRightVersion = this.versionRightOptions[0];
        }
    }
    
    // for productLine/benefit select field
    doCompareWith(value1: any, value2: any): boolean {
        if (value1 && value2) {
            return value1.ProdLineCode === value2.ProdLineCode;

        } else {
            return false;
        }
    }
    
    checkSelectedBenefit(): void {
        // if benefit is life, display life cover amount input and future insurability checkbox
        if (this.selectedBenefit?.ProdLineCode === 'LI') {
            this.isLifeSelected = true;
            this.standaloneH2HObj.CoverAmount = 50000;
            this.standaloneH2HObj.IsFutureInsurability = false;
        } else {
            this.isLifeSelected = false;
            this.standaloneH2HObj.CoverAmount = null;
            this.standaloneH2HObj.IsFutureInsurability = null;
        }

        // if benefit is income protection, display benefit period select options
        if (this.selectedBenefit?.ProdLineCode === 'IP') {
            this.isIncomeProtectionSelected = true;
            // 1 : 1 Year; 2 : 2 Years; 5 : 5 Years; 60 : To Age 60; 65 : To Age 65; 70 : To Age 70
            this.standaloneH2HObj.BenefitPeriod = 65;
        } else {
            this.isIncomeProtectionSelected = false;
            this.standaloneH2HObj.BenefitPeriod = null;
        }
    }

    doStandaloneCompare() {
        this.errorMsgs = [];
        this.standaloneHeadToHeadResult = null;
        if (this.checkIsDifferentProductVersion()) {
            this.bindStandaloneH2HObjData();
            this.standaloneH2HService.showDinoLoading();
            this.standaloneH2HService.getStandaloneH2H(this.standaloneH2HObj, (response:StandaloneHead2HeadResult) => {
                if (response) {
                    // response with error message and the code is not 200
                    if (response.ErrorMessage && response.ErrorMessage.MessageCode !== 200) {
                        this.errorMsgs.push(response.ErrorMessage.Message);
                    } else if (response.LeftTotalScore > 1 && response.RightTotalScore > 1) {
                        // has correct head to head result, bind data to quoteStepCompareH2H
                        this.standaloneHeadToHeadResult = response;
                        this.quoteStepCompareH2H.headToHeadResult = this.standaloneHeadToHeadResult;
                        
                        this.standaloneHeadToHeadResult.hasValidLeftLegacyDocument = StandaloneHead2HeadResult.isValidLeftLegacyDocumentAvailable(this.standaloneHeadToHeadResult);
                        this.standaloneHeadToHeadResult.hasValidRightLegacyDocument = StandaloneHead2HeadResult.isValidRightLegacyDocumentAvailable(this.standaloneHeadToHeadResult);
                    } else {
                        // has head to head result but score is less than 1
                        if (response.LeftTotalScore < 1) {
                            this.errorMsgs.push(
                                this.selectedLeftCompany.Name +
                                ' does not have a QPR rating for ' +
                                this.selectedBenefit.ProdLineDesc + '. Please try different search conditions, such as Class and Benefit Period.'
                            );
                        }
                        if (response.RightTotalScore < 1) {
                            this.errorMsgs.push(
                                this.selectedRightCompany.Name +
                                ' does not have a QPR rating for ' +
                                this.selectedBenefit.ProdLineDesc + '. Please try different search conditions, such as Class and Benefit Period.'
                            );
                        }
                    }
                } else {
                    // no response, other error
                    this.errorMsgs.push(
                        this.sharedFunction.getUiMessageByCode('QuoteStepCompareH2H-ERROR-NotAvailable')
                    );
                }
                this.standaloneH2HService.closeDinoLoading();
            })
        }
    }

    // do not compare if same product and same version
    checkIsDifferentProductVersion(): boolean {
        if (this.selectedLeftProduct && this.selectedRightProduct && this.selectedLeftVersion && this.selectedRightVersion) {
            if (
                this.selectedLeftProduct.QprProvProdId === this.selectedRightProduct.QprProvProdId &&
                this.selectedLeftProduct.LegacyQprProvProdId === this.selectedRightProduct.LegacyQprProvProdId &&
                this.selectedLeftVersion.Id === this.selectedRightVersion.Id
            ) {
                this.errorMsgs.push(
                    this.sharedFunction.getUiMessageByCode('StandaloneHeadToHead-ERROR-SameProductVersion')
                );
                return false;
            } else {
                return true;
            }
        } else {
            return false;
        }
    }

    // bind data before sending request
    bindStandaloneH2HObjData(): void {
        this.standaloneH2HObj.LeftQprProvProdId = this.selectedLeftProduct.QprProvProdId;
        this.standaloneH2HObj.LeftLegacyQprProvProdId = this.selectedLeftProduct.LegacyQprProvProdId;
        this.standaloneH2HObj.LeftProviderProductDocumentVersionId = this.selectedLeftVersion.Id;
        
        this.standaloneH2HObj.RightQprProvProdId = this.selectedRightProduct.QprProvProdId;
        this.standaloneH2HObj.RightLegacyQprProvProdId = this.selectedRightProduct.LegacyQprProvProdId;
        this.standaloneH2HObj.RightProviderProductDocumentVersionId = this.selectedRightVersion.Id;
    }
    
    clearErrorAndH2HResult(): void {
        // when form value changes, remove error messages
        if (this.errorMsgs.length > 0) {
            this.errorMsgs = [];
        }

        // when form value changes, remove head to head result
        if (this.standaloneHeadToHeadResult) {
            this.standaloneHeadToHeadResult = null;
        }
    }
    
    // open download standalone head to head report dialog. 
    printStandaloneHeadToHeadReport() {
        this.standaloneH2HService.showDinoLoading();
        this.standaloneH2HService.getStandaloneH2HReport(this.standaloneH2HObj, (response) => {
            if (response) {
                this.dialog.open(QuoteReportDialogComponent, {
                    data: response,
                    maxHeight: '90%',
                    maxWidth: '1140px',
                    width: '80vw',
                    disableClose: true,
                    autoFocus: false,
                    restoreFocus: false,
                });
                this.quoteStepCompareH2H.closeDinoLoading();
            }
        });
    }
    

    canDisplayThisBenefit(benefit: LegacyProductLine): boolean{
        let canBeDisplayed = true;

        if(this.standaloneH2HObj.ProductType == 'B'){
            if(!this.quoteRequiredData.AvailableBusinessProductLineCodes.includes(benefit.ProdLineCode.toUpperCase())){
                canBeDisplayed = false;
            }   
        }

        return canBeDisplayed;
    }

}
