import { Component, OnInit, Input, isDevMode } from '@angular/core';
import { Router, Event as NavigationEvent, NavigationEnd } from '@angular/router';
import { LoginService } from '../../service/login.service';
import { UserService } from '../../service/user.service';
import { UserDetail } from 'src/app/models/user.detail';
import { QmLicense } from 'src/app/models/qm.license.models/qm.license';
import { UserMenuService } from 'src/app/service/user.menu.service';
import { UserMenuGroup } from 'src/app/models/user.menu.group.item.model';

@Component({
  // tslint:disable-next-line:component-selector
  selector: "nav-menu",
  templateUrl: "./navmenu.component.html",
  styleUrls: ["./navmenu.component.scss"],
})
export class NavMenuComponent implements OnInit {
  @Input() pathName: string;
  @Input() isOauth: boolean;

  collapse: string = "collapse";
  showCollapseNav: boolean = false;

  unreadNotice: number = 0;
  userDetail: UserDetail;
  currentLicense: QmLicense;

  userMenuGroupsForQuoteAndSoa: UserMenuGroup[];
  userMenuGroupsForUserSetting: UserMenuGroup[];

  unreadNoticeInterval: any = null;

  // for dashboard shepherd
  subsLinks: string[] = ['Existing Licenses', 'Saved Payment Methods', 'Invoices'];
  userStgLinks: string[] = ['Adviser Detail', 'Adviser Profile'];

  isDevMode: boolean = false;
  
  currentPlatformSetting: number = 1;
  
  isKiwiMonsterDomain: boolean = false;
  constructor(
    public router: Router,
    public loginService: LoginService,
    public userService: UserService,
    public userMenuService: UserMenuService
  ) {

    this.userDetail = new UserDetail();
    this.userDetail.FirstName = "";
    this.userDetail.UserLogo = "";

    this.currentLicense = new QmLicense();
    this.currentLicense.Name = "FREE";

    this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationEnd) {
            // reload user info when page has been changed
            this.loadUserData();
            this.checkDomain();
            this.checkIsAwaitingTermsAgreement();
          }
        });
  }

  ngOnInit() {
    this.showCollapseNav = false;

    this.loadUserData();
    this.isDevMode = isDevMode();
    this.checkDomain();
    this.checkIsAwaitingTermsAgreement();
  }


  loadUserData() {
    if (this.loginService.isLogin()) {
      this.loadUserInfo();

      // fist load when menu init.
      this.loadUnreadNotice();


      // reload notice every 10 mins.
      if (this.unreadNoticeInterval != null) {
        // clear old loop
        clearInterval(this.unreadNoticeInterval);
      }
      // re-add loop
      this.unreadNoticeInterval = setInterval(() => {
        this.loadUnreadNotice();
      }, 1000 * 60 * 10);

    }
  }


  loadUserMenu() {
    this.userMenuGroupsForQuoteAndSoa = this.userMenuService.getUserMenuGroups([
      "PQUOTESETTINGS",
      "SOASETTINGS",
      "BUSINESSSETTINGS",
      "KIWIMONSTERSETTINGS",
    ]);
    this.userMenuGroupsForUserSetting = this.userMenuService.getUserMenuGroups([
      "SUBSCRIPTION",
      "USERSETTINGS",
    ]);
  }
  
  updateUserMenuGroupsForQuoteAndSoa(): UserMenuGroup[] {
    if (this.currentPlatformSetting !== this.loginService.getPlatformSetting()) {
      setTimeout(() => {
        this.currentPlatformSetting = this.loginService.getPlatformSetting();
        this.loadUserMenu();
      });
    }
    return this.userMenuGroupsForQuoteAndSoa;
  }

  collapseNavbar(): void {
    if (this.collapse.length > 1) {
      this.collapse = "";
    } else {
      this.collapse = "collapse";
    }
  }

  loadUnreadNotice() {
    if (this.loginService.isLogin()) {
      this.unreadNotice = 0;
      this.userService.getUserUnreadNoticeCounter((response) => {
        if (response !== null) {
          this.unreadNotice = response.IntValue;
        }
      });
    }
  }

  logout() {
    this.loginService.logout();
  }

  hideMenu(): boolean {
    this.showCollapseNav = false;
    return true;
  }

  loadUserInfo() {
    // check if user logined and never load user detail
    if (this.loginService.isLogin() && this.userDetail.FirstName === '') {
      this.userService.getUserDetail((userDetail) => {
        this.userDetail = userDetail;
        this.loadUserLicenseInfo();
      });
    } else {
      this.loadUserLicenseInfo();
    }
  }

  loadUserLicenseInfo() {
    // reload user license
    this.currentLicense = this.loginService.getUserLicense();
    // reload user menu
    this.loadUserMenu();
  }
  
  getDashboardUrl(): string {
    // go to dashboard depend on platform setting
    let dashboardUrl = this.loginService.platformDashboard(this.getPlatformSetting());
    return dashboardUrl;
  }

  getPlatformSetting(): number {
    return this.loginService.getPlatformSetting();
  }

  checkDomain(): void {
    let domain = window.location.origin;
    this.isKiwiMonsterDomain = domain.includes('kiwimonster.co.nz');
  }
  
  checkIsAwaitingTermsAgreement(): void {
    if (this.loginService.getAwaitingTermsAgreement()) {
      this.loginService.logout();
    }
  }
  
  displayLogoHeader(): boolean {
    if (this.loginService.hasAccessToKiwimonster() && this.isDevMode) {
      return true;
    }

    if (this.currentLicense.AccessLevel === 2) {
      return true;
    }

    return false;
  }
}
